import styled from "styled-components";
import React, { useState, useEffect, useContext } from "react";
import notificationsServices from "services/notificationsServices";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { updateNotification } from '../../store/actions/notificationData';
import { Notification } from "types/notificatonTypes";
import { toast } from "react-toastify";
import chatServices from "services/chatServices";
import { AllConversationsContext } from "contexts/allConversationsContext";

export const Div = styled.div<{ notificationsNumber?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  gap: 11px;
  // padding-bottom: 12px;

  & > p {
    font-size: 10px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
  }

  &:before {
    content: ${({ notificationsNumber }) => `"${notificationsNumber}"`};
    display: ${({ notificationsNumber }) =>
      notificationsNumber === "0" ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    padding: 1px 0;
    margin-left: 36px;
    margin-bottom: -11px;
    font-size: ${({ notificationsNumber }) =>
      notificationsNumber !== undefined
        ? notificationsNumber >= "100"
          ? "8px"
          : "9px"
        : ""};
    font-weight: 600;
  }
`;

const Chats = () => {
  const token = localStorage.getItem("token");
  const { push } = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();

  const [notificationCounter, setNotificationsCounter] = useState("0");
  const isNotificatonNumChange = useSelector(
    (state: any) => state.notificationPageNumberReducer,
  );
  const permission = useSelector((state: any) => state.permissions.permissions);
  const isChatHasPermission =
    permission[0]?.Id === -1
      ? true
      : !!permission.find((permission: any) => permission.Id === 215);

  const [
    allConversations,
    setAllConversations,
    filter,
    setFilter,
    searchQuery,
    setSearchQuery,
    conversation,
    setConversation,
    conversationMembers,
    setConversationMembers,
    activeConversationImageUrl,
    setActiveConversationImageUrl,
    activeConversationName,
    setActiveConversationName,
    onlineProfiles,
    setOnlineProfiles,
    activeConversationId,
    setActiveConversationId,
    activeConversationType,
    setActiveConversationType,
    tempMemberId,
    setTempMemberId,
    unreadMessagesCounter,
    setUnreadMessagesCounter,
    allInterlocutors,
    setAllInterlocutors,
    auxFolderName,
    setAuxFolderName,
    activeMemberType,
    setActiveMemberType,
  ] = useContext(AllConversationsContext);

  const handleButtonClick = () => {
    if (isChatHasPermission) {
      push("/chat");
    } else {
      toast.error("Nie masz uprawnień, skontaktuj się z Administratorem");
    }
  };

  return (
    <>
      <Div
        notificationsNumber={unreadMessagesCounter}
        onClick={handleButtonClick}
        style={{
          cursor: isChatHasPermission ? "pointer" : "default",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23.25"
          height="21"
          viewBox="0 0 29.25 27"
          onClick={handleButtonClick}
        >
          <g
            id="Icon_ionic-md-chatboxes"
            data-name="Icon ionic-md-chatboxes"
            transform="translate(-3.375 -4.5)"
          >
            <path
              id="Path_21491"
              data-name="Path 21491"
              d="M27.531,4.5H4.05a.677.677,0,0,0-.675.574V20.136a.705.705,0,0,0,.675.606H8.086v6.25l6.337-6.25H27.531a.568.568,0,0,0,.524-.606V5.074A.538.538,0,0,0,27.531,4.5Z"
              fill="#f8f8f8"
              opacity={isChatHasPermission ? "1" : "0.4"}
            />
            <path
              id="Path_21492"
              data-name="Path 21492"
              d="M32.09,8.93H29.813V20.642c0,1.124-.486,1.858-1.768,1.858H15.335L12.6,25.242h9.119L28.055,31.5V25.242H32.09a.58.58,0,0,0,.535-.61V9.57A.608.608,0,0,0,32.09,8.93Z"
              fill="#f8f8f8"
              opacity={isChatHasPermission ? "1" : "0.4"}
            />
          </g>
        </svg>
        <p
          style={{
            opacity: isChatHasPermission ? "" : "0.4",
          }}
        >
          wiadomości
        </p>
      </Div>
    </>
  );
};

export default Chats;
