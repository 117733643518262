import HeaderContainer from "components/common/Containers/HeaderContainer";
import { InfoContainer, StyledTableInput } from "styles/styles";
import InfoContainerItem from "components/common/Containers/InfoContainerItem";
import { usePrimaryDataFormContext } from "../usePrimaryDataFormContext";

const AcademyGeneralArea = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  return (
    <HeaderContainer title="Obszar">
      <InfoContainer className="w-full">
        <InfoContainerItem
          header="Widoczność"
          content={
            !editEnable ? (
              // form.primaryData.NameShort
              "-"
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.NameShort}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      NameShort: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Promień"
          content={
            !editEnable ? (
              // form.primaryData.WebsiteURL
              "-"
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.WebsiteURL}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      WebsiteURL: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyGeneralArea;
