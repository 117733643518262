import { useEffect, useState } from "react";
import TabContent from "components/common/tabs/TabContent";
import styled from "styled-components";
import { showServerErrors } from "utils/errorsUtils";
import eventsServices from "services/eventsServices";
import { toast } from "react-toastify";
import { changeLocalization } from "services/eventServices";

export enum EVENT_LOCALIZATION_TYPE {
  "Sala gimnastyczna",
  "Sztuczna nawierzchnia",
  "Boisko trawiaste",
  "Inne",
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 27px 39px; */
  width: 572px;
  position: relative;

  & > div:nth-of-type(1) {
    background: rgb(2, 2, 15, 0.3);
    color: white;
    border-left: 5px solid #ff9226;
    opacity: 1;
    font-size: 15px;
    padding: 17px 37px;
    font-weight: 300;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }

  & > div:nth-of-type(2) {
    position: absolute;
    top: 18px;
    display: flex;
    gap: 10px;
    right: 20px;
    & > p {
      color: #ff9226;
      cursor: pointer;
    }
  }

  & > div:nth-of-type(3) > div {
    padding: 11px 10px 11px 40px;
    display: flex;
    gap: 5px;

    &:nth-child(even) {
      background: rgba(163, 158, 157, 0.09);
    }
    &:nth-child(odd) {
      background: rgba(163, 158, 157, 0.05);
    }

    & > p {
      :nth-child(1) {
        width: 25%;
        color: #707070;
        text-transform: capitalize;
      }
      :nth-child(2) {
        width: 75%;
        color: white;
        display: flex;
        justify-content: end;
      }
    }
  }
`;

const RelatedProfilesTab = ({ event, getEventDetail }: any) => {
  const [countries, setCountries] = useState<any>();
  const { Localization, PostCode } = event;
  const [editedPostCode, setEditedPostCode] = useState("");
  const [editedCity, setEditedCity] = useState("");
  const [isEdited, setIsEdited] = useState(false);

  const processLocalization = (localization: any) => {
    const partsArray = localization.split(",");

    if (partsArray.length > 2) {
      return partsArray.slice(0, -1).join(",").trim();
    } else {
      return partsArray[0].split(" ")[1];
    }
  };

  const handleEditedCityOnChange = (e: any) => {
    setEditedCity(e.target.value);
  };

  const handleEditedPostCodeOnChange = (e: any) => {
    setEditedPostCode(e.target.value);
  };

  const backendDate = new Date(event.EndDate);

  const today = new Date();

  const isDateExpired = today > backendDate;

  // const getAllCountries = async () => {
  //   try {
  //     const resp = await playerProfileServices.getAllCountries();

  //     setCountries(resp);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getAllCountries();
  // }, []);

  const editLocalizationFn = async () => {
    try {
      await changeLocalization(event?.EventId, editedCity, editedPostCode);
      await getEventDetail();
      setIsEdited(false);
      toast.success("Edytowano lokalizacje!");
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    <TabContent id="address">
      <Container>
        <div>adres</div>
        <div>
          {/* {!isDateExpired ? (
            !isEdited ? (
              <p onClick={() => setIsEdited(true)}>Edytuj</p>
            ) : (
              <>
                <p onClick={() => editLocalizationFn()}>Zapisz</p>
                <p onClick={() => setIsEdited(false)}>Anuluj</p>
              </>
            )
          ) : (
            <p></p>
          )} */}
        </div>
        {!isEdited ? (
          <>
            {" "}
            <div>
              <div>
                <p>Kraj</p>
                <p>Polska</p>
              </div>

              <div>
                <p>Kod pocztowy</p>
                <p>{PostCode}</p>
              </div>
              <div>
                <p>Lokalizacja</p>
                <p>{Localization}</p>
                {/* <p>{processLocalization(Localization)}</p> */}
              </div>
              <div>
                <p>Typ lokalizacji</p>
                <p>
                  {event?.LocalizationType
                    ? EVENT_LOCALIZATION_TYPE[event?.LocalizationType]
                    : "-"}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div>
              <div>
                <p>Kraj</p>
                <p>Polska</p>
              </div>
              <div>
                <p>Kod pocztowy</p>
                <StyledInput
                  type="text"
                  placeholder={PostCode}
                  value={editedPostCode}
                  onChange={handleEditedPostCodeOnChange}
                />
              </div>
              <div>
                <p>Miasto</p>
                <StyledInput
                  type="text"
                  value={editedCity}
                  onChange={handleEditedCityOnChange}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </TabContent>
  );
};

export default RelatedProfilesTab;

const StyledInput = styled.input`
  background: rgb(2, 2, 15, 0.3);
  padding: 0 10px;
  color: white;
`;
