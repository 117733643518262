import HeaderContainer from 'components/common/Containers/HeaderContainer';
import ColumnTableRow from 'components/common/FormTable/ColumnTableRow';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import passService from 'services/transfer/passServices';
import { GridColumn, StyledTableSelect, GridTextItem } from 'styles/styles';
import { IPass, IPlayerHistoryPass } from 'types/transfer/pass.interface';
import { IAppliedPass } from 'types/transfer/player.interface';
import { showServerErrors } from 'utils/errorsUtils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'styles/confirmAlertStyles.scss'; // Import css
import { getFormatedDate } from 'utils/dateUtils';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete.svg';

import { ReactComponent as SaveIcon } from '../../../../../assets/icons/save.svg';

import { useSelector } from 'react-redux';
import { useAcademyTabsContext } from 'components/academy/hook/useAcademyTabsContext';
import { toast } from 'react-toastify';
import DeleteButton from 'components/common/buttons/basicButton/DeleteButton';
import SaveButton from 'components/common/buttons/basicButton/SaveButton';
import EditButton from 'components/common/buttons/basicButton/EditButton';
import { IPlayerDetail } from 'types/transfer/player.interface';
import { off } from 'process';

const StyledInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 38px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
`;

const StyledInputDate = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color-scheme: dark;
`;

interface IApplyPassProps {
  refresh: () => Promise<void>;
  appliedPass: any;
  newestHistoryPass: IPlayerHistoryPass;
}

enum PassType {
  Miesięczny = 0,
  Czasowy = 1,
}

enum PaymentType {
  Miesięczna = 0,
  Rata = 1,
  Pełna = 2,
}

function formatDate(isoString: any) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const formatToISOWithoutMilliseconds = (dateStr: any) => {
  const [year, month, day] = dateStr.split('-');
  const utcDate = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
  return utcDate.toISOString().slice(0, 19) + 'Z';
};

const ApplyPass = ({
  refresh,
  appliedPass,
  newestHistoryPass,
}: IApplyPassProps) => {
  const { id: playerId } = useParams<{ id: string }>();

  const [passes, setPasses] = useState<IPass[] | null>([]);
  const [isLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newDate, setNewDate] = useState('');
  const [editedPassEndAt, setEditedPassEndAt] = useState('');
  const [isPassEndAtEdit, setIsPassEndAtEdit] = useState(false);
  const [idEditedPassEndAt, setIdEditedPassEndAt] = useState('');
  const [prevEditPassEnd, setPrevEditPassEnd] = useState('');
  const [isTableEdit, setIsTableEdit] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [editRow, setEditRow] = useState('');
  const [pickedPass, setPickedPass] = useState<IPass | null>(null);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isEditEndPassInput, setIsEditEndPassInput] = useState(false);

  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  const [minEditDate, setMinEditDate] = useState('');
  const [maxEditDate, setMaxEditDate] = useState('');

  const [isSubsriptionActive, setIsSubscriptionActive] = useState(false);

  const todaydate = formatDate(new Date());

  const permission = useSelector((state: any) => state.permissions.permissions);

  const isAcademyCreatePass = passes?.length === 0;

  const isElemHasPermission = (id: number) =>
    permission[0]?.Id === -1
      ? true
      : !!permission.find((permission: any) => permission.Id === id);

  // const { data  } = useAcademyTabsContext();

  const contactMail = 'support@academiq.pl';
  const mailtoHref = `mailto:${contactMail}?subject=${'academy.Name'} - Edycja składki.`;

  const findLastDate = () => {
    return appliedPass?.AppliedPasses?.map((elem: any) => {
      let date = null;

      if (elem.Period === null) {
        return;
      } else if (elem.EndAt === null) {
        date = new Date(elem.Period.EndAt);
      } else if (elem.Period !== null) {
        date = new Date(elem.EndAt);
      }
      if (date) {
        date.setDate(date.getDate() + 1);
      }
      return date ? formatDate(date) : undefined;
    }).filter((date: any) => date !== undefined);
  };

  const removeOneDay = (dateString: any) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split('T')[0];
  };

  const getAcademyPasses = async () => {
    const { Items } = await passService.getAll();
    setPasses(Items);
  };

  useEffect(() => {
    const endDate = findLastDate();
    setNewDate('');
    setMinDate('');
    setMaxDate('');
    setMinEditDate('');
    setMaxEditDate('');

    const passToApply =
      pickedPass?.Period.StartAt === null && pickedPass?.Period.EndAt === null;

    if (passToApply && !isSubsriptionActive) {
      setMinDate(endDate[0] === undefined ? todaydate : endDate[0]);
      setMaxDate('9999-01-01');
    }

    if (passToApply && isSubsriptionActive) {
      setMinDate('9999-12-31');
      setMaxDate('9999-12-31');
    }

    if (appliedPass?.AppliedPasses.length > 0 && !passToApply) {
      const lastPassDate = findLastDate();

      if (lastPassDate[0] !== undefined) {
        const canPassApply =
          lastPassDate[0] > formatDate(pickedPass?.Period.StartAt) &&
          lastPassDate[0] > removeOneDay(pickedPass?.Period?.EndAt);

        const isStartPassOlder =
          formatDate(pickedPass?.Period.StartAt) > endDate[1]
            ? formatDate(pickedPass?.Period.StartAt)
            : endDate[1];

        const higherDate =
          lastPassDate[0] > formatDate(pickedPass?.Period.StartAt)
            ? lastPassDate[0]
            : formatDate(pickedPass?.Period.StartAt);

        if (isSubsriptionActive) {
          setMinDate('9999-12-31');
          setMaxDate('9999-12-31');
        } else {
          setMinDate(canPassApply ? isStartPassOlder : higherDate);

          setMaxDate(
            !canPassApply && pickedPass?.Period?.EndAt === undefined
              ? todaydate
              : removeOneDay(pickedPass?.Period?.EndAt)
          );
        }
      }
    } else {
      if (pickedPass && !passToApply) {
        if (
          endDate[0] !== undefined &&
          endDate[0] < formatDate(pickedPass?.Period?.StartAt || '')
        ) {
          setMinDate(formatDate(pickedPass?.Period?.StartAt || ''));
          setMaxDate(formatDate(pickedPass?.Period?.EndAt || ''));
        } else {
          if (endDate.length === 0) {
            setMinDate(
              todaydate < formatDate(pickedPass?.Period?.StartAt)
                ? formatDate(pickedPass?.Period?.StartAt)
                : todaydate
            );
            setMaxDate(removeOneDay(pickedPass?.Period?.EndAt));
          } else {
            const startDate =
              endDate[1] !== undefined
                ? endDate[1] < formatDate(pickedPass?.Period?.StartAt)
                  ? formatDate(pickedPass?.Period?.StartAt)
                  : endDate[1]
                : endDate[0];
            const safeStartDate = startDate || '';
            setMinDate(safeStartDate);
            setMaxDate(removeOneDay(pickedPass?.Period?.EndAt));
          }
        }
      }
    }
  }, [pickedPass, appliedPass]);

  const handelSetNewEndPassDate = async (PassId: string) => {
    setLoading(true);

    const passEnds = formatToISOWithoutMilliseconds(editedPassEndAt);
    try {
      const payload: { PlayerId: string; PassId: string; EndAt: string } = {
        PlayerId: appliedPass?.PlayerId,
        PassId: PassId,
        EndAt: passEnds,
      };

      await passService.setPassEndDate(payload);
      toast.success(`Data zakończenia karnetu została zmieniona`);

      setPasses([]);
      await refresh();

      setLoading(false);
      setNewPass(false);
      setEditedPassEndAt('');
      setPrevEditPassEnd('');
      setIsTableEdit(false);
      setIsEditEndPassInput(false);
    } catch (error: any) {
      showServerErrors(error);
      setLoading(false);
      setNewPass(false);
    }
  };

  const handleEditPassEndAt = (
    event: React.ChangeEvent<HTMLInputElement>,
    editedPassId: string
  ) => {
    return appliedPass?.AppliedPasses.some((elem: any) => {
      if (elem.PassId === editedPassId) {
        setEditedPassEndAt(event.target.value);
      }
    });
  };

  const showWarningPopupDeletePass = () => {
    confirmAlert({
      title: 'Dodanie Składki',
      message:
        'Usunięcie składki podczas jej trwanie jest nie możliwe. Po więcej informacji zachęcamy do kontaktu bezpośrednio z obsługą klienta pod adresem: support@academiq.pl',
      buttons: [
        {
          label: 'Wyślij wiadomość',
          onClick: () => {
            window.location.href = mailtoHref;
          },
        },
        {
          label: 'Rozumiem',
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const showPassLackPopup = () => {
    confirmAlert({
      title: 'Brak Składki',
      message:
        'Aktualnie akademia nie posiada żadnych składek . Przejdź do sekcji moja akademia i utwórz nową składkę ',
      buttons: [
        {
          label: 'Rozumiem',
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const applyPass = async (passDate: string, passId: string) => {
    setLoading(true);

    try {
      await passService.applyPlayer(
        appliedPass?.PlayerId,
        passId,
        formatToISOWithoutMilliseconds(newDate)
      );
      toast.success(
        `Karnet ${
          passes?.find((pass) => pass.PassId === passId)?.Name
        } został przypisany i oczekuje na zatwierdzenie przez zawodnika`
      );
      await refresh();
      setLoading(false);
      setNewPass(false);
    } catch (error: any) {
      showServerErrors(error);
      setLoading(false);
      setNewPass(false);
    }
  };

  const revokePass = async (payload: string) => {
    setIsRemoveLoading(true);
    try {
      await passService.revokePlayer(appliedPass?.PlayerId, payload);
      toast.success(`Pomyślnie usunięto ${appliedPass.Name}`);

      await refresh();
      getAcademyPasses();
      setEditRow('');
    } catch (error: any) {
      showServerErrors(error);
    } finally {
      setIsRemoveLoading(false);
      setNewPass(false);
    }
  };

  const removePassDuplicates = (
    appliedPassParam: any,
    passesParam: IPass[] | null
  ) => {
    const appliedPassId = new Set(
      appliedPassParam.map((item: any) => item.PassId)
    );

    const removePassDuplicates = (passesParam || []).filter(
      (item: IPass) => !appliedPassId.has(item.PassId)
    );

    setPasses(removePassDuplicates);
  };

  useEffect(() => {
    const safePasses: IPass[] = passes || [];

    const isSubscriptionApplyed = appliedPass?.AppliedPasses.some(
      (elem: any) => {
        return elem.Period === null && elem.EndAt === null;
      }
    );

    setIsSubscriptionActive(isSubscriptionApplyed);

    removePassDuplicates(appliedPass?.AppliedPasses, safePasses);
  }, [appliedPass?.AppliedPasses]);

  const handleApplyPassDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pickedPassStartDate =
      event.target.value >= minDate || event.target.value <= maxDate;
    if (pickedPassStartDate) {
      setNewDate(event.target.value);
    } else {
      setNewDate('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (/[\d]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getAcademyPasses();
  }, []);

  return (
    <div
      className={`flex lg:w-full overflow-x-auto md:overflow-x-hidden w-[50vw]`}
    >
      <HeaderContainer
        title={appliedPass ? 'Przypisz składkę' : 'Aktualna składka'}
        headerContent={
          <div className="flex h-full p-4 gap-2">
            <div
              style={{
                pointerEvents: isElemHasPermission(179) ? 'auto' : 'none',
                opacity: isElemHasPermission(179) ? '' : '0.6',
              }}
            >
              {!isAcademyCreatePass ? (
                <Button
                  onClick={() => {
                    setNewPass((prev) => !prev);
                    setPickedPass(null);
                    setNewDate('');

                    setEditedPassEndAt('');
                    setIsPassEndAtEdit(false);
                    setIdEditedPassEndAt('');
                    setIsTableEdit(false);
                    removePassDuplicates(appliedPass?.AppliedPasses, passes);
                  }}
                  variant={!newPass ? ButtonVariant.Add : ButtonVariant.Cancel}
                >
                  {!newPass ? 'Dodaj składkę' : 'Zakończ'}
                </Button>
              ) : (
                <Button
                  variant={ButtonVariant.Add}
                  onClick={() => {
                    showPassLackPopup();
                  }}
                >
                  Dodaj składkę
                </Button>
              )}
            </div>
            {appliedPass?.AppliedPasses.length ? (
              <div
                style={{
                  pointerEvents: isElemHasPermission(180) ? 'auto' : 'none',
                  opacity: isElemHasPermission(180) ? '' : '0.6',
                }}
              >
                <Button
                  onClick={() => {
                    setIsTableEdit((prev) => {
                      if (prev) {
                        setIsEditing(false);
                        setEditedPassEndAt('');

                        setPickedPass(null);
                        setNewDate('');

                        setEditedPassEndAt('');
                        setIsPassEndAtEdit(false);
                        setIdEditedPassEndAt('');
                        setIsTableEdit(false);

                        setMinEditDate('');
                        setMaxEditDate('');
                      }

                      return !prev;
                    });
                  }}
                  variant={
                    !newPass && isTableEdit
                      ? ButtonVariant.Cancel
                      : ButtonVariant.Edit
                  }
                >
                  {isTableEdit && !newPass ? 'Zakończ' : 'Edycja'}
                </Button>
              </div>
            ) : null}
          </div>
        }
      >
        {appliedPass?.AppliedPasses ? (
          <GridColumn cols={9}>
            <GridTextItem isHeader>Nazwa</GridTextItem>
            <GridTextItem isHeader>typ</GridTextItem>
            <GridTextItem isHeader>Od</GridTextItem>
            <GridTextItem isHeader>Do</GridTextItem>
            <GridTextItem isHeader>Płatność</GridTextItem>
            <GridTextItem isHeader>Brutto</GridTextItem>
            <GridTextItem isHeader>Data aktywacji</GridTextItem>
            <GridTextItem isHeader>Data zakończenia</GridTextItem>
            <GridTextItem isHeader>Działanie</GridTextItem>
            <>
              {newPass && passes !== null
                ? passes.slice(0, 1).map((elem: any, idx: number) => (
                    <React.Fragment key={elem.PassId}>
                      <GridTextItem>
                        <ColumnTableRow header="" disableUppercase>
                          <>
                            <StyledTableSelect
                              value={pickedPass ? pickedPass?.PassId : ''}
                              onChange={(e) => {
                                const selectedPassId = e.target.value;
                                const selectedPass = passes.find(
                                  (pass) => pass.PassId === selectedPassId
                                );
                                setPickedPass(selectedPass || null);
                              }}
                              style={{ lineHeight: '15px' }}
                            >
                              <option value="" disabled>
                                Wybierz składkę
                              </option>
                              {passes.map((pass) => (
                                <option key={pass.PassId} value={pass.PassId}>
                                  {pass.Name}
                                </option>
                              ))}
                            </StyledTableSelect>
                          </>
                        </ColumnTableRow>
                      </GridTextItem>

                      <GridTextItem>
                        {pickedPass !== null ? PassType[pickedPass?.Type] : '-'}
                      </GridTextItem>

                      <GridTextItem>
                        {pickedPass?.Period?.StartAt
                          ? getFormatedDate(pickedPass?.Period?.StartAt, 'date')
                          : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass?.Period.EndAt
                          ? getFormatedDate(pickedPass?.Period.EndAt, 'date')
                          : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass !== null
                          ? PaymentType[pickedPass?.PaymentType]
                          : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass?.Gross}

                        {pickedPass !== null ? 'pln' : '-'}
                      </GridTextItem>
                      <GridTextItem>
                        {pickedPass !== null ? (
                          <StyledInputDate
                            onKeyDown={handleKeyDown}
                            type="date"
                            value={newDate}
                            onChange={handleApplyPassDate}
                            min={minDate}
                            max={maxDate}
                            disabled={minDate === '9999-12-31'}
                          />
                        ) : (
                          '-'
                        )}
                      </GridTextItem>
                      <GridTextItem>-</GridTextItem>
                      <GridTextItem>
                        <div className="flex items-center w-full h-full justify-center">
                          {pickedPass !== null && newDate !== '' && (
                            <SaveButton
                              variant={ButtonVariant.Cancel}
                              disabled={isLoading}
                              onClick={() => {
                                applyPass(
                                  formatToISOWithoutMilliseconds(newDate),
                                  pickedPass.PassId
                                );
                              }}
                            />
                          )}
                        </div>
                      </GridTextItem>
                    </React.Fragment>
                  ))
                : null}
            </>

            <>
              {appliedPass?.AppliedPasses.map((elem: any, idx: number) => (
                <React.Fragment key={elem.PassId}>
                  <GridTextItem>{elem.Name}</GridTextItem>
                  <GridTextItem>{PassType[elem.Type]}</GridTextItem>

                  <GridTextItem>
                    {elem.Period !== null
                      ? getFormatedDate(elem.Period.StartAt, 'date')
                      : '-'}
                  </GridTextItem>

                  <GridTextItem>
                    {elem.Period !== null
                      ? getFormatedDate(elem.Period.EndAt, 'date')
                      : '-'}
                  </GridTextItem>

                  <GridTextItem>{PaymentType[elem.PaymentType]}</GridTextItem>

                  <GridTextItem>{elem.Gross} pln</GridTextItem>

                  {isEditing && elem.PassId === editRow ? (
                    <GridTextItem>
                      <StyledInputDate
                        type="date"
                        value={newDate}
                        onChange={handleApplyPassDate}
                        onKeyDown={handleKeyDown}
                      />
                    </GridTextItem>
                  ) : (
                    <GridTextItem>
                      {elem.StartAt
                        ? getFormatedDate(elem.StartAt, 'date')
                        : '-'}
                    </GridTextItem>
                  )}

                  <GridTextItem>
                    {elem.EndAt && !isEditEndPassInput ? (
                      getFormatedDate(elem.EndAt, 'date')
                    ) : idEditedPassEndAt === elem.PassId ? (
                      <StyledInputDate
                        type="date"
                        onKeyDown={handleKeyDown}
                        value={editedPassEndAt}
                        onChange={(event) =>
                          handleEditPassEndAt(event, elem.PassId)
                        }
                        min={minEditDate}
                        max={maxEditDate}
                      />
                    ) : (
                      '-'
                    )}
                  </GridTextItem>

                  <GridTextItem>
                    <div className="flex w-full h-full gap-2 items-center justify-center ">
                      {isTableEdit && (
                        <>
                          {idEditedPassEndAt === elem.PassId &&
                          editedPassEndAt.length > 0 ? (
                            <SaveButton
                              disabled={isLoading}
                              onClick={() =>
                                handelSetNewEndPassDate(elem.PassId)
                              }
                            />
                          ) : (
                            <EditButton
                              onClick={() => {
                                setIdEditedPassEndAt(elem.PassId);
                                setIsPassEndAtEdit(!isPassEndAtEdit);
                                setIsEditEndPassInput((prev) => !prev);
                                setMinEditDate('');
                                setMaxEditDate('');

                                const isDataStartExist =
                                  elem?.Period?.StartAt !== undefined ??
                                  elem?.Period?.StartAt;

                                const isDataEndExist =
                                  elem?.Period?.EndAt !== undefined ??
                                  elem?.Period?.EndAt;

                                if (isDataEndExist && isDataStartExist) {
                                  setMinEditDate(
                                    formatDate(
                                      elem?.Period?.StartAt > todaydate
                                        ? elem?.Period?.StartAt
                                        : todaydate
                                    )
                                  );

                                  setMaxEditDate(
                                    formatDate(elem?.Period?.EndAt)
                                  );
                                }

                                if (!isDataEndExist && !isDataStartExist) {
                                  const endDate = findLastDate();
                                  setMinEditDate(formatDate(endDate[0]));
                                }
                              }}
                            />
                          )}
                          <DeleteButton
                            onClick={() => {
                              if (todaydate < formatDate(elem.StartAt)) {
                                revokePass(elem.PassId);
                              } else {
                                showWarningPopupDeletePass();
                              }
                            }}
                            variant={ButtonVariant.Cancel}
                            disabled={isRemoveLoading}
                          />
                        </>
                      )}
                    </div>
                  </GridTextItem>
                </React.Fragment>
              ))}
            </>
          </GridColumn>
        ) : (
          <div>brak danych</div>
        )}
      </HeaderContainer>
    </div>
  );
};

export default ApplyPass;
