import { useTranslation } from "react-i18next";
import DataTable from "components/common/dataTable/DataTable";
import EventListingDataTable from "components/common/dataTable/EventListingDataTable";
import { getFormatedDate } from "utils/dateUtils";
import { utcToLocalDate } from "./detail/EventsDetail";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import StatisticsTextInput from "components/common/inputs/textInput/StatisticsTextInput";
import { toast } from "react-toastify";
import { useUrlQuery } from "hooks/useUrlQuery";

interface IDigitalReleaseTableProps {
  events: Array<any>;
  containerRef: any;
  lastItemRef: any;
  isDataLoading: boolean;
  sortBy: any;
  setClickedCyclicalEventId: any;
  toggleDraftMenuOpen: any;
}

const EventsStructureTable: React.FC<IDigitalReleaseTableProps> = ({
  events,
  containerRef,
  isDataLoading,
  lastItemRef,
  sortBy,
  setClickedCyclicalEventId,
  toggleDraftMenuOpen,
}) => {
  const { t } = useTranslation();

  const [dateTypes, setDateTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [dateType, setDateType] =
    useState<{
      value: string;
      label: string;
    } | null>(null);
  const { query, changeQuery } = useUrlQuery();
  const [benchmarkTimeFrom, setBenchmarkTimeFrom] = useState("");
  const [benchmarkTimeTo, setBenchmarkTimeTo] = useState("");
  const [benchmarkDateFrom, setBenchmarkDateFrom] = useState("");
  const [benchmarkDateTo, setBenchmarkDateTo] = useState("");

  useEffect(() => {
    if (
      benchmarkDateFrom &&
      benchmarkDateTo &&
      benchmarkTimeFrom &&
      benchmarkTimeTo
    ) {
      changeDateQuery();
    }
  }, [benchmarkDateFrom, benchmarkDateTo, benchmarkTimeFrom, benchmarkTimeTo]);

  const headers = [
    "Typ",
    "Poziom",
    "Nazwa",
    "Uczestnicy",
    "Data",
    // "Godzina",
    "Lokalizacja",
  ];

  const compare = (a: any, b: any, sort: any) => {
    if (a[sort] < b[sort]) {
      return 1;
    }
    if (a[sort] > b[sort]) {
      return -1;
    }
    return 0;
  };

  const sortingFunction = (type: number, array: any) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "Name"))
          .reverse();
      case 3:
        return array.slice().sort((a: any, b: any) => compare(a, b, "Name"));
      default:
        return array;
    }
  };

  const getAllDateTypes = async () => {
    try {
      setDateTypes([
        {
          label: "+/- 3 dni",
          value: "3days",
        },
        {
          label: "Dzień dzisiejszy",
          value: "today",
        },
        {
          label: "7 dni wstecz",
          value: "7daysago",
        },
        {
          label: "14 dni wstecz",
          value: "14daysago",
        },
        {
          label: "Zaplanowane wydarzenia na 7 dni",
          value: "7days",
        },

        {
          label: "Zaplanowane wydarzenia na 30 dni",
          value: "month",
        },
        {
          label: "Niestandardowe",
          value: "custom",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDateTypes();
  }, []);

  useEffect(() => {
    if (dateType?.value === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setBenchmarkDateFrom(formattedDate);
      setBenchmarkTimeFrom("00:01");
      setBenchmarkDateTo(formattedDate);
      setBenchmarkTimeTo("23:59");
    }
    if (dateType?.value === "3days") {
      const today = new Date();

      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(today.getDate() - 3);

      const startYear = threeDaysAgo.getFullYear();
      const startMonth = (threeDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const startDay = threeDaysAgo.getDate().toString().padStart(2, "0");

      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const threeDaysLater = new Date();
      threeDaysLater.setDate(today.getDate() + 3);

      const endYear = threeDaysLater.getFullYear();
      const endMonth = (threeDaysLater.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = threeDaysLater.getDate().toString().padStart(2, "0");

      const endDate = `${endYear}-${endMonth}-${endDay}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "7days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 7);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${day}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const endDate = `${year}-${month}-${endDay}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "7daysago") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDate = `${year}-${month}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "14daysago") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 14);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endDate = `${year}-${month}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "month") {
      const today = new Date();
      const thirtyDaysLater = new Date();
      thirtyDaysLater.setDate(today.getDate() + 30);

      const startYear = today.getFullYear();
      const startMonth = (today.getMonth() + 1).toString().padStart(2, "0");
      const startDay = today.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;

      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endYear = thirtyDaysLater.getFullYear();
      const endMonth = (thirtyDaysLater.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = thirtyDaysLater.getDate().toString().padStart(2, "0");
      const endDate = `${endYear}-${endMonth}-${endDay}`;

      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "custom") {
      setBenchmarkDateFrom("");
      setBenchmarkTimeFrom("");
      setBenchmarkDateTo("");
      setBenchmarkTimeTo("");
    }
  }, [dateType]);

  const changeDateQuery = () => {
    if (benchmarkDateFrom && benchmarkDateTo) {
      changeQuery([
        {
          name: "OccurenceDate",
          value: `${benchmarkDateFrom}T${
            benchmarkTimeFrom ? benchmarkTimeFrom : "00:01"
          }:00Z`,
        },

        {
          name: "EndDate",
          value: `${benchmarkDateTo}T${
            benchmarkTimeTo ? benchmarkTimeTo : "23:59"
          }:00Z`,
        },
      ]);
    } else if (benchmarkDateFrom) {
      changeQuery([
        {
          name: "OccurenceDate",
          value: `${benchmarkDateFrom}T${
            benchmarkTimeFrom ? benchmarkTimeFrom : "00:01"
          }:00Z`,
        },
      ]);
    } else if (benchmarkDateTo) {
      changeQuery([
        {
          name: "EndDate",
          value: `${benchmarkDateTo}T${
            benchmarkTimeTo ? benchmarkTimeTo : "23:59"
          }:00Z`,
        },
      ]);
    } else toast.error("Wybierz date!");
  };

  const visibilitySwitch = (visibility: number) => {
    switch (visibility) {
      case 0:
        return "Wszyscy";
      case 1:
        return "Tylko zaproszeni";
      case 2:
        return "Grupa";
      case 3:
        return "-";

      default:
        return visibility;
    }
  };

  const typeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return "T";
      // case 0:
      //   return "Trening";
      case 1:
        return "TA";
      case 2:
        return "TP";
      // case 1:
      //   return "Trening aerobowy";
      // case 2:
      //   return "Trening personalny";
      case 3:
        return "BO";
      case 4:
        return "M";
      // case 3:
      //   return "Badania okresowe";
      // case 4:
      //   return "Mecz piłkarski";
      default:
        return type;
    }
  };
  const levelSwitch = (level: number) => {
    switch (level) {
      case 0:
        return "K";
      case 1:
        return "R";
      // case 0:
      //   return "Klub";
      // case 1:
      //   return "Reprezentacja";
      case 2:
        return "Własne";
      case 3:
        return "-";

      default:
        return level;
    }
  };

  const processLocalization = (localization: any) => {
    const partsArray = localization.split(",");

    if (partsArray.length > 2) {
      return partsArray.slice(0, -1).join(",").trim();
    } else {
      return partsArray[0].split(" ")[1];
    }
  };

  const sortedArray = sortingFunction(sortBy?.value, events);
  const uniqueEvents = Array.from(
    new Map(sortedArray.map((event: any) => [event.EventId, event])).values(),
  );
  const rows = uniqueEvents.map((event: any) => {
    const localizationParts = event?.Localization?.split(",");
    localizationParts?.pop();
    const eventLocalizatioin = localizationParts?.join(",");

    const occurenceDateLocal = utcToLocalDate(event?.OccurenceDate);
    const endDateLocal = utcToLocalDate(event?.EndDate);

    return {
      data: { link: `/events/detail/${event.EventId}?tab=attendances` },
      isCyclical: event?.IsDraft ? true : false,
      cols: [
        typeSwitch(event.Type),
        levelSwitch(event.Level),
        event.Name,
        // organizerSwitch(event.Organizer),

        <div>
          {event?.Groups?.length > 0 ? (
            event?.Groups?.slice(0, 3)?.map((group: any) => (
              <p>{group?.GroupName}</p>
            ))
          ) : (
            <p>{visibilitySwitch(event.Visibility)}</p>
          )}
        </div>,
        <div>
          {!event?.OccurenceDate && !event?.EndDate ? (
            <>
              <p>-</p>
            </>
          ) : occurenceDateLocal.slice(0, 10) === endDateLocal.slice(0, 10) ? (
            <>
              <p>
                {`${getFormatedDate(
                  occurenceDateLocal.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${occurenceDateLocal.slice(11, 16)} - ${endDateLocal.slice(
                  11,
                  16,
                )}`}
              </p>
            </>
          ) : (
            <>
              <p>
                {event?.OccurenceDate !== null
                  ? `
                ${getFormatedDate(
                  occurenceDateLocal.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${occurenceDateLocal.slice(11, 16)}

                `
                  : "-"}
              </p>
              <p>
                {" "}
                {`${getFormatedDate(
                  endDateLocal.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${endDateLocal.slice(11, 16)}`}
              </p>
            </>
          )}
        </div>,

        // eventLocalizatioin,
        // processLocalization(event?.Localization),
        event?.Localization,
      ],
    };
  });

  return (
    <Container className="w-full">
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "5px",
          flexWrap: "wrap",
        }}
      >
        <SelectWithoutFilter
          name=""
          items={dateTypes}
          className={"min-w-160"}
          style={{ minWidth: "360px" }}
          label="Okres"
          selectedItem={dateType}
          setSelectedItem={setDateType}
          defaultValue={"3days"}
        />

        {dateType?.value === "custom" && (
          <>
            <TimeAndDateWrapper>
              <DateInput
                name="AvailableFrom"
                label={"Od"}
                type="date"
                value={benchmarkDateFrom}
                className={"min-w-120"}
                onChange={(e) => setBenchmarkDateFrom(e.target.value)}
                max={benchmarkDateTo}
              />

              <TimeInput
                type="time"
                value={benchmarkTimeFrom}
                onChange={(e) => {
                  setBenchmarkTimeFrom(e.target.value);
                }}
              />
            </TimeAndDateWrapper>
            <TimeAndDateWrapper>
              <DateInput
                name="AvailableTo"
                label={"Do"}
                type="date"
                className={"min-w-120"}
                value={benchmarkDateTo}
                onChange={(e) => setBenchmarkDateTo(e.target.value)}
                max={new Date().toISOString().slice(0, 10)}
              />
              <TimeInput
                type="time"
                value={benchmarkTimeTo}
                onChange={(e) => setBenchmarkTimeTo(e.target.value)}
              />
            </TimeAndDateWrapper>
          </>
        )}
        {/* <Button
          className="px-24 xl:order-2"
          variant={ButtonVariant.Save}
          onClick={() => changeDateQuery()}
          style={{
            alignSelf: "center",
          }}
        >
          Zastosuj
        </Button> */}
      </div>

      <EventListingDataTable
        setClickedCyclicalEventId={setClickedCyclicalEventId}
        toggleDraftMenuOpen={toggleDraftMenuOpen}
        rows={rows}
        headers={headers}
        isDataLoading={isDataLoading}
        containerRef={containerRef}
        lastItemRef={lastItemRef}
      />
    </Container>
  );
};

export default EventsStructureTable;
const Container = styled.div`
  height: 100%;

  /* max-height: 650px;
  overflow-y: auto;
  position: relative; */

  & > div {
    & > div:nth-of-type(4) {
      & > div {
        & > div {
          max-height: 900px;
          /* overflow-y: auto; */
        }
      }
    }
  }

  & table {
    height: 100%;
    max-height: 100%;
    /* opacity: 1 !important; */
    & thead {
      position: sticky;
      top: 0;
      z-index: 99;
    }

    & * {
      /* opacity: 1 !important; */
    }
  }
`;

const TimeAndDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeInput = styled.input`
  height: 48px;
  width: 84px;
  line-height: 1rem;
  background-color: transparent;
  border: 1px solid lightgray;
  padding: 5px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: unset;
  color: white;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const DateInput = styled(StatisticsTextInput)`
  width: 193px;
  height: 48px;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  background-color: transparent;
  /* background: rgba(0, 0, 0, 0.1); */
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;
