import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ContentContainer from 'components/layout/ContentContainer';
import InfoBox from 'components/common/boxes/InfoBox/InfoBox';
import InfoBoxPlaceholder from 'components/common/boxes/InfoBox/InfoBoxPlaceholder';
import Tabs from 'components/common/tabs/Tabs';
import TabsView from 'components/common/tabs/TabsView';
import TabContent from 'components/common/tabs/TabContent';
import SelectArrow from 'assets/icons/selectArrow.png';
import DigitalReleaseDetailTopbar from './PlayersDetailTopbar';
import logo from '../../../assets/images/logo.svg';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getAllAcademyTrainers,
  getPlayerDetail,
} from 'services/transferServices';
import Button from 'components/common/buttons/basicButton/Button';
import { ButtonVariant } from 'components/common/buttons/buttonTypes';
import {
  getFolderDetail,
  getPlayerPasses,
  grantFolderToTrainer,
  revokeFolderToTrainer,
} from 'services/folderServices';
import NewSelectStatystics from 'components/common/inputs/select/NewSelectStatystics';
import styled from 'styled-components';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import EventsTable from 'components/events/EventsTable';
import { getAllEventsFilter } from 'services/eventServices';
import { getFormatedDate } from 'utils/dateUtils';
import Arrow from 'assets/icons/right-arrow.png';
import axios from 'axios';
import HomeDataTable from 'components/common/dataTable/HomeDataTable';
import { TABLE_AVG_VAL, TABLE_HEADER } from '../../../types/homeTabelTypes';
import { useSelector } from 'react-redux';
import EventsStructureTable from 'components/events/EventsStructureTable';
import DeleteButton from 'components/common/buttons/basicButton/DeleteButton';
import AddButton from 'components/common/buttons/basicButton/AddButton';
import api from 'services/axiosConfig';
import { PASS_TRANSACTION_STATUS } from 'types/transfer/pass.interface';

interface Props {
  isStructure?: boolean;
  folderId?: string;
  closeFolderModalOpen?: any;
  columns: any;
  getFolderDetailWithoutIndex: any;
}

const PERMISSIONS: Record<string, string> = {
  '198': 'Zajęcia',
  '199': 'Trener',
  '197': 'Statystyki',
  '200': 'Składki',
};

const StructureFolderDetail: React.FC<Props> = ({
  isStructure,
  folderId,
  closeFolderModalOpen,
  columns,
  getFolderDetailWithoutIndex,
}) => {
  const [folder, setFolder] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const [editAttributes, setEditAttributes] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [accessType, setAccessType] = useState<any>(false);
  const [deteteTrainerBtn, setDeleteTrainerBtn] = useState('')
  const [sortBy, setSortBy] =
    useState<{ value: number; label: string } | null>(null);
  const [accessTypes, setAccessTypes] = useState<
    { value: boolean; label: string }[]
  >([]);
  const [queryString, setQueryString] = useState('');
  const [academyTrainer, setAcademyTrainer] = useState<any>('');
  const [academyTrainers, setAcademyTrainers] = useState<
    { value: number; label: string }[]
  >([]);
  const [isDraftMenuOpen, toggleDraftMenuOpen] = useState(false);
  const [clickedCyclicalEventId, setClickedCyclicalEventId] = useState('');
  const [passes, setPasses] = useState<any>([]);
  const url = window.location.href;
  const params = new URLSearchParams(new URL(url).search);

  const today = new Date();

  const threeDaysAgo = new Date();
  threeDaysAgo.setDate(today.getDate() - 3);

  const threeDaysLater = new Date();
  threeDaysLater.setDate(today.getDate() + 3);

  const startYear = threeDaysAgo.getFullYear();
  const startMonth = (threeDaysAgo.getMonth() + 1).toString().padStart(2, '0');
  const startDay = threeDaysAgo.getDate().toString().padStart(2, '0');
  const startDate = `${startYear}-${startMonth}-${startDay}`;

  const endYear = threeDaysLater.getFullYear();
  const endMonth = (threeDaysLater.getMonth() + 1).toString().padStart(2, '0');
  const endDay = threeDaysLater.getDate().toString().padStart(2, '0');
  const endDate = `${endYear}-${endMonth}-${endDay}`;

  const {
    items: events,
    loading,
    containerRef,
    lastItemRef,
  } = useInfiniteScroll<any>(
    // @ts-ignore
    (pageInfo) => {
      const filterParams = {
        ...pageInfo,
        GroupId: folderId,
        ...(params.has('OccurenceDate')
          ? {}
          : {
              OccurenceDate: `${startDate}T00:01:00Z`,
              EndDate: `${endDate}T23:59:00Z`,
            }),
      };

      return getAllEventsFilter(filterParams);
    },
    queryString
  );
  const [groupPlayers, setGroupPlayers] = useState<any[]>([]);
  const [tokenUser, setTokenUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const match = useRouteMatch();
  const permission = useSelector((state: any) => state.permissions.permissions);

  const [avgStats, setAvgStats] = useState<{
    EventCountFromLast7Days: number;
    Dynamic: number;
    Energy: number;
    TotalDistanceFromLast7Days: number;
    AverageDistance: number;
    MaxSpeedFromLast7Days: number;
    HSR: number;
    HMLD: number;
    Fitness: number;
    MaxHeartRate: number;
    AverageHeartRateFromLast7Days: number;
    AverageSpeed: number;
    HSRPercentage: number;
    HMLDPercentage: number;
  }>({
    EventCountFromLast7Days: 0,
    Dynamic: 0,
    Energy: 0,
    TotalDistanceFromLast7Days: 0,
    AverageDistance: 0,
    MaxSpeedFromLast7Days: 0,
    HSR: 0,
    HMLD: 0,
    Fitness: 0,
    MaxHeartRate: 0,
    AverageHeartRateFromLast7Days: 0,
    AverageSpeed: 0,
    HSRPercentage: 0,
    HMLDPercentage: 0,
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    order: 'asc' | 'desc';
  }>({ key: '', order: 'asc' });

  const RowsName: { [key: string]: string } = {
    Zadań: 'EventCountFromLast7Days',
    Dynamika: 'Dynamic',
    Energia: 'Energy',
    'Dystans total': 'TotalDistanceFromLast7Days',
    'Dystans średnia': 'AverageDistance',
    'Prędkość max': 'MaxSpeedFromLast7Days',
    'Prędkość średnia': 'AverageSpeed',
    'HSR %': 'HSRPercentage',
    'HSR m': 'HSR',
    'Hmld %': 'HMLDPercentage',
    'Hmld m': 'HMLD',
    Energian: 'Energy',
    Kondycja: 'Fitness',
    'Tętno max': 'MaxHeartRate',
    'Tętno średnia': 'AverageHeartRateFromLast7Days',
  };

  const compare = (a: any, b: any, sortKey: string, order: 'asc' | 'desc') => {
    if (a[sortKey] < b[sortKey]) return order === 'asc' ? -1 : 1;
    if (a[sortKey] > b[sortKey]) return order === 'asc' ? 1 : -1;
    return 0;
  };

  const filteredItems = useMemo(() => {
    if (!sortConfig.key)
      return groupPlayers
        .slice()
        .sort((a, b) => (a.LastName ?? '').localeCompare(b.LastName ?? ''));

    const sortKey = RowsName[sortConfig.key];
    return groupPlayers
      .slice()
      .sort((a, b) => compare(a, b, sortKey, sortConfig.order));
  }, [groupPlayers, sortConfig]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setTokenUser(token);
    }
  }, []);

  const typeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return 'Trener';
      case 1:
        return 'Scout';
      case 2:
        return 'Manager';
      case 3:
        return 'Analityk';
      default:
        return type;
    }
  };

  const getFolderDetailFn = () => {
    if (folderId) {
      getFolderDetail(folderId)
        .then((data) => {
          setFolder(data.Data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    getFolderDetailFn();
  }, []);

  const getPlayerPassesFn = () => {
    if (folderId) {
      getPlayerPasses(folderId)
        .then((data) => {
          setPasses(data.Data?.PlayerPasses);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    getPlayerPassesFn();
  }, []);

  const getAllAccessTypes = async () => {
    try {
      setAccessTypes([
        {
          label: 'Pełna',
          value: true,
        },
        {
          label: 'Ogólna',
          value: false,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAccessTypes();
  }, []);

  const getAllAcademyTrainersFn = async () => {
    try {
      const resp: any = await getAllAcademyTrainers();
      console.log('respacademytrainers>D', resp.Data.Items);

      const mappedTrainers = resp.Data?.Items?.map((trainer: any) => ({
        label: trainer.Name,
        value: trainer.TrainerId,
      }));

      setAcademyTrainers(mappedTrainers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAcademyTrainersFn();
  }, []);

  useEffect(() => {
    const removeGrantedTrainers = academyTrainers?.filter((e: any) => {
      return !folder?.GrantTrainers.some((el: any) => e.value === el.TrainerId);
    });

    setAcademyTrainers(removeGrantedTrainers);
  }, [folder]);

  const getGroupPlayer = () => {
    const body = {
      GroupId: folder.FolderId,
    };

    api
      .post(
        '/api/StatisticsProcessor/GetPlayersStatisticsByAcademyOrGroupId',
        body
      )
      .then((response) => {
        setGroupPlayers(response.data.Data.PlayersStatistics);
        setAvgStats(response.data.Data.AverageStatistics);
        setIsLoading(false);
      })
      .catch((errors) => {
        console.error(errors);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    if (folder) {
      getGroupPlayer();
    }
  }, [folder]);

  if (!folder) {
    return <InfoBoxPlaceholder />;
  }

  const handleClick = (playerId: string) => {
    push(`/players/detail/${playerId}?tab=passes`, {
      var1: folderId,
    });
  };

  function roundToTwo(num: number): number {
    return +(Math.round(num * 100) / 100).toFixed();
  }

  const getStyledSpan = (value: number, avgValue: number) => {
    const lowerThreshold = avgValue * 0.8;
    const upperThreshold = avgValue * 1.2;

    return (
      <span
        style={{
          fontWeight: '500',
          width: value < 999 ? '100%' : value <= 99999 ? '85%' : '75%',
          color:
            value < 0.5
              ? 'white'
              : value < lowerThreshold
              ? 'rgb(220, 33, 33)'
              : value > upperThreshold
              ? '#008800'
              : 'white',
        }}
      >
        {roundToTwo(value) === 0
          ? '-'
          : roundToTwo(value).toLocaleString('pl-PL', {
              maximumFractionDigits: 3,
              minimumFractionDigits: 0,
              useGrouping: true,
            })}
      </span>
    );
  };

  const headers: TABLE_HEADER = {
    firstCol: ['imię'],
    secendCol: ['Nazwisko'],
    thirdCol: ['Rocznik'],
    task: ['Zadań', '', ''],
    distance: ['Dystans', 'total', 'średnia'],
    speed: ['Prędkość', 'max', 'średnia'],
    HSR: ['HSR', '%', 'm'],
    Hmld: ['Hmld', '%', 'm'],
    dynamic: ['Dynamika'],
    energy: ['Energia'],
    condition: ['Kondycja'],
    pulse: ['Tętno', 'max', 'średnia'],
  };

  const tabelAvgVal: TABLE_AVG_VAL = {
    task: [`x ${roundToTwo(avgStats.EventCountFromLast7Days)}`],
    distance: [
      `x ${roundToTwo(avgStats.TotalDistanceFromLast7Days)} m`,
      `x ${roundToTwo(avgStats.AverageDistance)} m`,
    ],
    speed: [
      `x ${roundToTwo(avgStats.MaxSpeedFromLast7Days)} km/h`,
      `x ${roundToTwo(avgStats.AverageSpeed)} km/h`,
    ],
    HSR: [
      `x ${roundToTwo(avgStats.HSRPercentage)} %`,
      `x ${roundToTwo(avgStats.HSR)} m`,
    ],
    Hmld: [
      `x ${roundToTwo(avgStats.HMLDPercentage)} %`,
      `x ${roundToTwo(avgStats.HMLD)} m`,
    ],
    pulse: [
      `x ${roundToTwo(avgStats.MaxHeartRate)} bmp`,
      `${roundToTwo(avgStats.AverageHeartRateFromLast7Days)} bmp`,
    ],
  };

  const filterBy = (primaryValue: string, secondaryValue?: string) => {
    let key = primaryValue;

    if (secondaryValue) {
      key = `${primaryValue} ${secondaryValue}`;
    }

    setSortConfig((prevSortConfig) => ({
      key,
      order:
        prevSortConfig.key === key && prevSortConfig.order === 'asc'
          ? 'desc'
          : 'asc',
    }));
  };

  const rows = filteredItems.map((player: any) => ({
    // data: { link: /player-profiles/detail/${player.Id} },
    cols: [
      `${player.FirstName}`,
      `${player.LastName}`,
      `${player.YearOfBirth}`,
      getStyledSpan(
        player.EventCountFromLast7Days,
        avgStats.EventCountFromLast7Days
      ),

      [
        getStyledSpan(
          player.TotalDistanceFromLast7Days,
          avgStats.TotalDistanceFromLast7Days
        ),
        getStyledSpan(player.AverageDistance, avgStats.AverageDistance),
      ],

      [
        getStyledSpan(
          player.MaxSpeedFromLast7Days,
          avgStats.MaxSpeedFromLast7Days
        ),
        getStyledSpan(player.AverageSpeed, avgStats.AverageSpeed),
      ],
      [
        getStyledSpan(player.HSRPercentage, avgStats.HSRPercentage),
        getStyledSpan(player.HSR, avgStats.HSR),
      ],

      [
        getStyledSpan(player.HMLDPercentage, avgStats.HMLDPercentage),
        getStyledSpan(player.HMLD, avgStats.HMLD),
      ],
      getStyledSpan(player.Dynamic, avgStats.Dynamic),
      getStyledSpan(player.Energy, avgStats.Energy),
      getStyledSpan(player.Fitness, avgStats.Fitness),
      [
        getStyledSpan(player.MaxHeartRate, avgStats.MaxHeartRate),
        getStyledSpan(
          player.AverageHeartRateFromLast7Days,
          avgStats.AverageHeartRateFromLast7Days
        ),
      ],
    ],
  }));

  const isAdmin = permission[0]?.Id === -1;
  const isActiveSubtabs = (id: number) =>
    permission.find((tab: any) => tab.Id === id);

  const tabs = [
    {
      tab: {
        id: 'informations',
        label: 'Statystyki',
      },
      content: (
        <TabContent id="informations">
          <div className="flex">
            <span
              style={{ background: 'rgba(0, 0, 0, 0.5)', width: '33.3%' }}
            ></span>
            <span
              className="w-full text-white p-8 text-center "
              style={{
                border: '2px solid rgba(0, 0, 0, 0.9)',
                borderRight: 'transparent',
                borderLeft: '4px solid rgba(0, 0, 0, 0.9)',
              }}
            >
              Mikrocykl = 7dni
            </span>
          </div>
          <HomeDataTable
            rows={rows}
            headers={headers}
            isDataLoading={isLoading}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
            filterBy={filterBy}
            tabelAvgVal={tabelAvgVal}
          />
        </TabContent>
      ),
    },
    {
      tab: {
        id: 'events',
        label: 'Zajęcia',
      },
      content: (
        <TabContent id="events">
          <EventsStructureTable
            events={events}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
            isDataLoading={loading}
            sortBy={sortBy}
            setClickedCyclicalEventId={setClickedCyclicalEventId}
            toggleDraftMenuOpen={toggleDraftMenuOpen}
          />
        </TabContent>
      ),
    },
    {
      tab: {
        id: 'manager',
        label: 'Trener',
      },
      content: (
        <TabContent id="manager">
          <>
            <div style={{ display: 'flex', gap: '10px' }}></div>

            <div className="flex flex-col lg:flex-row gap-16 mx-auto w-full">
              <div className="w-full text-sm">
                <div
                  className="px-18 py-12 opacity-80"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: 'rgba(2,2,15,0.3)',
                    color: 'white',
                    opacity: 1,
                    height: '60px',
                    alignItems: 'center',
                    borderLeft: '5px solid rgb(255,146,38)',
                  }}
                >
                  <span
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '15px',
                      fontWeight: 300,
                      letterSpacing: -0.5,
                    }}
                  >
                    Obserwatorzy
                  </span>
                  <div style={{ display: 'flex', gap: '5px' }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        pointerEvents: isAdmin
                          ? 'auto'
                          : isActiveSubtabs(202) === undefined
                          ? 'none'
                          : undefined,
                      }}
                    >
                      <Button
                        variant={
                          isAdding ? ButtonVariant.Cancel : ButtonVariant.Add
                        }
                        onClick={() => {
                          setIsAdding((prev) => !prev);
                        }}
                      >
                        {isAdding ? 'Zamknij' : 'Dodaj'}
                      </Button>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        gap: '5px',
                        pointerEvents: isAdmin
                          ? 'auto'
                          : isActiveSubtabs(202) === undefined
                          ? 'none'
                          : undefined,
                      }}
                    >
                      <Button
                        variant={
                          editAttributes
                            ? ButtonVariant.Save
                            : ButtonVariant.Edit
                        }
                        style={{ cursor: 'pointer', userSelect: 'none' }}
                        onClick={() => {
                          setEditAttributes((prev) => !prev);
                        }}
                      >
                        {editAttributes ? 'Zapisz' : 'Edytuj'}
                      </Button>
                    </div>
                  </div>
                </div>
                <>
                  <div className={`grid relative grid-cols-5 gap-1 my-1`}>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Imię i nazwisko</span>
                    </div>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Status obserwacji</span>
                    </div>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Funkcja</span>
                    </div>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Przypisany od</span>
                    </div>

                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Zmiana parametrów</span>
                    </div>
                  </div>
                </>

                {isAdding && (
                  <>
                    <div className={`grid relative grid-cols-5 gap-1 my-1`}>
                      <div
                        className="p-12 text-center"
                        style={{
                          background: 'rgba(163,158,157,0.09)',
                          color: 'white',
                          fontSize: '15px',
                          fontWeight: 400,
                          height: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <StyledSelect
                          value={academyTrainer}
                          onChange={(e) => setAcademyTrainer(e.target.value)}
                        >
                          <option value={''} selected hidden>
                            -
                          </option>
                          {academyTrainers.length > 0 &&
                            academyTrainers.map((trainer: any) => {
                              const { value, label } = trainer;
                              return <option value={value}>{label}</option>;
                            })}
                        </StyledSelect>
                        {/* <span>
                          {" "}
                          <NewSelectStatystics
                            name="Trener"
                            items={academyTrainers}
                            label="Trener"
                            style={{ width: "280px", paddingBottom: "9px" }}
                            // @ts-ignore
                            selectedItem={academyTrainer}
                            setSelectedItem={setAcademyTrainer}
                          />
                        </span> */}
                      </div>
                      <div
                        className="p-12 text-center"
                        style={{
                          background: 'rgba(163,158,157,0.09)',
                          color: 'white',
                          fontSize: '15px',
                          fontWeight: 400,
                          height: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {/* <StyledSelect
                          value={accessType.toString()}
                          onChange={(e) =>
                            setAccessType(e.target.value === "true")
                          }
                        >
                          <option value={"true"}>Pełna</option>
                          <option value={"false"}>Ogólna</option>
                        </StyledSelect> */}
                        Pełna
                        {/* {academyTrainer?.value && (
                          <NewSelectStatystics
                            name="Dostęp"
                            items={accessTypes}
                            label="Dostęp"
                            style={{ width: "280px", paddingBottom: "9px" }}
                            // @ts-ignore
                            selectedItem={accessType}
                            setSelectedItem={setAccessType}
                          />
                        )} */}
                      </div>
                      <div
                        className="p-12 text-center"
                        style={{
                          background: 'rgba(163,158,157,0.09)',
                          color: 'white',
                          fontSize: '15px',
                          fontWeight: 400,
                          height: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        -
                      </div>
                      <div
                        className="p-12 text-center"
                        style={{
                          background: 'rgba(163,158,157,0.09)',
                          color: 'white',
                          fontSize: '15px',
                          fontWeight: 400,
                          height: '48px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        -
                      </div>

                      <div
                        className="p-12 text-center"
                        style={{
                          background: 'rgba(163,158,157,0.09)',
                          color: 'white',
                          fontSize: '15px',
                          fontWeight: 400,
                          height: '48px',
                          display: 'flex',
                          padding: '5px 8px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '6px',
                        }}
                      >
                        <AddButton
                          variant={ButtonVariant.Submit}
                          disabled={!academyTrainer || isLoading}
                          onClick={async () => {
                            setIsAdding(false);
                            const idAlreadyExists = folder.GrantTrainers.some(
                              (trainer: any) =>
                                trainer.TrainerId === academyTrainer
                            );
                            if (folderId && !idAlreadyExists) {
                              await grantFolderToTrainer(
                                folderId,
                                // @ts-ignore
                                academyTrainer
                              );
                              await getFolderDetailFn();
                              await columns.map((column: any) => {
                                getFolderDetailWithoutIndex(column.id);
                              });
                              // setAccessType(null);
                              // setAcademyTrainer(null);
                              toast.success('Przypisano trenera!');
                              setIsAdding(false);
                            } else {
                              toast.error('Trener jest już przypisany!');
                            }
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {folder?.GrantTrainers.length > 0 &&
                  folder.GrantTrainers.map((trainer: any) => {
                    const {
                      IsRootFolder,
                      Name,
                      PhotoFilePath,
                      TrainerId,
                      Type,
                      CreatedAt,
                    } = trainer;
                    return (
                      <div className={`grid relative grid-cols-5 gap-1 my-1`}>
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span>{Name}</span>
                        </div>
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span>Pełna</span>
                        </div>
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {typeSwitch(Type)}
                        </div>{' '}
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {CreatedAt
                            ? getFormatedDate(
                                CreatedAt?.slice(0, 10),
                                'dd.MM.yyyy'
                              )
                            : '-'}
                        </div>
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            padding: '5px 8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '6px',
                          }}
                        >
                          {editAttributes && (
                            <>
                              {/* <div
                                onClick={() => alert("Ewentualna edycja")}
                                className="cursor-pointer"
                                style={{
                                  fontSize: "15px",
                                  textTransform: "uppercase",
                                  fontWeight: 400,
                                  width: "100%",
                                  height: "100%",
                                  background: "rgb(2, 106, 188)",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                EDYTUJ
                              </div> */}

                              <DeleteButton
                                disabled={isLoading}
                                loading={isLoading ? TrainerId === deteteTrainerBtn : isLoading}

                                onClick={async () => {
                                  if (folderId) {
                                    setIsLoading(true);
                                    setDeleteTrainerBtn(TrainerId)
                                    await revokeFolderToTrainer(
                                      folderId,
                                      TrainerId
                                    );
                                    await getFolderDetailFn();
                                    await getAllAcademyTrainersFn();
                                    setDeleteTrainerBtn('')
                                    await columns.map((column: any) => {
                                      getFolderDetailWithoutIndex(column.id);
                                    });
                                    toast.success('Usunięto trenera!');
                                    setIsLoading(false);
                                  }
                                }}
                                className="cursor-pointer"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        </TabContent>
      ),
    },
    {
      tab: {
        id: 'contributions',
        label: 'Składki',
      },
      content: (
        <TabContent id="contributions">
          {' '}
          <>
            <div style={{ display: 'flex', gap: '10px' }}></div>

            <div className="flex flex-col lg:flex-row gap-16 mx-auto w-full">
              <div className="w-full text-sm">
                <>
                  <div className={`grid relative grid-cols-4 gap-1 my-1`}>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Nazwa</span>
                    </div>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Imię i nazwisko</span>
                    </div>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Status</span>
                    </div>
                    <div
                      className="bg-opacity-70 p-12 text-center"
                      style={{
                        background: 'rgba(2,2,15,0.3)',
                        color: 'white',
                        textTransform: 'uppercase',
                        fontSize: '11px',
                        fontWeight: 500,
                      }}
                    >
                      <span>Przejdź do</span>
                    </div>
                  </div>
                </>

                {passes?.length > 0 &&
                  passes?.map((single: any) => {
                    return (
                      <div
                        className={`grid relative grid-cols-4 gap-1 my-1`}
                        key={single.Pass.PassId}
                      >
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span>{single.Pass.Name}</span>
                        </div>
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span>{single.Player.Name}</span>
                        </div>
                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span>
                            {PASS_TRANSACTION_STATUS[single?.TransactionStatus]}
                          </span>
                        </div>

                        <div
                          className="p-12 text-center"
                          style={{
                            background: 'rgba(163,158,157,0.09)',
                            color: 'white',
                            fontSize: '15px',
                            fontWeight: 400,
                            height: '48px',
                            display: 'flex',
                            padding: '5px 8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '6px',
                          }}
                        >
                          <div
                            onClick={() => {
                              if (!isAdmin && !isActiveSubtabs(204)) {
                                toast.error(
                                  'Nie masz uprawnień. Skontaktuj się z Administratorem'
                                );
                              }
                            }}
                          >
                            <div
                              style={{
                                pointerEvents:
                                  isActiveSubtabs(204) || isAdmin
                                    ? 'auto'
                                    : 'none',
                              }}
                            >
                              <img
                                onClick={() => {
                                  handleClick(single?.Player?.PlayerId);
                                }}
                                style={{ cursor: 'pointer' }}
                                src={Arrow}
                                alt="arrow"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        </TabContent>
      ),
    },
  ];

  const playerTabsWithPermissions = tabs.map((tab) => {
    return {
      content: {
        ...tab.content,
      },
      tab: {
        ...tab.tab,

        disabled: !permission.find(
          (permission: any) => PERMISSIONS[permission.Id] === tab.tab.label
        ),
      },
    };
  });

  const tabsPlayer =
    permission[0]?.Id === -1 ? tabs : playerTabsWithPermissions;

  return (
    <ContentContainer
      title={folder.Name}
      // TopBar={<DigitalReleaseDetailTopbar event={folder} />}
      TopBar={<></>}
      fn={() => closeFolderModalOpen()}
      noArrow={true}
      // path="/players"
    >
      <div>
        <TabsView>
          <Tabs tabs={tabsPlayer.map((t: any) => t.tab)} />

          <div style={{ padding: '10px 0 0 0' }}>
            {tabsPlayer.map((t: any) => t.content)}
          </div>
        </TabsView>
      </div>
    </ContentContainer>
  );
};

export default StructureFolderDetail;

const StyledSelect = styled.select`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  background: url(${SelectArrow}) calc(100% - 10px) 60% no-repeat;
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  outline: none;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    height: 48px;
  }
`;
