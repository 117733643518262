import React from "react";
import { GridTextItem } from "styles/styles";

const AcademyDocumentsHeaders = () => {
  return (
    <>
      <GridTextItem isHeader>Nazwa</GridTextItem>
      <GridTextItem isHeader>Opis</GridTextItem>
      <GridTextItem isHeader>Dokument</GridTextItem>
      <GridTextItem isHeader>Wymagany</GridTextItem>
      <GridTextItem isHeader>Kategoria</GridTextItem>
      <GridTextItem isHeader>Działanie</GridTextItem>
    </>
  );
};

export default AcademyDocumentsHeaders;
