import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import passService from "services/transfer/passServices";
import {
  GridItem,
  GridTextItem,
  StyledTableCurrencyInput,
  StyledTableSelect,
} from "styles/styles";
import {
  IPass,
  IPassDetail,
  PASS_PAYMENT_TYPE,
  PASS_TYPE,
} from "types/transfer/pass.interface";
import { showServerErrors } from "utils/errorsUtils";

import { ReactComponent as AddIcon } from "../../../../../assets/icons/add-circle-white.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/icons/save.svg";
import { getGrossPrice } from "utils/baseUtils";
import { getFormatedDate } from "utils/dateUtils";
import DeleteButton from "components/common/buttons/basicButton/DeleteButton";
import EditButton from "components/common/buttons/basicButton/EditButton";
import SaveButton from "components/common/buttons/basicButton/SaveButton";
import CancelButton from "components/common/buttons/basicButton/CancelButton";

interface IAcademyPassesRowProps {
  isEditMode: boolean;
  pass: IPass;
  setUpdatedPass: React.Dispatch<React.SetStateAction<IPass | undefined>>;
  updatedPass: IPass | undefined;
  setPass: Dispatch<SetStateAction<IPass | null>>;
  refreshPasses: () => Promise<void>;
}

const AcademyDocumentsRow = ({
  isEditMode,
  pass,
  refreshPasses,
  setUpdatedPass,
  updatedPass,
  setPass,
}: IAcademyPassesRowProps) => {
  const [nettoValue, setNettoValue] = useState("");

  useEffect(() => {
    if (!isEditMode) {
      setNettoValue(pass ? pass.Netto.toString() : "");
      setUpdatedPass(undefined);
    }
  }, [isEditMode]);

  const updatePass = async (newPass: IPass) => {
    try {
      if (!newPass.IsFree && newPass.Netto < 0) {
        return toast.error("Podaj rzeczywistą kwotę netto");
      }

      newPass.Netto === 0
        ? await passService.setForFree(newPass.PassId)
        : await passService.changePrice({
            Netto: newPass.Netto,
            PassId: newPass.PassId,
            Tax: newPass.Tax,
          });

      toast.success(`Pomyślnie zapisano karnet ${newPass.Name}`);

      setUpdatedPass(undefined);

      await refreshPasses();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  const deletePass = async (pass: IPass) => {
    try {
      await passService.remove(pass.PassId);

      toast.success(`Pomyślnie usunięto karnet ${pass.Name}`);

      setUpdatedPass(undefined);
      setNettoValue("");
      await refreshPasses();
    } catch (error: any) {
      showServerErrors(error);
    }
  };

  return (
    <>
      <GridTextItem>-</GridTextItem>
      <GridTextItem>-</GridTextItem>
      <GridTextItem>-</GridTextItem>
      <GridTextItem>-</GridTextItem>
      <GridTextItem>-</GridTextItem>

      <GridItem>
        {isEditMode ? (
          !updatedPass ? (
            <div
              className="flex w-full h-full gap-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <EditButton
                onClick={() => {
                  setUpdatedPass(pass);
                  setNettoValue(pass.Netto.toString());
                }}
              />

              <DeleteButton onClick={() => deletePass(pass)} />
            </div>
          ) : (
            <div
              className="flex w-full h-full gap-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <SaveButton
                onClick={() => updatePass(updatedPass)}
                disabled={pass === updatedPass}
                variant={ButtonVariant.Submit}
                className="w-2/3 p-0 h-10 "
              />

              <CancelButton
                onClick={() => {
                  setUpdatedPass(undefined);
                  setNettoValue("");
                }}
                variant={ButtonVariant.Abort}
                className="w-1/3 p-0 h-10 "
              />
            </div>
          )
        ) : (
          <Button
            onClick={() => setPass(pass)}
            variant={ButtonVariant.Cancel}
            className="p-0 w-full h-full "
          >
            Detal
          </Button>
        )}
      </GridItem>

      {/* <GridTextItem>
        <Button
          onClick={() => setPass(pass)}
          variant={ButtonVariant.Cancel}
          className="p-0 w-full h-full "
        >
          Detal
        </Button>
      </GridTextItem>

      <GridTextItem>
        {pass.PaymentType === PASS_PAYMENT_TYPE["Ratalnie"]
          ? PASS_PAYMENT_TYPE[0]
          : PASS_PAYMENT_TYPE[pass.PaymentType]}
      </GridTextItem>

   */}
    </>
  );
};

export default AcademyDocumentsRow;
