import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import DataTable from "components/common/dataTable/DataTable";
import TabContent from "components/common/tabs/TabContent";
import { utcToLocalDate } from "components/events/detail/EventsDetail";
import StatisticsTextInput from "components/common/inputs/textInput/StatisticsTextInput";
import React, { useEffect, useState } from "react";
import playerService from "services/transfer/playerServices";
import styled from "styled-components";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import {
  EVENT_ACTIVITY_LEVEL,
  EVENT_ACTIVITY_TYPE,
  EVENT_ORGANIZER,
  EVENT_VISIBILITY,
  IPlayerEvent,
} from "types/events/event.interface";
import { compareArray } from "utils/baseUtils";
import { getFormatedDate } from "utils/dateUtils";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "utils/localStorageUtils";

interface IPlayerEventsProps {
  eventsData: IPlayerEvent[];
  isLoading: boolean;
}

const PlayerEvents = ({ eventsData, isLoading }: IPlayerEventsProps) => {
  const tableHeaders = [
    "TYP ZADANIA",
    "POZIOM",
    "NAZWA",
    "ORGANIZATOR",
    "ZAPROSZENI",
    "DATA STARTU",
    "LOKALIZACJA",
  ];

  // const [dateTypes, setDateTypes] = useState<
  //   { value: string; label: string }[]
  // >([]);

  // const [dateType, setDateType] =
  //   useState<{
  //     value: string;
  //     label: string;
  //   } | null>(null);

  // const [benchmarkTimeFrom, setBenchmarkTimeFrom] = useState("");
  // const [benchmarkTimeTo, setBenchmarkTimeTo] = useState("");
  // const [benchmarkDateFrom, setBenchmarkDateFrom] = useState("");
  // const [benchmarkDateTo, setBenchmarkDateTo] = useState("");

  const [benchmarkTimeFrom, setBenchmarkTimeFrom] = useState(
    getLocalStorageValue("benchmarkTimeFromPlayer", ""),
  );
  const [benchmarkTimeTo, setBenchmarkTimeTo] = useState(
    getLocalStorageValue("benchmarkTimeToPlayer", ""),
  );

  const [benchmarkDateFrom, setBenchmarkDateFrom] = useState(
    getLocalStorageValue("benchmarkDateFromPlayer", ""),
  );

  const [benchmarkDateTo, setBenchmarkDateTo] = useState(
    getLocalStorageValue("benchmarkDateToPlayer", ""),
  );

  const [dateTypes, setDateTypes] = useState(
    getLocalStorageValue("dateTypesPlayer", []),
  );

  const [dateType, setDateType] = useState(
    getLocalStorageValue("dateTypePlayer", null),
  );

  useEffect(() => {
    if (dateType?.value === "today") {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setBenchmarkDateFrom(formattedDate);
      setBenchmarkTimeFrom("00:00");
      setBenchmarkDateTo(formattedDate);
      setBenchmarkTimeTo("23:59");
    }
    if (dateType?.value === "3days") {
      const today = new Date();

      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(today.getDate() - 3);

      const startYear = threeDaysAgo.getFullYear();
      const startMonth = (threeDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const startDay = threeDaysAgo.getDate().toString().padStart(2, "0");

      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:00");

      const threeDaysLater = new Date();
      threeDaysLater.setDate(today.getDate() + 3);

      const endYear = threeDaysLater.getFullYear();
      const endMonth = (threeDaysLater.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = threeDaysLater.getDate().toString().padStart(2, "0");

      const endDate = `${endYear}-${endMonth}-${endDay}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "7days") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() + 7);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${day}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:00");

      const endDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const endDate = `${year}-${month}-${endDay}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "7daysago") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:00");

      const endDate = `${year}-${month}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "14daysago") {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 14);

      const startYear = sevenDaysAgo.getFullYear();
      const startMonth = (sevenDaysAgo.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      const startDay = sevenDaysAgo.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;
      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:00");

      const endDate = `${year}-${month}-${day}`;
      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }

    if (dateType?.value === "month") {
      const today = new Date();
      const thirtyDaysLater = new Date();
      thirtyDaysLater.setDate(today.getDate() + 30);

      const startYear = today.getFullYear();
      const startMonth = (today.getMonth() + 1).toString().padStart(2, "0");
      const startDay = today.getDate().toString().padStart(2, "0");
      const startDate = `${startYear}-${startMonth}-${startDay}`;

      setBenchmarkDateFrom(startDate);
      setBenchmarkTimeFrom("00:01");

      const endYear = thirtyDaysLater.getFullYear();
      const endMonth = (thirtyDaysLater.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const endDay = thirtyDaysLater.getDate().toString().padStart(2, "0");
      const endDate = `${endYear}-${endMonth}-${endDay}`;

      setBenchmarkDateTo(endDate);
      setBenchmarkTimeTo("23:59");
    }
    if (dateType?.value === "custom") {
      // setBenchmarkDateFrom("");
      // setBenchmarkTimeFrom("");
      // setBenchmarkDateTo("");
      // setBenchmarkTimeTo("");
    }
  }, [dateType]);

  // const today = new Date();
  // const threeDaysBefore = new Date(today);
  // threeDaysBefore.setDate(today.getDate() - 3);
  // const threeDaysAfter = new Date(today);
  // threeDaysAfter.setDate(today.getDate() + 3);

  const getAllDateTypes = async () => {
    try {
      setDateTypes([
        {
          label: "+/- 3 dni",
          value: "3days",
        },
        {
          label: "Dzień dzisiejszy",
          value: "today",
        },
        {
          label: "7 dni wstecz",
          value: "7daysago",
        },
        {
          label: "14 dni wstecz",
          value: "14daysago",
        },
        {
          label: "Zaplanowane wydarzenia na 7 dni",
          value: "7days",
        },

        {
          label: "Zaplanowane wydarzenia na 30 dni",
          value: "month",
        },
        {
          label: "Niestandardowe",
          value: "custom",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDateTypes();
  }, []);

  const rows = eventsData
    .filter((event: IPlayerEvent) => {
      const eventDate = new Date(event.OccurenceDate);
      const datastart = new Date(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00`,
      );
      const dataend = new Date(`${benchmarkDateTo}T${benchmarkTimeTo}:00`);
      return eventDate >= datastart && eventDate <= dataend;
    })
    .sort((a, b) => compareArray<IPlayerEvent>(a, b, "OccurenceDate"))
    .map((event: IPlayerEvent) => {
      const localizationParts = event?.Localization.split(",");
      localizationParts.pop();
      const eventLocalization = localizationParts.join(",");
      const occurenceDateLocal = utcToLocalDate(event?.OccurenceDate);

      return {
        data: { link: `/events/detail/${event.EventId}?tab=attendances` },
        cols: [
          EVENT_ACTIVITY_TYPE[event.Type],
          EVENT_ACTIVITY_LEVEL[event.Level],
          event.Name,
          EVENT_ORGANIZER[event.Organizer],
          EVENT_VISIBILITY[event.Visibility],
          `${getFormatedDate(
            occurenceDateLocal.slice(0, 10),
            "dd.MM.yyyy",
          )} ${occurenceDateLocal.slice(11, 16)}`,
          event?.Localization,
        ],
      };
    });

  useEffect(() => {
    setLocalStorageValue("benchmarkTimeFromPlayer", benchmarkTimeFrom);
    setLocalStorageValue("benchmarkTimeToPlayer", benchmarkTimeTo);
    setLocalStorageValue("benchmarkDateFromPlayer", benchmarkDateFrom);
    setLocalStorageValue("benchmarkDateToPlayer", benchmarkDateTo);
    setLocalStorageValue("dateTypesPlayer", dateTypes);
    setLocalStorageValue("dateTypePlayer", dateType);
  }, [
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkDateFrom,
    benchmarkDateTo,
    dateTypes,
    dateType,
  ]);

  const defaultSelectFilterElem = getLocalStorageValue("dateTypePlayer", "");

  /*   useEffect(() => {
    setLoading(true);
    !isOutside && getEvents();
    isOutside && getFutureEvents();
  }, []); */

  return (
    <TabContent id="events">
      <Container className="w-full">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "5px",
            flexWrap: "wrap",
            marginBottom: "5px",
          }}
        >
          <SelectWithoutFilter
            name=""
            // items={dateTypes}
            items={dateTypes.length ? dateTypes : []}
            className={"min-w-160"}
            style={{ minWidth: "360px" }}
            label="Okres"
            selectedItem={dateType}
            setSelectedItem={setDateType}
            defaultValue={defaultSelectFilterElem?.value ?? "3days"}
          />

          {dateType?.value === "custom" && (
            <>
              <TimeAndDateWrapper>
                <DateInput
                  name="AvailableFrom"
                  label={"Od"}
                  type="date"
                  value={benchmarkDateFrom}
                  className={"min-w-120"}
                  onChange={(e) => setBenchmarkDateFrom(e.target.value)}
                  max={benchmarkDateTo}
                />

                <TimeInput
                  type="time"
                  value={benchmarkTimeFrom}
                  onChange={(e) => {
                    setBenchmarkTimeFrom(e.target.value);
                  }}
                />
              </TimeAndDateWrapper>
              <TimeAndDateWrapper>
                <DateInput
                  name="AvailableTo"
                  label={"Do"}
                  type="date"
                  className={"min-w-120"}
                  value={benchmarkDateTo}
                  onChange={(e) => setBenchmarkDateTo(e.target.value)}
                  max={new Date().toISOString().slice(0, 10)}
                />
                <TimeInput
                  type="time"
                  value={benchmarkTimeTo}
                  onChange={(e) => setBenchmarkTimeTo(e.target.value)}
                />
              </TimeAndDateWrapper>
            </>
          )}
        </div>

        <DataTable
          headers={tableHeaders}
          isDataLoading={isLoading}
          rows={rows}
        />
      </Container>
    </TabContent>
  );
};

export default PlayerEvents;

const Container = styled.div`
  height: 100%;

  /* max-height: 650px;
  overflow-y: auto;
  position: relative; */

  & > div {
    & > div:nth-of-type(4) {
      & > div {
        & > div {
          max-height: 500px;
          /* overflow-y: auto; */
        }
      }
    }
  }

  & table {
    height: 100%;
    max-height: 100%;
    /* opacity: 1 !important; */
    & thead {
      position: sticky;
      top: 0;
      z-index: 99;
    }

    & * {
      /* opacity: 1 !important; */
    }
  }
`;

const TimeAndDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeInput = styled.input`
  height: 48px;
  width: 84px;
  line-height: 1rem;
  background-color: transparent;
  border: 1px solid lightgray;
  padding: 5px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: unset;
  color: white;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const DateInput = styled(StatisticsTextInput)`
  width: 193px;
  height: 48px;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  background-color: transparent;
  /* background: rgba(0, 0, 0, 0.1); */
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;
