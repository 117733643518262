import { ChangeEvent, useState, useEffect } from "react";
import { useHistory } from "react-router";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import styled from "styled-components";
import NewFilterPanel from "components/filters/NewFilterPanel";
import HomeSearchIco from "../../assets/icons/home-search-ico.png";
import GridIco from "../../assets/icons/home-grid-ico.png";
import ListIco from "../../assets/icons/home-list-ico.png";
import StatisticsTextInput from "components/common/inputs/textInput/StatisticsTextInput";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import { useUrlQuery } from "hooks/useUrlQuery";
import { toast } from "react-toastify";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "utils/localStorageUtils";
import FilterButton from "components/common/buttons/basicButton/FilterButton";

interface IDigitalReleasesTopbarProps {
  handleQueryChange: (value: string) => void;
  sortBy: any;
  setSortBy: any;
  sorts: any;
  defaultSort: any;
  viewType: any;
  setViewType: any;
  isDraftMenuOpen: any;
  toggleDraftMenuOpen: any;
  clickedCyclicalEventId: any;
  setClickedCyclicalEventId: any;
  displayType: any;
  setDisplayType: any;
  displayTypes: any;
}

const AcademiesTopbar: React.FC<IDigitalReleasesTopbarProps> = ({
  handleQueryChange,
  sortBy,
  setSortBy,
  sorts,
  defaultSort,
  setViewType,
  viewType,
  isDraftMenuOpen,
  toggleDraftMenuOpen,
  clickedCyclicalEventId,
  setClickedCyclicalEventId,
  displayType,
  displayTypes,
  setDisplayType,
}) => {
  const { push } = useHistory();

  const [isMenuOpen, toggleMenuOpen] = useState(false);
  const [isAddMenuOpen, toggleAddMenuOpen] = useState(false);
  const { query, changeQuery } = useUrlQuery();

  const handleAddDigitalRelease = () => {
    push("/shop/products/add");
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    handleQueryChange(e.target.value);
  };

  const filters = [
    {
      id: 0,
      type: "select",
      name: "Type",
      values: [
        { backend: 0, pl: "Trening piłkarski" },
        { backend: 1, pl: "Trening na siłowni" },
        { backend: 2, pl: "Trening personalny" },
        { backend: 3, pl: "Testy okresowe" },
        { backend: 4, pl: "Mecz piłkarski" },
      ],
      pl: "Typ",
    },
    {
      id: 1,
      type: "select",
      name: "ActivityLevel",
      values: [
        { backend: 0, pl: "Klub" },
        { backend: 1, pl: "Reprezentacja" },
        // { backend: 2, pl: "Własne" },
      ],
      pl: "Poziom",
    },
    {
      id: 2,
      type: "select",
      name: "Visibility",
      values: [
        { backend: 0, pl: "Wszyscy" },
        { backend: 1, pl: "Tylko zaproszeni" },
        { backend: 2, pl: "Grupa" },
      ],
      pl: "Uczestnicy",
    },
  ];

  const defaultSelectFilterElem = getLocalStorageValue("dateTypeData", "");

  return (
    <div
      // className="flex flex-wrap items-center justify-end gap-y-2"
      style={{
        gap: "5px",
        minHeight: "99px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <BlurBox
        isMenuOpen={isMenuOpen}
        isAddMenuOpen={isAddMenuOpen}
        isDraftMenuOpen={isDraftMenuOpen}
        onClick={() => {
          toggleAddMenuOpen(false);
          toggleMenuOpen(false);
          toggleDraftMenuOpen(false);
        }}
      />

      <SideMenu isMenuOpen={isMenuOpen}>
        <NewFilterPanel filters={filters} />
      </SideMenu>

      <SideAddMenu isAddMenuOpen={isAddMenuOpen}>
        <div>
          {/* <AddDigitalRelease
            isAddMenuOpen={isAddMenuOpen}
            toggleAddMenuOpen={toggleAddMenuOpen}
          /> */}
        </div>
      </SideAddMenu>

      <SideAddMenu isAddMenuOpen={isDraftMenuOpen}>
        {clickedCyclicalEventId && (
          <div>
            {/* <EditDraftEvent
              clickedCyclicalEventId={clickedCyclicalEventId}
              isAddMenuOpen={isDraftMenuOpen}
              toggleAddMenuOpen={toggleDraftMenuOpen}
              setClickedCyclicalEventId={setClickedCyclicalEventId}
            /> */}
          </div>
        )}
      </SideAddMenu>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          alignItems: "center",
          gap: "5px",
          flexWrap: "wrap",
          paddingLeft: "10px",
        }}
        className="pr-24"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "5px",
            flexWrap: "wrap",
            marginTop: "8px",
          }}
        >
          <Button
            onClick={() => {
              if (isAddMenuOpen) {
                toggleAddMenuOpen(false);
              } else if (!isMenuOpen && !isAddMenuOpen) {
                toggleAddMenuOpen(true);
              } else {
                toggleMenuOpen(false);
                setTimeout(() => {
                  toggleAddMenuOpen(true);
                }, 250);
              }
            }}
            className="px-24"
            variant={ButtonVariant.Add}
          >
            Dodaj
          </Button>
          {/* <div className="flex items-center gap-2">

          </div> */}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "5px",
          flexWrap: "wrap",
          padding: "4px",
          paddingRight: "24px",
        }}
      >
        <NewSelectStatystics
          name="Sort"
          items={sorts}
          label="Sortowanie"
          selectedItem={sortBy}
          setSelectedItem={setSortBy}
          defaultValue={defaultSort}
        />

        <SearchBox>
          <SearchInput
            className="w-full md:w-36 xl:w-72"
            placeholder="Szukaj..."
            autoComplete="off"
            name="search"
            onChange={handleSearch}
            // @ts-ignore
            defaultValue={localStorage.getItem("searchQuery")}
          />
          <div>
            <img src={HomeSearchIco} alt="ico" />
          </div>
        </SearchBox>

        {/* <FilterButton
          onClick={() => {
            if (isMenuOpen) {
              toggleMenuOpen(false);
            } else if (!isMenuOpen && !isAddMenuOpen) {
              toggleMenuOpen(true);
            } else {
              toggleAddMenuOpen(false);
              setTimeout(() => {
                toggleMenuOpen(true);
              }, 400);
            }
          }}
        /> */}
      </div>
    </div>
  );
};

export default AcademiesTopbar;

const BlurBox = styled.div<{
  isMenuOpen: boolean;
  isAddMenuOpen: boolean;
  isDraftMenuOpen: boolean;
}>`
  position: absolute;
  display: ${(props) =>
    props.isMenuOpen || props.isAddMenuOpen || props.isDraftMenuOpen
      ? "unset"
      : "none"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  // background: rgba(2, 2, 15, 1);
  background: #202d38;
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

const SideAddMenu = styled.div<{ isAddMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: ${(props) => (props.isAddMenuOpen ? "23px 44px" : "0")}; */
  padding: ${(props) => (props.isAddMenuOpen ? "23px 0" : "23px 0")};
  right: 0;
  width: ${(props) => (props.isAddMenuOpen ? "917px" : "0px")};
  // background: rgba(2, 2, 15, 1);
  background: #202d38;
  transition: all 0.4s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isAddMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    height: 1100px;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > form {
      & > div:first-child {
        & > div {
          & > p {
            text-align: left;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

const SearchBox = styled.div`
  height: 48px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  width: 280px;
  display: flex;
  align-items: center;
  border-radius: 10px;

  & > input {
    width: calc(100% - 45px);
  }

  & > div {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 21px;
      height: 21px;
    }
  }
`;

const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  padding: 14px 23px;
  border-radius: 10px;
`;
