import HeaderContainer from "components/common/Containers/HeaderContainer";
import { InfoContainer, StyledTableInput } from "styles/styles";
import InfoContainerItem from "components/common/Containers/InfoContainerItem";
import { usePrimaryDataFormContext } from "../usePrimaryDataFormContext";

const AcademyGeneralData = () => {
  const { editEnable, form, setForm } = usePrimaryDataFormContext();

  return (
    <HeaderContainer title="Dane podstawowe">
      <InfoContainer className="w-full">
        <InfoContainerItem
          header="Skrócona nazwa"
          content={
            !editEnable ? (
              form.primaryData.NameShort
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.NameShort}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      NameShort: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Strona www"
          content={
            !editEnable ? (
              form.primaryData.WebsiteURL
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.WebsiteURL}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      WebsiteURL: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Mail"
          content={
            !editEnable ? (
              // form.primaryData.WebsiteURL
              "-"
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.WebsiteURL}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      WebsiteURL: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        <InfoContainerItem
          header="Telefon"
          content={
            !editEnable ? (
              // form.primaryData.WebsiteURL
              "-"
            ) : (
              <StyledTableInput
                type="text"
                value={form.primaryData.WebsiteURL}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    primaryData: {
                      ...prev.primaryData,
                      WebsiteURL: e.target.value,
                    },
                  }))
                }
              />
            )
          }
        />
        {/* <div style={{ height: "45px" }}></div> */}
      </InfoContainer>
    </HeaderContainer>
  );
};

export default AcademyGeneralData;
