import TabContent from "components/common/tabs/TabContent";
import React, { useState } from "react";
import HeaderContainer from "components/common/Containers/HeaderContainer";
import InputCheckbox from "components/common/inputs/inputCheckbox/InputCheckbox";

import AcademyLicenceData from "components/academy/detail/tabs/Merchant/DataFields/AcademyLicenceData";
import AcademyAdministratorContact from "../Informations/DataFields/AcademyAdministratorContact";

const inputContentHelper = [
  {
    id: 1,
    text: `Oświadczam, że zapoznałam/łem z <a style="color: #3B82F6; text-decoration: underline" href="https://example.com">Regulaminem</a> oraz <a style="color: #3B82F6; text-decoration: underline" href="https://example.com">Polityką prywatności </a> i akceptuję ich postanowienia`,
  },
  {
    id: 2,
    text: 'Przeczytałem i zrozumiałam/em <a style="color: #3B82F6; text-decoration: underline" href="https://example.com">Klauzulę informacyjną</a> dot. prztwarzania mich danych osobowych',
  },
  {
    id: 3,
    text: "* Zgoda na przechowywanie danych, jak pliki cookie oraz identyfikator aplikacji, które są związane z analityką, np. czas trwania wizyty na stronie",
  },
];

interface CheckedState {
  [key: number]: boolean;
}

const AcademyLicence = () => {
  const [checkedState, setCheckedState] = useState<CheckedState>({});

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));
  };

  return (
    <TabContent id="licence">
      <div className="flex flex-col  gap-1">
        <AcademyAdministratorContact />
        <AcademyLicenceData />
      </div>
    </TabContent>
  );
};

export default AcademyLicence;
