import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActiveFilter from "./ActiveFilter";
import Button from "../common/buttons/basicButton/Button";
import DropdownPanel from "../common/panels/DropdownPanel";
import { closeFilterPanel } from "../../store/actions/ui";
import { RootState } from "../../store/store";
import { useUrlQuery } from "../../hooks/useUrlQuery";
import styled from "styled-components";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import FilterDropdownPanel from "components/common/panels/FilterDropdownPanel";
import FilterSingleSelect from "./FilterSingleSelect";
import { getTransactionPassSummary } from "services/transfer/passServices";
import { toast } from "react-toastify";

export interface IFilterValue<T = number | string> {
  backend: T;
  pl: string;
}

export type Filter = {
  id: number;
  name: string;
  pl: string;
  type: string;
  values?: Array<IFilterValue>;
};

interface IFilterPanelProps {
  filters?: Array<Filter>;
}

const NewFilterPanel: React.FC<IFilterPanelProps> = ({ filters }) => {
  const [activeFilters, setActiveFilters] = useState<
    Array<{ name: string; value: string; pl: string; id: number }>
  >([]);
  const [filterToApply, setFilterToApply] = useState<
    Array<{ name: string; value: string; pl: string; id: number }>
  >([]);

  const [settlementDataFrom, setSettlementDataFrom] = useState("");
  const [settlementDataTo, setSettlementDataTo] = useState("");
  const [settlementPeriod, setSettlementPeriod] = useState("");

  const dispatch = useDispatch();
  const { query, changeQuery } = useUrlQuery();

  const handleFilterChange = (
    filter: { name: string; value: string; pl: string; id: number },
    checked: boolean,
  ) => {
    setActiveFilters((prev) => {
      let updatedFilters;
      if (checked) {
        updatedFilters = [...prev, filter];
      } else {
        updatedFilters = prev.filter(
          (f) =>
            !(
              f.name === filter.name &&
              f.value === filter.value &&
              f.pl === filter.pl
            ),
        );

        handleSubmit(updatedFilters);
      }
      return filterFun(updatedFilters);
    });
  };

  const filterFun = (
    arr: Array<{ name: string; value: string; pl: string; id: number }>,
  ) => {
    const reversedArr = [...arr].reverse();

    return arr
      .filter((value, index, self) => {
        const lastIndex = reversedArr.findIndex((t) => t.name === value.name);
        return index === reversedArr.length - lastIndex - 1;
      })
      .filter((t) => t.value !== "");
  };

  const handleSubmit = (
    filtersToSubmit: Array<{
      name: string;
      value: string;
      pl: string;
      id: number;
    }> | null = null,
  ) => {
    const filtersToSend = filtersToSubmit || activeFilters;
    changeQuery(filtersToSend);
    console.log("filtersToSend", filtersToSend);
    dispatch(closeFilterPanel());
  };

  const handleApplyFilters = () => {
    setFilterToApply(activeFilters);
    handleSubmit(activeFilters);
  };

  useEffect(() => {
    setActiveFilters((prev) => [...prev]);
  }, [query]);

  const handleSettlementPeriod = async (): Promise<void> => {
    const payload = {
      FromDate: settlementDataFrom,
      ToDate: settlementDataTo,
    };

    try {
      const transactionPassSummary = await getTransactionPassSummary(payload);
      if (transactionPassSummary?.TotalAmountGross !== undefined) {
        setSettlementPeriod(transactionPassSummary?.TotalAmountGross);
      }
    } catch (error) {
      toast.error("Wystąpił problem z pobraniem okresu rozliczeniowego");
    }
  };

  return (
    <>
      <div className="flex flex-nowrap gap-4 mt-1 mb-6 h-8">
        {activeFilters
          .sort((a, b) => a.id - b.id)
          .map((filter, key) => (
            <div key={key}>
              <ActiveFilter
                removeFilter={() => handleFilterChange(filter, false)}
                filter={filter}
              />
            </div>
          ))}
      </div>
      <div className="shadow flex gap-1 flex-col" style={{ height: "80%" }}>
        <p style={{ textAlign: "center" }} className="text-lg pb-12">
          Filtrowanie tabeli składek
        </p>

        {filters?.map((filter: any, id: number) =>
          filter.type === "checkbox" ? (
            <FilterDropdownPanel label={filter.pl} key={id}>
              <>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: 400,
                    textTransform: "uppercase",
                    marginTop: "10px",
                  }}
                >
                  {filter.pl}
                </p>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    flexDirection: "column",
                  }}
                >
                  {filter?.values?.map((val: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "13px",
                        width: "175px",
                      }}
                    >
                      <label
                        htmlFor={`${filter.name}${index}`}
                        style={{
                          color: "white",
                          fontSize: "15px",
                          fontWeight: 400,
                          textAlign: "right",
                          width: "130px",
                        }}
                      >
                        {val.pl}
                      </label>
                      <div style={{ width: "75px" }}>
                        <input
                          style={{ width: "20px", height: "20px" }}
                          type="checkbox"
                          name={filter.name}
                          id={`${filter.name}${index}`}
                          checked={activeFilters.some(
                            (f) =>
                              f.name === filter.name && f.value === val.backend,
                          )}
                          onChange={(e) => {
                            const { checked } = e.target;
                            handleFilterChange(
                              {
                                name: filter.name,
                                value: val.backend,
                                pl: val.pl,
                                id: filter.id,
                              },
                              checked,
                            );
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </FilterDropdownPanel>
          ) : filter.type === "select" ? (
            <FilterSingleSelect
              filters={filters}
              activeFilters={activeFilters}
              filter={filter}
              handleFilterChange={handleFilterChange}
              key={id}
            />
          ) : filter.type === "date" ? (
            <div style={{ width: "280px" }}>
              <DateContainer key={id}>
                <DataInput
                  type="date"
                  name={filter.name}
                  id={filter.name}
                  value={
                    filter.name === "FromDate"
                      ? activeFilters.find((obj) => obj.name === "FromDate")
                          ?.value || ""
                      : filter.name === "ToDate"
                      ? activeFilters.find((obj) => obj.name === "ToDate")
                          ?.value || ""
                      : ""
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      {
                        name: filter.name,
                        value: e.target.value,
                        pl: filter.pl,
                        id: filter.id,
                      },
                      true,
                    );
                  }}
                />
                <DataLabel htmlFor={filter.name}>{filter.pl}</DataLabel>
              </DateContainer>
            </div>
          ) : filter.type === "rangeOfAmount" ? (
            <>
              <AmountContainer key={id}>
                <AmountInput
                  type="text"
                  name={filter.name}
                  id={filter.name}
                  autoComplete="off"
                  placeholder="Uzupełnij"
                  value={
                    activeFilters.find((obj) => obj.name === filter.name)
                      ?.value || ""
                  }
                  onChange={(e) => {
                    handleFilterChange(
                      {
                        name: filter.name,
                        value: e.target.value,
                        pl: filter.pl,
                        id: filter.id,
                      },
                      true,
                    );
                  }}
                />
                <AmountLabel htmlFor={filter.name}>{filter.pl}</AmountLabel>
              </AmountContainer>
            </>
          ) : (
            <></>
          ),
        )}

        <div className="pt-30" style={{ width: "280px" }}>
          <Button
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              background: "rgb(0, 160, 255)",
              opacity: 1,
              width: "100%",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              fontSize: "15px",
              fontWeight: "bold",
              color: "white",
              marginTop: "20px",
            }}
            onClick={handleApplyFilters}
          >
            Zastosuj
          </Button>
        </div>

        <div
          style={{
            borderBottom: "1px solid rgb(55 53 53)",
            padding: "30px 0",
            width: "100%",
          }}
        ></div>

        <div className="pt-18 relative " style={{ width: "280px" }}>
          <p style={{ textAlign: "center" }} className="text-lg">
            Okres rozliczeniowy
          </p>

          <div className="flex flex-col gap-1 pt-12">
            <DateContainer>
              <DataInput
                type="date"
                name="dateFrom"
                value={settlementDataFrom}
                onChange={(e) => {
                  setSettlementDataFrom(e.target.value);
                }}
              />
            </DateContainer>

            <DateContainer>
              <DataInput
                type="date"
                value={settlementDataTo}
                onChange={(e) => {
                  setSettlementDataTo(e.target.value);
                }}
              />
            </DateContainer>
          </div>

          <div
            className="pt-18 flex justify-end"
            style={{
              height: "28px",
            }}
          >
            <div className="text-lg">
              {settlementPeriod !== "" && (
                <>
                  <span>Suma:</span>
                  <span className="text-white font-bold">
                    {" "}
                    {settlementPeriod}{" "}
                  </span>
                  <span>brutto</span>
                </>
              )}
            </div>
          </div>
          <div
            className="pt-4/10"
            style={{
              textAlign: "center",

              width: "280px",
              position: "absolute",
            }}
          >
            <Button
              className="px-24 xl:order-2"
              disabled={!settlementDataFrom.length && !settlementDataTo.length}
              variant={ButtonVariant.Submit}
              style={{
                background: "rgb(0, 160, 255)",
                opacity: 1,
                width: "100%",
                height: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                fontSize: "15px",
                fontWeight: "bold",
                color: "white",
                marginTop: "20px",
              }}
              onClick={handleSettlementPeriod}
            >
              Sortuj po okresie
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFilterPanel;

const AmountContainer = styled.div`
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(90, 90, 100, 0.3); */
  background: rgba(163, 158, 157, 0.11);
  width: 100%;
  /* margin: 5px 0; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const AmountInput = styled.input`
  background: transparent;
  opacity: 1;
  height: 100%;
  width: 70%;
  text-align: right;
  padding: 0 10px;
  color: white;
  font-size: 15px;
  font-weight: 500;

  &::-webkit-input-placeholder {
    color: white; /*Change the placeholder color*/
    opacity: 1; /*Change the opacity between 0 and 1*/
  }
`;
const AmountLabel = styled.label`
  color: rgba(163, 158, 157, 1);
  font-size: 11px;
  position: absolute;
  top: 7px;
  left: 10px;
`;

const DateContainer = styled.div`
  cursor: pointer;
  min-height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  /* border: 1px solid rgba(90, 90, 100, 0.3); */
  /* border-radius: 14px; */
  padding-left: 130px;
  min-width: 280px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  border-radius: 10px;

  /* margin-top: 10px; */
`;
const DataLabel = styled.label`
  position: absolute;

  top: 7px;
  left: 15px;
  font-size: 11px;
  letter-spacing: 0px;
  font-weight: 400;
  color: rgb(163, 158, 157);
`;

const DataInput = styled.input`
  background-color: transparent;
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
    /* color-scheme: orange; */
  }
  padding: 0 10px;
`;

const ClearBtn = styled.button`
  background: none;
  color: rgb(118, 118, 118);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.25em;
  &:focus,
  &:hover {
    color: #333;
  }
`;

const Divider = styled.div`
  background-color: rgb(118, 118, 118);
  align-self: stretch;
  width: 0.05em;
`;
const Caret = styled.div`
  translate: 0 25%;
  border: 0.25em solid transparent;
  border-top-color: rgb(118, 118, 118);
  position: absolute;
  right: 8px;
  top: 20px;
`;
