import { ISelectOption } from 'components/common/inputs/inputTypes';
import React from 'react';
import ScannerTrainerForm from '../../trainers/outside/ScannerTrainerForm';
import { IFilterPlayerValue } from 'types/filterPassData';
import NewFilterPanel from "components/filters/NewFilterPanel";

import styled from "styled-components";

interface IScannerSideMenuProps {
  filterArray: any;
}

const ScannerSideMenuTrainer = ({
  filterArray
}: any) => {
  return (
    <div>
      <SideMenu isMenuOpen={true}>
        <NewFilterPanel filters={filterArray} />
      </SideMenu>
    </div>
  );
};

export default ScannerSideMenuTrainer;


const SideMenu = styled.div<{ isMenuOpen: boolean }>`

  position: relative;
   height: calc(100vh - 78px);
  // top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  background: #202d38;
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  overflow-y: hidden,

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;
