import { IAcademyPrimaryDataForm } from "components/academy/detail/tabs/Informations/primaryData.interface";
import {
  IAcademy,
  IUpdateAcademyPrimaryData,
  IUpdateAcademyUnion,
} from "types/academy.interface";

export const getAcademyPrimaryData = (
  academy: IAcademy
): IUpdateAcademyPrimaryData => ({
  Description: academy.Description,
  NameShort: academy.NameShort,
  PhotoFilePath: academy.PhotoFilePath,
  WebsiteURL: academy.WebsiteURL,
});

export const getAcademyPrimaryDataForm = (
  academy: IAcademy
): IAcademyPrimaryDataForm => ({
  primaryData: {
    Description: academy.Description,
    NameShort: academy.NameShort,
    PhotoFilePath: academy.PhotoFilePath,
    WebsiteURL: academy.WebsiteURL,
  },
  union: academy.Union,
});
