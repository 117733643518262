import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BlurBox, SideMenu } from "styles/styles";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import Button from "components/common/buttons/basicButton/Button";
import { deliveryServices } from "services/marketpleace";
import { toast } from "react-toastify";
import { showServerErrors } from "utils/errorsUtils";
import DeliveryForm, { DeliveryType } from "./DeliveryForm/DeliveryForm";
import {
  IAddDelivery,
  IDelivery,
  IUpdateDelivery,
} from "types/marketpleace/delivery.interface";
import { IUpdateCombination } from "types/marketpleace/combination.interface";
import { deliveryInitialValues } from "./utils/helpers";
import AddDelivery from "./DeliveryForm/AddDelivery";
import EditDelivery from "./DeliveryForm/EditDelivery";
import { PRODUCT_TYPES } from "types/marketpleace/product.interface";
import styled from 'styled-components';


export const SideMenus = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 48px;
  right: 0;
  z-index: 99;
  height: calc(100vh - 48px);
  width: ${(props) => (props.isMenuOpen ? '630px' : '0px')};
  padding: ${(props) => (props.isMenuOpen ? '23px 0' : '23px 0')};
  gap: 9px;
  background: #202d38;
  color: rgba(163, 158, 157, 1);

  transition: width 0.4s ease-in-out;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? '1' : '0')};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    & > form {
      & > div {
        & > p {
          /* text-align: center; */
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;




interface IDeliveriesTopbarProps {
  deliveries: IDelivery[];
  refreshDeliveries: () => void;
  hideSideMenu: () => void;
  isAddMenuOpen: boolean;
  toggleAddMenuOpen: Dispatch<SetStateAction<boolean>>;
  isEditMenuOpen: boolean;
  toggleEditMenuOpen: Dispatch<SetStateAction<boolean>>;
  openAddSideMenu: () => void;
  selectedDelivery: IDelivery | null;
}

const DeliveriesTopbar = ({
  deliveries,
  refreshDeliveries,
  hideSideMenu,
  isAddMenuOpen,
  toggleAddMenuOpen,
  isEditMenuOpen,
  toggleEditMenuOpen,
  openAddSideMenu,
  selectedDelivery,
}: IDeliveriesTopbarProps) => {
  const [digitalDeliveryExists, setDigitalDeliveryExists] = useState(true);

  const addDigitalDelivery = async () => {
    try {
      await deliveryServices.addDigital();

      toast.success("Pomyślnie dodano fizyczną dostawę");

      refreshDeliveries();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  useEffect(() => {
    if (deliveries.length) {
      setDigitalDeliveryExists(
        !!deliveries.find(
          (d) => d.AvailableFor === PRODUCT_TYPES["Elektroniczny"]
        )
      );
    }
  }, [deliveries]);

  return (
    <div
      className="flex flex-wrap items-center justify-end gap-y-2"
      style={{ gap: "3px", marginTop: "8px" }}
    >
      <BlurBox
        isMenuOpen={isAddMenuOpen || isEditMenuOpen}
        onClick={hideSideMenu}
      />
      {!selectedDelivery ? null : (
        <SideMenu isMenuOpen={isEditMenuOpen && !isAddMenuOpen}>
          <div className="flex h-full">
            <EditDelivery
              editDelivery={selectedDelivery}
              refresh={refreshDeliveries}
              toggleEditMenuOpen={toggleEditMenuOpen}
            />
          </div>
        </SideMenu>
      )}

      <SideMenus isMenuOpen={isAddMenuOpen && !isEditMenuOpen}>
        <div className="flex h-full">
          <AddDelivery
            refresh={refreshDeliveries}
            toggleAddMenuOpen={toggleAddMenuOpen}
          />
        </div>
      </SideMenus>

      <Button
        onClick={addDigitalDelivery}
        variant={ButtonVariant.Add}
        disabled={digitalDeliveryExists}
      >
        {!digitalDeliveryExists
          ? "Dodaj elektroniczną dostawe"
          : "Posiadasz elektroniczną dostawę"}
      </Button>

      <Button onClick={openAddSideMenu} variant={ButtonVariant.Add}>
        Dodaj fizyczną dostawę
      </Button>
    </div>
  );
};

export default DeliveriesTopbar;
