import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IDigitalRelease,
  Product,
  IAddProduct,
  DigitalReleaseRequest,
} from "types/digitalReleaseTypes";

import { showServerErrors } from "utils/errorsUtils";

import { useTranslation } from "react-i18next";
import EditDigitalReleaseForm from "./EditDigitalReleaseForm";
import eventsServices from "services/eventsServices";
import systemServices from "services/systemServices";

const EditDigitalRelease: React.FC<any> = ({ event, toggleEditMenuOpen }) => {
  const [product, setProduct] = useState<IAddProduct | null>(null);
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const handleSubmit = async (
    digitalReleaseData: any,
    OccurenceDate: any,
    EndDate: any,
    Visibility: any,
    ActivityLevel: any,
    LocalizationType: any,
    ActivityType: any,
    Limit: any,
    allPlayers: any,
    OrganizerType: any,
    // RepetitationInterval: any,
    Latitude: number,
    Longitude: number,
    IsCalendarChecked: boolean,
    TimeInterval: any,
    IsPostChecked: boolean,
    IsDraft: boolean,
    FilesArray: any,
    GroupIds: any,
  ) => {
    try {
      const newProduct = digitalReleaseData;
      console.log("newproduct", newProduct);
      const imageUrl =
        IsPostChecked && newProduct?.photos
          ? await systemServices.uploadFile(newProduct?.photos)
          : "";

      const newOccurenceDate = new Date(OccurenceDate);
      const occurenceDateUTC = newOccurenceDate.toISOString();

      const newEndDate = new Date(EndDate);
      const endDateUTC = newEndDate.toISOString();

      await eventsServices.editEvent(
        newProduct,
        imageUrl,
        occurenceDateUTC,
        endDateUTC,
        Visibility,
        ActivityLevel,
        LocalizationType,
        ActivityType,
        Limit,
        allPlayers,
        OrganizerType,
        Latitude,
        Longitude,
        IsCalendarChecked,
        TimeInterval,
        IsPostChecked,
        IsDraft,
        FilesArray,
        GroupIds,
        id,
      );
      toast.success("Edytowano zajęcie!");
      goBack();
    } catch (errors: any) {
      showServerErrors(errors);
    }
  };

  return (
    event && (
      <>
        <EditDigitalReleaseForm
          event={event}
          onSubmit={handleSubmit}
          isEdit={true}
          toggleEditMenuOpen={toggleEditMenuOpen}
        />
      </>
    )
  );
};

export default EditDigitalRelease;
