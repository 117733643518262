import styled from "styled-components";
import React, { useState, useEffect } from "react";
import notificationsServices from "services/notificationsServices";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { updateNotification } from '../../store/actions/notificationData';
import { Notification } from "types/notificatonTypes";

export const Div = styled.div<{ notificationsNumber?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  gap: 11px;
  // padding-bottom: 12px;

  & > p {
    font-size: 10px;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
  }

  &:before {
    content: ${({ notificationsNumber }) => `"${notificationsNumber}"`};
    display: ${({ notificationsNumber }) =>
      notificationsNumber === "0" ? "none" : "flex"};
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    padding: 1px 0;
    margin-left: 30px;
    margin-bottom: -15px;
    font-size: ${({ notificationsNumber }) =>
      notificationsNumber !== undefined
        ? notificationsNumber >= "100"
          ? "8px"
          : "9px"
        : ""};
    font-weight: 600;
  }
`;

const Notifications = () => {
  const token = localStorage.getItem("token");
  const { push } = useHistory();
  const dispatch = useDispatch();
  const history = useHistory();

  const [notificationCounter, setNotificationsCounter] = useState("0");
  const isNotificatonNumChange = useSelector(
    (state: any) => state.notificationPageNumberReducer,
  );

  useEffect(() => {
    const getNotificationCounter = async () => {
      try {
        const response = await notificationsServices.getNotificationCounter();
        setNotificationsCounter(String(response));
      } catch (error) {
        console.log(error);
      }
    };
    getNotificationCounter();
  }, [isNotificatonNumChange]);

  const handleButtonClick = () => {
    history.push("/notifications");
  };

  return (
    <>
      <Div
        notificationsNumber={notificationCounter}
        onClick={handleButtonClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.75"
          height="23.25"
          viewBox="0 0 24.75 29.25"
          onClick={handleButtonClick}
        >
          <path
            id="Icon_ionic-md-notifications"
            data-name="Icon ionic-md-notifications"
            d="M18,32.625A2.927,2.927,0,0,0,20.912,29.7H15.088A2.927,2.927,0,0,0,18,32.625Zm9.463-8.775V15.806a9.479,9.479,0,0,0-7.279-9.214V5.569a2.184,2.184,0,1,0-4.368,0V6.592a9.479,9.479,0,0,0-7.279,9.214V23.85L5.625,26.775v1.462h24.75V26.775Z"
            transform="translate(-5.625 -3.375)"
            fill="#f8f8f8"
          />
        </svg>
        <p>powiadomienia</p>
      </Div>
    </>
  );
};

export default Notifications;
