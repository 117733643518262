import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import ContentContainer from "components/layout/ContentContainer";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { DefaultSortContext } from "contexts/defaultSortContext";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AcademiesTopbar from "./AcademiesTopbar";
import AcademiesTable from "./AcademiesTable";
import { getAcademyWithBranches } from "services/usersServices";

const Academies: React.FC = () => {
  const [defaultSort, setDefaultSort] = useContext(DefaultSortContext);
  const [sortBy, setSortBy] =
    useState<{ value: number; label: string } | null>(null);
  const [viewType, setViewType] = useState("list");
  const [sorts, setSorts] = useState<{ value: number; label: string }[]>([]);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const { id } = useParams<{ id: string }>();
  const [displayType, setDisplayType] = useState<{
    value: number;
    label: string;
  } | null>({
    label: "Zajęcia",
    value: 0,
  });
  const [displayTypes, setDisplayTypes] = useState<
    { value: number; label: string }[]
  >([]);

  const [queryString, setQueryString] = useState(() => {
    return localStorage.getItem("searchQuery") || "";
  });
  const [isDraftMenuOpen, toggleDraftMenuOpen] = useState(false);
  const [clickedCyclicalEventId, setClickedCyclicalEventId] = useState("");

  const {
    items: academies,
    loading,
    containerRef,
    lastItemRef,
    refresh,
  } = useInfiniteScroll<any>(
    // @ts-ignore
    getAcademyWithBranches,
    localStorage.getItem("searchQuery") || ""
  );

  useEffect(() => {
    console.log("academies", academies);
  }, [academies]);

  const getAllSorts = async () => {
    try {
      setSorts([
        {
          label: "Najnowsze",
          value: 0,
        },
        {
          label: "Najstarsze",
          value: 1,
        },
        {
          label: "Nazwa (A-Z)",
          value: 2,
        },
        {
          label: "Nazwa (Z-A)",
          value: 3,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSorts();
  }, []);

  useEffect(() => {
    if (sortBy) {
      // @ts-ignore
      setDefaultSort(sortBy.value);
    }
  }, [sortBy]);

  const handleQueryChange = (value: string) => {
    setQueryString(value);
    localStorage.setItem("searchQuery", value);
  };

  useEffect(() => {
    const storedQuery = localStorage.getItem("searchQuery");
    if (storedQuery) {
      setQueryString(storedQuery);
    }
  }, []);

  return (
    <ContentContainer
      title={"Akademie"}
      TopBar={
        <AcademiesTopbar
          displayType={displayType}
          setDisplayType={setDisplayType}
          displayTypes={displayTypes}
          sorts={sorts}
          sortBy={sortBy}
          setSortBy={setSortBy}
          isDraftMenuOpen={isDraftMenuOpen}
          toggleDraftMenuOpen={toggleDraftMenuOpen}
          clickedCyclicalEventId={clickedCyclicalEventId}
          setClickedCyclicalEventId={setClickedCyclicalEventId}
          handleQueryChange={handleQueryChange}
          defaultSort={defaultSort}
          viewType={viewType}
          setViewType={setViewType}
        />
      }
    >
      <div>
        <div>
          <AcademiesTable
            data={academies}
            containerRef={containerRef}
            lastItemRef={lastItemRef}
            isDataLoading={loading}
            sortBy={sortBy}
          />
        </div>
      </div>
    </ContentContainer>
  );
};

export default Academies;
