import { conn } from "api/BaseConnection";
import axios from "axios";

const createConfig = () => {
  const api = axios.create({
    baseURL: conn.base_url,
  });

  const token = localStorage.getItem("token");

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return api;
};

const api = createConfig();

export default api;
