import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ContentContainer from "components/layout/ContentContainer";
import InfoBox from "components/common/boxes/InfoBox/InfoBox";
import InfoBoxPlaceholder from "components/common/boxes/InfoBox/InfoBoxPlaceholder";
import Tabs from "components/common/tabs/Tabs";
import TabsView from "components/common/tabs/TabsView";
import TabContent from "components/common/tabs/TabContent";
// @ts-ignore
import Modal from "react-modal";
import DigitalReleaseDetailTopbar from "./EventsDetailTopbar";
import logo from "../../../assets/images/logo.svg";
import { showServerErrors } from "utils/errorsUtils";
import sellerServices from "services/sellerServices";
import axios from "axios";
import styled from "styled-components";
import EventsDetailTable from "./EventsDetailTable";
import { getEventDetail, addFile, deleteFile } from "services/eventServices";
import RelatedProfilesTab from "./Tabs/RelatedProfilesTab";
import ProductDescription from "./Tabs/ProductDescription";
import systemServices from "services/systemServices";
import EventComment from "./Tabs/EventComment";
import { getFormatedDate } from "utils/dateUtils";
import eventsServices from "services/eventsServices";
import EventStatistics from "./Tabs/EventStatistics";
import { useSelector } from "react-redux";
import { getOtherConnectionUrl } from "api/BaseConnection";

const EventsDetail: React.FC = () => {
  const [event, setEvent] = useState<any>(null);
  const [allAttendances, setAllAttendances] = useState([]);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedPlayerId, setSelectedPlayerId] = useState([]);
  const [selectedParam, setSelectedParam] = useState("");
  const [selectedTableParam, setSelectedTableParam] = useState("");
  const [selectedParamValueName, setSelectedParamValueName] = useState("");
  const minDate = new Date(event?.OccurenceDate);
  const maxDate = new Date(event?.EndDate);
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(maxDate);
  const [newSelectedImage, setNewSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const history = useHistory();

  const permission = useSelector((state: any) => state.permissions.permissions);

  useEffect(() => {
    const minDate = new Date(event?.OccurenceDate);
    setStartDate(minDate);
  }, [event?.OccurenceDate]);

  useEffect(() => {
    const maxDate = new Date(event?.EndDate);
    setEndDate(maxDate);
  }, [event?.EndDate]);

  const handleImageChange = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const reader = new FileReader();

      reader.onload = async (eventx: any) => {
        const base64StringWithPrefix = eventx.target.result;
        const base64String = base64StringWithPrefix.split(",")[1];

        try {
          const fileUrl = await systemServices.uploadFile(base64String);

          await addFile(event.EventId, fileName, fileUrl, fileUrl);

          getEventDetailFn();
          toast.success("Dodano zdjęcie!");
        } catch (errors: any) {
          showServerErrors(errors);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const openModal = (photo: any) => {
    setSelectedImage(photo);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  // const getAllAttendances = (EventId: string, ProfilesId: Array<string>) => {
  //   const body = {
  //     EventId,
  //     ProfileId: ProfilesId.map((profile: any) => profile.Id),
  //   };

  //   axios
  //     .post(
  //       `https://justwin.pl/statisticsdistributor/Statistics/Summary?timeInterval=11`,
  //       body,
  //     )
  //     .then((response: any) => {
  //       console.log("resp", response);
  //       // setAllAttendances(response.data);
  //     })
  //     .catch((errors: any) => {
  //       console.error(errors);
  //     });
  // };

  // useEffect(() => {
  //   if (event?.Attendances?.length > 0) {
  //     getAllAttendances(id, event.Attendances);
  //   }
  // }, [event]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      maxWidth: "80%",
      maxHeight: "80%",
    },
  };

  const organizerSwitch = (organizer: number) => {
    switch (organizer) {
      case 0:
        return "Justwin";
      case 1:
        return "Trener";
      case 2:
        return "Zawodnik";
      case 3:
        return "Klub";
      case 4:
        return "Reprezentacja";
      default:
        return organizer;
    }
  };

  const typeSwitch = (type: number) => {
    switch (type) {
      case 0:
        return "Trening piłkarski";
      case 1:
        return "Trening na siłowni";
      case 2:
        return "Trening personalny";
      case 3:
        return "Testy okresowe";
      case 4:
        return "Mecz piłkarski";
      default:
        return type;
    }
  };
  const levelSwitch = (level: number) => {
    switch (level) {
      case 0:
        return "Klub";
      case 1:
        return "Reprezentacja";
      // case 2:
      //   return "Własne";

      default:
        return level;
    }
  };

  const getEventDetailFn = async () => {
    try {
      const resp: any = await getEventDetail(id);
      setEvent(resp.Data);
      console.log(resp.Data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEventDetailFn();
  }, []);

  const StatisticHomeAcademiq = () => {
    setLoading(true);
    const body = {
      EventId: id,
      StatisticTypes: [
        115, 55, 50, 5, 10, 15, 20, 25, 30, 35, 40, 45, 60, 65, 70, 75, 80, 85,
        90, 95, 100, 105, 110, 120, 125, 130, 135,
      ],
    };

    const baseUrl = getOtherConnectionUrl("justwin");

    axios
      .post(`${baseUrl}/statisticsprocessor/GetEventStatistic`, body)
      .then((response) => {
        console.log("GetEventStatistic><><", response?.data);
        setAllAttendances(response?.data);
        setLoading(false);
      })
      .catch((errors: any) => {
        console.error(errors);
      });
  };

  useEffect(() => {
    StatisticHomeAcademiq();
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     getEventDetail(id)
  //       .then((data) => {
  //         setEvent(data.Data);
  //         console.log(data);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // }, [localStorage.getItem("token")]);

  // const getEventDetail = () => {
  //   eventsServices
  //     .getEvent(id)
  //     .then((event: any) => {
  //       setEvent(event);
  //     })
  //     .catch((errors) => {
  //       showServerErrors(errors);
  //     });
  // };

  // useEffect(() => {
  //   getEventDetail();
  // }, [id]);

  if (!event) {
    return <InfoBoxPlaceholder />;
  }
  console.log("event", event);

  const tabs = [
    {
      tab: {
        id: "attendances",
        label: "Uczestnicy",
      },
      content: (
        <TabContent id="attendances">
          <EventStatistics
            event={event}
            allAttendances={allAttendances}
            selectedPlayerId={selectedPlayerId}
            setSelectedPlayerId={setSelectedPlayerId}
            selectedParam={selectedParam}
            setSelectedParam={setSelectedParam}
            selectedParamValueName={selectedParamValueName}
            setSelectedParamValueName={setSelectedParamValueName}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <p
            style={{
              color: "white",
              marginBottom: "15px",
              paddingLeft: "10px",
              marginTop: "5px",
            }}
          >
            {event?.Limit?.HasLimit ? (
              <>
                Liczba dostępnych miejsc {event?.AttendanceCount}/
                {event?.Limit?.Limit}
              </>
            ) : (
              <>Liczba zawodników {event?.AttendanceCount}</>
            )}
          </p>
          <EventsDetailTable
            events={allAttendances}
            loading={loading}
            selectedPlayerId={selectedPlayerId}
            setSelectedPlayerId={setSelectedPlayerId}
            selectedParam={selectedParam}
            setSelectedParam={setSelectedParam}
            selectedParamValueName={selectedParamValueName}
            setSelectedParamValueName={setSelectedParamValueName}
            selectedTableParam={selectedTableParam}
            setSelectedTableParam={setSelectedTableParam}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </TabContent>
      ),
    },
    {
      tab: {
        id: "address",
        label: "Adres",
      },
      content: (
        <TabContent id="address">
          <RelatedProfilesTab event={event} getEventDetail={getEventDetailFn} />
        </TabContent>
      ),
    },

    {
      tab: {
        id: "description",
        label: "Post",
      },
      content: (
        <TabContent id="description">
          <ProductDescription
            event={event}
            getEventDetailFn={getEventDetailFn}
          />
        </TabContent>
      ),
    },
    {
      tab: {
        id: "comments",
        label: "Uwagi",
      },
      content: (
        <TabContent id="comments">
          <EventComment event={event} getEventDetailFn={getEventDetailFn} />
        </TabContent>
      ),
    },
  ];

  const tabsHelpers: { label: string; id: number }[] = [
    { label: "Uczestnicy", id: 142 },
    { label: "Adres", id: 143 },
    { label: "Post", id: 144 },
    { label: "Uwagi", id: 145 },
  ];

  const eventTabsWithPermissions = tabs.map((tab: any) => {
    const tabHelper = tabsHelpers.find(
      (helper) => helper.label === tab.tab.label,
    );

    const isTabPermissionActive = tabHelper
      ? permission.some((e: any) => e.Id === tabHelper.id)
      : false;

    return {
      ...tab,
      tab: {
        ...tab.tab,
        disabled: isTabPermissionActive ? false : true,
      },
    };
  });

  const tabsActive = permission[0]?.Id === -1 ? tabs : eventTabsWithPermissions;

  console.log(eventTabsWithPermissions, "eventTabsWithPermissions");

  return (
    <ContentContainer
      title={event.Name}
      TopBar={<DigitalReleaseDetailTopbar event={event} />}
      // TopBar={<></>}
      // path="/events"

      noArrow={true}
      fn={() => history.goBack()}
    >
      <div>
        <div className="flex flex-col">
          <InfoBox className="p-18">
            <InfoBox.Image
              // src={logo}
              src={
                event && event?.Files.length > 0
                  ? event?.Files[0].FilePath
                  : logo
              }
            />

            <InfoBox.Items>
              <InfoBox.InfoItem
                label={"Typ zadania"}
                value={`${typeSwitch(event.Type)}`}
              />
              <InfoBox.InfoItem
                label={"Poziom"}
                value={`${levelSwitch(event.Level)}`}
              />
              <InfoBox.InfoItem label={""} value={``} />
              <InfoBox.InfoItem
                label={"Data startu"}
                value={`${getFormatedDate(
                  utcToLocalDate(event?.OccurenceDate).slice(0, 10),
                  "dd.MM.yyyy",
                )} ${utcToLocalDate(event?.OccurenceDate).slice(11, 19)}`}
              />

              <InfoBox.InfoItem
                label={"Liczba uczestników"}
                value={event?.AttendanceCount}
              />
              <InfoBox.InfoItem label={""} value={``} />
              <InfoBox.InfoItem
                label={"Data końcowa"}
                value={`${getFormatedDate(
                  utcToLocalDate(event?.EndDate)?.slice(0, 10),
                  "dd.MM.yyyy",
                )} ${utcToLocalDate(event?.EndDate)?.slice(11, 19)}`}
              />
              <InfoBox.InfoItem label={""} value={``} />
              <InfoBox.InfoItem label={""} value={``} />
            </InfoBox.Items>
          </InfoBox>
        </div>

        <TabsView>
          <Tabs tabs={tabsActive.map((t) => t.tab)} />
          <div style={{ padding: "10px 0 0 0" }}>
            {tabsActive.map((t) => t.content)}
          </div>
        </TabsView>
      </div>
    </ContentContainer>
  );
};

export default EventsDetail;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 16px;
`;

const GridItem = styled.div`
  background-color: #f0f0f0;
  position: relative;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  & > img {
    width: 100%;
    height: auto;
  }

  & > div {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid black;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: red;
    cursor: pointer;
  }
`;

const NewGridItem = styled.div`
  cursor: pointer;
  background-color: #f0f0f0;
  position: relative;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const utcToLocalDate = (utcDate: any): any => {
  const newDate: any = new Date(utcDate);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0");
  const day = String(newDate.getDate()).padStart(2, "0");
  const hours = String(newDate.getHours()).padStart(2, "0");
  const minutes = String(newDate.getMinutes()).padStart(2, "0");
  const seconds = String(newDate.getSeconds()).padStart(2, "0");
  const milliseconds = String(newDate.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};
