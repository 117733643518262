import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GridColumn } from "styles/styles";
import AcademyDocumentsAddForm from "./AcademyDocumentsAddForm";
import AcademyDocumentsRow from "./AcademyDocumentsRow";
import { IPass, IPassDetail } from "types/transfer/pass.interface";
import AcademyDocumentsHeaders from "./AcademyDocumentsHeaders";

interface IAcademyPassesTableProps {
  isAddFormDisplayed: boolean;
  displayAddForm: Dispatch<SetStateAction<boolean>>;
  isEditMode: boolean;
  passes: IPass[];
  setPass: Dispatch<SetStateAction<IPass | null>>;
  refreshPasses: () => Promise<void>;
}

const AcademyDocumentsTable = ({
  displayAddForm,
  isAddFormDisplayed,
  isEditMode,
  passes,
  setPass,
  refreshPasses,
}: IAcademyPassesTableProps) => {
  const [updatedPass, setUpdatedPass] = useState<IPass>();

  useEffect(() => {
    console.log(updatedPass);
  }, [updatedPass]);

  return (
    <div>
      <GridColumn cols={6} largerColumn={1}>
        <AcademyDocumentsHeaders />
        {isAddFormDisplayed && (
          <AcademyDocumentsAddForm
            displayAddForm={displayAddForm}
            refreshPasses={refreshPasses}
          />
        )}

        {passes.map((pass: any, idx: number) => (
          <AcademyDocumentsRow
            isEditMode={isEditMode}
            updatedPass={
              updatedPass?.PassId === pass.PassId ? updatedPass : undefined
            }
            setUpdatedPass={setUpdatedPass}
            pass={pass}
            setPass={setPass}
            refreshPasses={refreshPasses}
            key={idx}
          />
        ))}
      </GridColumn>
    </div>
  );
};

export default AcademyDocumentsTable;
