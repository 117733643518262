import { useTranslation } from "react-i18next";
import checkedSvg from "assets/icons/statistic-checkbox.svg";
import uncheckedSvg from "assets/icons/statistic-checkbox-unchecked.svg";
import DataTable from "components/common/dataTable/DataTable";
import AttendancesDataTable from "components/common/dataTable/AttendancesDataTable";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getOtherConnectionUrl } from "api/BaseConnection";

interface IDigitalReleaseTableProps {
  events: Array<any>;
  loading: any;
  selectedPlayerId: any;
  setSelectedPlayerId: any;
  selectedParam: any;
  setSelectedParam: any;
  selectedParamValueName: any;
  setSelectedParamValueName: any;
  selectedTableParam: any;
  setSelectedTableParam: any;
  startDate: any;
  endDate: any;
  setStartDate: any;
  setEndDate: any;
  //   containerRef: any;
  //   lastItemRef: any;
  //   isDataLoading: boolean;
  //   sortBy: any;
}

const EventsDetailTable: React.FC<IDigitalReleaseTableProps> = ({
  events,
  loading,
  selectedPlayerId,
  setSelectedPlayerId,
  selectedParam,
  setSelectedParam,
  selectedParamValueName,
  setSelectedParamValueName,
  selectedTableParam,
  setSelectedTableParam,
  endDate,
  setEndDate,
  setStartDate,
  startDate,

  //   containerRef,
  //   isDataLoading,
  //   lastItemRef,
  //   sortBy,
}) => {
  const [clickedIndex, setClickedIndex] = useState(null);
  const { id } = useParams<{ id: string }>();
  const [waitingUsers, setWaitingUsers] = useState<any>([]);

  const RemoveStatisticAndImportData = (profileId: string) => {
    const baseUrl = getOtherConnectionUrl("justwin");

    axios
      .post(
        `${baseUrl}/statisticsprocessor/RemoveStatisticAndImportData?eventId=${id}&profileId=${profileId}`,
      )
      .then((response) => {
        console.log("RemoveStatisticAndImportData><><", response);
      })
      .catch((errors: any) => {
        console.error(errors);
      });
  };

  const GetUAMStatistic = (profileId: string) => {
    const baseUrl = getOtherConnectionUrl("justwin");

    axios
      .post(`${baseUrl}/statisticsprocessor/GetUAMStatistic/${id}-${profileId}`)
      .then((response) => {
        console.log("GetUAMStatistic><><", response);
        toast.info(
          `Status: ${
            response?.data?.Status?.Status
              ? response?.data?.Status?.Status
              : "-null-"
          }`,
        );
      })
      .catch((errors: any) => {
        console.error(errors);
      });
  };

  const decodeBase64 = (base64String: any) => {
    try {
      const jsonString = atob(base64String);
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Invalid Base64 string", error);
      return null;
    }
  };

  const saveToFile = (data: any) => {
    if (data) {
      // const decodedData = decodeBase64(base64String);
      const jsonString = JSON.stringify(data, null, 2);

      const blob = new Blob([jsonString], { type: "text/plain;charset=utf-8" });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "decoded_data.json";

      link.click();

      URL.revokeObjectURL(url);
    } else {
      console.warn("No data available to save.");
    }
  };

  const DownloadStatisticFile = async (profileId: string) => {
    const params = {
      eventId: id,
      profileId,
    };

    try {
      const baseUrl = getOtherConnectionUrl("justwin");

      const resp = await axios.get(
        `${baseUrl}/statisticsprocessor/DownloadStatisticFile`,
        { params },
      );
      console.log("DownloadStatisticFile><><", resp);
      const statisticsData = await resp?.data;
      console.log("statisticsData", statisticsData);
      saveToFile(statisticsData);
    } catch (errors: any) {
      console.error(errors);
    }
  };

  const GetUsersWaitForStatistics = async () => {
    const params = {
      eventId: id,
    };

    try {
      const baseUrl = getOtherConnectionUrl("justwin");

      const resp = await axios.get(
        `${baseUrl}/statisticsprocessor/GetUsersWaitForStatistics`,
        { params },
      );
      console.log("GetUsersWaitForStatistics><><", resp);
      setWaitingUsers(resp.data);
    } catch (errors: any) {
      console.error(errors);
    }
  };

  useEffect(() => {
    GetUsersWaitForStatistics();
  }, []);

  const RemoveStatistic = async (profileId: string) => {
    try {
      const baseUrl = getOtherConnectionUrl("justwin");

      const resp = await axios.delete(
        `${baseUrl}/statisticsprocessor/RemoveStatistic/${id}/${profileId}`,
      );
      console.log("RemoveStatistic><><", resp);
    } catch (errors: any) {
      console.error(errors);
    }
  };

  useEffect(() => {
    console.log("CLICKEDINDEX>>>", clickedIndex);
  }, [clickedIndex]);

  const { t } = useTranslation();
  const headers = [
    "",
    "Imię",
    "Nazwisko",
    "Rocznik",
    "Dystans",
    "Prędkość",
    "Hsr",
    "HMLD",
    "Dynamika",
    // "Liczba zajęć w systemie",
    "Kondycja",
    "Energia",
    "Tętno",
    "",
    // "Status",
  ];
  console.log("attendences", events);

  const compare = (a: any, b: any, param: any) => {
    if (a[param] > b[param]) {
      return -1;
    }
    if (a[param] < b[param]) {
      return 1;
    }
    return 0;
  };

  function roundToTwo(num: number): number {
    return +(Math.round(num * 100) / 100).toFixed(2);
  }

  const sortingFunction = (type: number, array: any) => {
    switch (type) {
      case 0:
        return array.slice().reverse();
      case 1:
        return array;
      case 2:
        return array
          .slice()
          .sort((a: any, b: any) => compare(a, b, "Name"))
          .reverse();
      case 3:
        return array.slice().sort((a: any, b: any) => compare(a, b, "Name"));
      default:
        return array;
    }
  };

  const colorSwitch = (index: number) => {
    switch (index) {
      case 0:
        return "#C41EC4";
      case 1:
        return "#F97D00";
      case 2:
        return "#1060FF";
      case 3:
        return "#88E03C";
      case 4:
        return "#00FFFF";
      case 5:
        return "#FF74D6";
      case 6:
        return "#FFFFFF";
      case 7:
        return "#FF0000";
      case 8:
        return "#EEFF00";
      case 9:
        return "#00B3FF";
      case 10:
        return "#3B9E45";

      default:
        return "#8F8F8F";
    }
  };

  const handleCheckboxChange = (playerId: number) => {
    setSelectedPlayerId((prevSelectedIds: any) => {
      if (prevSelectedIds.includes(playerId)) {
        return prevSelectedIds.filter((id: any) => id !== playerId);
      } else {
        if (prevSelectedIds.length === 11) {
          return [...prevSelectedIds];
        } else {
          return [...prevSelectedIds, playerId];
        }
      }
    });
  };

  // const sumDistances = (event: any) => {
  //   if (!event.StatisticData?.Distance) {
  //     return 0;
  //   }
  //   return event.StatisticData.Distance.reduce(
  //     (sum: any, distanceEntry: any) => {
  //       return sum + (distanceEntry.Value || 0);
  //     },
  //     0,
  //   );
  // };

  const sumDistances = (event: any) => {
    if (!event.StatisticData?.Distance) {
      return 0;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    return event.StatisticData.Distance.reduce(
      (sum: any, distanceEntry: any) => {
        const recordDate = new Date(distanceEntry.RecordDate);

        if (recordDate >= start && recordDate <= end) {
          return sum + (distanceEntry.Value || 0);
        }
        return sum;
      },
      0,
    );
  };

  const calculateAverage = (arr: any) => {
    if (!arr || arr.length === 0) {
      return 0;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const filteredArr = arr.filter((item: any) => {
      const recordDate = new Date(item.RecordDate);
      return item.Value !== 0 && recordDate >= start && recordDate <= end;
    });

    if (filteredArr.length === 0) {
      return 0;
    }

    const sum = filteredArr.reduce(
      (total: any, item: any) => total + (item.Value || 0),
      0,
    );

    return sum / filteredArr.length;
  };

  // const calculateAverage = (arr: any) => {
  //   if (!arr || arr.length === 0) {
  //     return 0;
  //   }

  //   const filteredArr = arr.filter((item: any) => item.Value !== 0);

  //   if (filteredArr.length === 0) {
  //     return 0;
  //   }

  //   const sum = filteredArr.reduce(
  //     (total: any, item: any) => total + (item.Value || 0),
  //     0,
  //   );

  //   return sum / filteredArr.length;
  // };

  const averageSpeed = (event: any) => {
    return calculateAverage(event.StatisticData?.Speed);
  };

  const averageHeartRate = (event: any) => {
    return calculateAverage(event.StatisticData?.HeartRate);
  };

  const averageHSR = (event: any) => {
    return calculateAverage(event.StatisticData?.HsrDistance);
  };

  const averageHmld = (event: any) => {
    return calculateAverage(event.StatisticData?.HmldDistance);
  };

  //   const sortedArray = sortingFunction(sortBy?.value, events);
  const enrichedEvents = events.map((event) => ({
    ...event,
    totalDistance: sumDistances(event),
    avgSpeed: averageSpeed(event),
    avgHeartRate: averageHeartRate(event),
    avgHSR: averageHSR(event),
    avgHmld: averageHmld(event),
  }));

  const sortedEvents = [...enrichedEvents].sort((a, b) =>
    compare(a, b, selectedTableParam),
  );

  const rows = waitingUsers
    .map((user: any) => ({ ...user, isWaitingUser: true }))
    .concat(sortedEvents)
    .map((event: any, index: number) => {
      const totalDistance = sumDistances(event);
      const avgSpeed = averageSpeed(event);
      const avgHeartRate = averageHeartRate(event);
      const avgHSR = averageHSR(event);
      const avgHmld = averageHmld(event);
      const profileIndex = selectedPlayerId.indexOf(event.ProfileId);
      console.log("EVENTHSR", avgHSR);

      const isWaitingUser = event.isWaitingUser || false;
      if (isWaitingUser)
        return {
          // data: { link: `/players/detail/${event.AcademyId}` },
          cols: [
            <div></div>,
            `${event.FirstName}`,
            `${event.LastName}`,
            `${event.YearOfBirth}`,
            `-`,
            `-`,
            `-`,
            `-`,
            `-`,
            `-`,
            `-`,
            `-`,
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Button
                className="flex-1 md:flex-grow-0 py-8 px-18"
                variant={ButtonVariant.Remove}
                onClick={() => RemoveStatisticAndImportData(event?.ProfileId)}
                style={{
                  background: "rgb(87, 83, 83)",
                  width: "100%",
                  height: "22px",
                  fontSize: "9px",
                  fontWeight: 400,
                }}
              >
                Ponowne przetwarzanie
              </Button>
              <Button
                className="flex-1 md:flex-grow-0 py-8 px-18"
                variant={ButtonVariant.Remove}
                onClick={() => GetUAMStatistic(event?.ProfileId)}
                style={{
                  background: "rgb(7, 124, 17)",
                  width: "100%",
                  height: "22px",
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                Status
              </Button>
            </div>,
          ],
        };
      else
        return {
          // data: { link: `/players/detail/${event.AcademyId}` },
          cols: [
            <StyledCheckbox
              checkboxColor={colorSwitch(profileIndex)}
              checked={selectedPlayerId.includes(event?.ProfileId)}
              onClick={() => handleCheckboxChange(event?.ProfileId)}
            >
              {selectedPlayerId.includes(event?.ProfileId) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 28 28"
                >
                  <g
                    id="Rectangle_14443"
                    data-name="Rectangle 14443"
                    fill="rgba(156,176,196,0.11)"
                    stroke="#00b3ff"
                    stroke-width="1"
                  >
                    <rect width="40" height="40" stroke="none" />
                    <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
                  </g>
                  <path
                    id="Icon_open-check"
                    data-name="Icon open-check"
                    d="M11.1,0l-1.2,1.247L5.093,6.063,3.69,4.712,2.443,3.465,0,5.907,1.247,7.154l2.6,2.6L5.041,11,6.288,9.753,12.351,3.69,13.6,2.443,11.1,0Z"
                    transform="translate(7.377 8)"
                    fill="#00b3ff"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 28 28"
                >
                  <g
                    id="Rectangle_14419"
                    data-name="Rectangle 14419"
                    fill="rgba(156,176,196,0.11)"
                    stroke="#8f8f8f"
                    stroke-width="1"
                  >
                    <rect width="40" height="40" stroke="none" />
                    <rect x="0.5" y="0.5" width="27" height="27" fill="none" />
                  </g>
                  <path
                    id="Icon_open-check"
                    data-name="Icon open-check"
                    d="M11.1,0l-1.2,1.247L5.093,6.063,3.69,4.712,2.443,3.465,0,5.907,1.247,7.154l2.6,2.6L5.041,11,6.288,9.753,12.351,3.69,13.6,2.443,11.1,0Z"
                    transform="translate(7.377 8)"
                    fill="#8f8f8f"
                  />
                </svg>
              )}
            </StyledCheckbox>,
            `${event.FirstName}`,
            `${event.LastName}`,
            `${event.YearOfBirth}`,
            `${totalDistance ? `${roundToTwo(totalDistance)} m` : "-"}`,
            `${avgSpeed ? `${roundToTwo(avgSpeed)} km/h` : "-"}`,
            `${avgHSR ? `${roundToTwo(avgHSR)} %` : "-"}`,
            `${avgHmld ? `${roundToTwo(avgHmld)} %` : "-"}`,
            `${event?.Dynamic ? `${roundToTwo(event.Dynamic)}` : "-"}`,
            `${event?.Fitness ? `${roundToTwo(event.Fitness)}` : "-"}`,
            `${event?.Energy ? `${roundToTwo(event.Energy)}` : "-"}`,
            `${avgHeartRate ? `${roundToTwo(avgHeartRate)} bpm` : "-"}`,
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <Button
                className="flex-1 md:flex-grow-0 py-8 px-18"
                variant={ButtonVariant.Submit}
                onClick={() => DownloadStatisticFile(event?.ProfileId)}
                style={{
                  background: "rgb(0, 160, 255)",
                  width: "100%",
                  height: "22px",
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                Pobierz
              </Button>
              <Button
                className="flex-1 md:flex-grow-0 py-8 px-18"
                variant={ButtonVariant.Remove}
                onClick={() => RemoveStatisticAndImportData(event?.ProfileId)}
                style={{
                  background: "rgb(87, 83, 83)",
                  width: "100%",
                  height: "22px",
                  fontSize: "9px",
                  fontWeight: 400,
                }}
              >
                Ponowne przetwarzanie
              </Button>
              <Button
                className="flex-1 md:flex-grow-0 py-8 px-18"
                variant={ButtonVariant.Remove}
                onClick={() => RemoveStatistic(event?.ProfileId)}
                style={{
                  background: "rgb(220, 33, 33)",
                  width: "100%",
                  height: "22px",
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                Usuń
              </Button>

              <Button
                className="flex-1 md:flex-grow-0 py-8 px-18"
                variant={ButtonVariant.Remove}
                onClick={() => GetUAMStatistic(event?.ProfileId)}
                style={{
                  background: "rgb(7, 124, 17)",
                  width: "100%",
                  height: "22px",
                  fontSize: "13px",
                  fontWeight: 400,
                }}
              >
                Status
              </Button>
            </div>,
          ],
        };
    });

  // const rows = events.map((event: any) => ({
  //   cols: [
  //     <CheckboxWithSVG
  //       key={event.ProfileId}
  //       selectedPlayerId={selectedPlayerId}
  //       event={event}
  //       handleCheckboxChange={handleCheckboxChange}
  //     />,
  //     `${event.FirstName}`,
  //     `${event.LastName}`,
  //     `${event.YearOfBirth}`,
  //     `${event.Dynamic ? roundToTwo(event.Dynamic) : "-"}`,
  //     `${event.Energy ? roundToTwo(event.Energy) : "-"}`,
  //     `${event.Distance ? roundToTwo(event.Distance) : "-"}`,
  //     `${event.Speed ? roundToTwo(event.Speed) : "-"}`,
  //     `${event.HSR ? roundToTwo(event.HSR) : "-"}`,
  //     `${event.HMLD ? roundToTwo(event.HMLD) : "-"}`,
  //     `${event.Fitness ? roundToTwo(event.Fitness) : "-"}`,
  //     `${event.HeartRate ? roundToTwo(event.HeartRate) : "-"}`,
  //   ],
  // }));
  return (
    <AttendancesDataTable
      selectedPlayerId={selectedPlayerId}
      setSelectedPlayerId={setSelectedPlayerId}
      selectedParam={selectedParam}
      setSelectedParam={setSelectedParam}
      selectedTableParam={selectedTableParam}
      setSelectedTableParam={setSelectedTableParam}
      selectedParamValueName={selectedParamValueName}
      setSelectedParamValueName={setSelectedParamValueName}
      clickedIndex={clickedIndex}
      setClickedIndex={setClickedIndex}
      rows={rows}
      headers={headers}
      //   @ts-ignore
      isDataLoading={loading}
      containerRef={null}
      lastItemRef={null}
    />
  );
};

export default EventsDetailTable;

const StyledCheckbox = styled.div<{ checked: boolean; checkboxColor: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    & g {
      stroke: ${({ checkboxColor, checked }) =>
        checked ? checkboxColor : "rgba(156,176,196,0.11)"};
    }

    & path {
      /* stroke: ${({ checkboxColor, checked }) =>
        checked ? checkboxColor : "rgba(156,176,196,0.11)"}; */
      fill: ${({ checkboxColor, checked }) =>
        checked ? checkboxColor : "rgba(156,176,196,0.11)"};
    }
  }
  /* display: inline-block;
  appearance: none;
  background: ${(props) =>
    props.checked ? `url(${checkedSvg})` : `url(${uncheckedSvg})`};

  background-size: contain;
  transition: all 150ms;
  cursor: pointer;
  fill: red !important;
  stroke: red !important; */
`;
