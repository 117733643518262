export interface ICreateFreePass {
  Name: string;
  PaymentType: PASS_PAYMENT_TYPE;
  Type: PASS_TYPE;
  Period: IPassPeriod;
}

export interface ICreatePaidPass extends ICreateFreePass {
  Tax: number;
  Netto: number;
}

export interface IPass extends ICreatePaidPass {
  PassId: string;
  Gross: number;
  IsFree: boolean;
  IsAssignedToAnyPlayer: boolean;
}

export interface IPassAppliedProfile {
  ProfileId: string;
  Name: string;
}

export interface IPassDetail extends IPass {
  AppliedProfiles: IPassAppliedProfile[];
}

export interface IPassForm extends ICreatePaidPass {
  NettoString: string;
}

export interface IChangePassPrice {
  PassId: string;
  Tax: number;
  Netto: number;
}

export interface IPassDto {
  PassId: string;
  Name: string;
}

export interface IPassPeriod {
  StartAt: Date | null;
  EndAt: Date | null;
}

export interface ITransactionBuyer {
  PlayerId: string;
  Name: string;
}

export interface IPassPrice {
  Tax: number;
  Netto: number;
  Gross: number;
}

export interface IPassTransaction {
  TransactionId: string;
  Number: string;
  ValitTo: Date;
  Buyer: ITransactionBuyer;
  Period: IPassPeriod;
  Pass: IPassDto;
  Price: IPassPrice;
  Status: PASS_TRANSACTION_STATUS;
  detailPassPermission?: boolean;
  PaymentMethod: PASS_PAYMENT_METHOD;
}

export interface IPlayerHistoryPass {
  TransactionId: string;
  Number: string;
  Status: PASS_TRANSACTION_STATUS;
  CreatedAt: Date;
  PaidAt: Date;
  Period: IPassPeriod;
  Pass: IPassDto;
  Price: IPassPrice;
}
export enum PASS_TRANSACTION_STATUS {
  "Zapłacony",
  "Oczekuje na zapłatę",
  "Zweryfikowany",
  "Niezapłacony",
  "Zaległy",
  "Anulowany",
}

export enum PASS_PAYMENT_METHOD {
  "Gotówka",
  "Przelew",
}

export enum PASSES_SORT_LIST {
  "Najnowsze",
  "Najstarsze",
}

export enum PASS_PAYMENT_TYPE {
  "Miesięcznie",
  "Ratalnie",
  "Całość",
}

export enum PASS_TYPE {
  "Nieokreślony",
  "Określony",
}

export enum PASS_PRICE_TYPE {
  "Płatny",
  "Darmowy",
}

export interface IPass {
  PassId: string;
  Name: string;
  Tax: number;
  Netto: number;
  Gross: number;
  IsFree: boolean;
  PaymentType: number;
  Type: number;
  Period: IPassPeriod;
  IsAssignedToAnyPlayer: boolean;
}
