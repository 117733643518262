import TabsView from "components/common/tabs/TabsView";
import React from "react";
import AcademyInformations from "./Informations/AcademyInformations";
import { getDetailTabsByEnum } from "utils/objectUtils";
import Tabs from "components/common/tabs/Tabs";
import AcademyConsents from "./Consents/AcademyConsents";
import AcademyPasses from "./Passes/AcademyPasses";
import AcademyLicence from "./Licence/AcademyLicence";
import AcademyLocalizations from "./Localizations/AcademyLocalizations";
import AcademyMerchant from "./Merchant/AcademyMerchant";

import { useSelector } from "react-redux";
import AcademyDocuments from "./Documents/AcademyDocuments";

export enum ACADEMY_TABS {
  informations = "Dane",
  licence = "Licencja",
  merchant = "Sprzedawca",
  passes = "Składki",
  localizations = "Ulubione lokalizacje",
  documents = "Dokumenty",
}

const PERMISSIONS: Record<string, string> = {
  "123": "Dane",
  "124": "Licencja",
  "125": "Sprzedawca",
  "126": "Składki",
  "127": "Ulubione lokalizacje",

  // "118": "Zaktualizuj podstawowe dane Akademii",
  // "20": "Aktywuj sprzedawcę",
  // "12": "Edytuj składkę",
  // "13": "Zmiena ceny składki Akademii",
  // "129": "Edytuj lokalizację",
};

const AcademyTabs = () => {
  const academyTabs = getDetailTabsByEnum(ACADEMY_TABS);

  const permission = useSelector((state: any) => state.permissions.permissions);

  const academyTabsWithPermissions = academyTabs.map((tab) => {
    return {
      ...tab,
      disabled: !permission.find(
        (permission: any) => PERMISSIONS[permission.Id] === tab.label,
      ),
    };
  });

  const isAdmin = permission[0]?.Id === -1;

  const isUpdatePrimaryData = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 118);

  const tabs = isAdmin ? academyTabs : academyTabsWithPermissions;

  const isSellerActivated = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 20);

  const isSellerActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 125);

  const isPassEditPermissionActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 13);

  const isLocalizationPermissionActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 129);

  const isLocatlizationListActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 127);

  const isPassAddPermissionActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 12);

  const isPassListActive = isAdmin
    ? true
    : !!permission.find((permission: any) => permission.Id === 126);

  return (
    <TabsView>
      <Tabs tabs={tabs} />
      <div className="flex gap-4 p-24 text-white">
        <AcademyInformations isUpdatePrimaryData={isUpdatePrimaryData} />
        <AcademyPasses
          isPassEditPermissionActive={isPassEditPermissionActive}
          isPassAddPermissionActive={isPassAddPermissionActive}
          isPassListActive={isPassListActive}
        />
        <AcademyDocuments
          isPassEditPermissionActive={isPassEditPermissionActive}
          isPassAddPermissionActive={isPassAddPermissionActive}
          isPassListActive={isPassListActive}
        />
        <AcademyLicence />
        <AcademyLocalizations
          isLocalizationPermissionActive={isLocalizationPermissionActive}
          isLocatlizationListActive={isLocatlizationListActive}
        />
        <AcademyMerchant
          isSellerActivated={isSellerActivated}
          isAdmin={isAdmin}
          isSellerActive={isSellerActive}
        />
      </div>
    </TabsView>
  );
};

export default AcademyTabs;
