// @ts-nocheck
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import styled from "styled-components";
import TabContent from "components/common/tabs/TabContent";

import leftArrowIco from "../../../../../../assets/icons/left-arrow.png";
import rightArrowIco from "../../../../../../assets/icons/right-arrow.png";

import {
  Line,
  Bar,
  Bubble,
  Chart,
  PolarArea,
  Radar,
  Scatter,
  ChartProps,
  Doughnut,
  Pie,
} from "react-chartjs-2";
import { Chart as ChartJS, defaults, registerables } from "chart.js";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import SelectProfiles from "components/common/inputs/select/SelectProfiles";
import TextInput from "components/common/inputs/textInput/TextInput";
import StatisticsTextInput from "components/common/inputs/textInput/StatisticsTextInput";
import { PlayerProfileDetailInterface } from "types/userTypes";
import playerProfileServices from "services/playerProfileServices";
import { useParams } from "react-router-dom";
import axios from "axios";
import { showServerErrors } from "utils/errorsUtils";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import StatisticsDropDown from "components/players/detail/tabs/PressMaterials/StatisticsDropDown";
import TextField from "components/common/inputs/textInput/TextField";
import { idText } from "typescript";
import { getOtherConnectionUrl } from "api/BaseConnection";

const TimeAndDateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1536px) and (max-width: 1797px) {
    margin-bottom: 5px;
  }
`;

const TimeInput = styled.input`
  height: 42px;
  width: 84px;
  line-height: 1rem;
  background-color: transparent;
  border: 1px solid lightgray;
  padding: 5px 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: unset;
  color: white;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const DateInput = styled(StatisticsTextInput)`
  width: 193px;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  background-color: transparent;
  /* background: rgba(0, 0, 0, 0.1); */
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;

  &::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
`;

const ChartBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  height: 558.5px;
  /* border-top: 1px solid #a39e9d; */
  /* border-bottom: 1px solid #a39e9d; */

  & > div:nth-of-type(1) {
    width: 48px;
    height: calc(100% + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #02020f;
  }

  & > div:nth-of-type(2) {
    width: calc(100% - 96px);
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #a39e9d;

    & > canvas {
      z-index: 1;
      height: ${(props) => (props.height <= 80 ? `95%` : "558.5px")} !important;
    }

    & > div:nth-of-type(1) {
      position: absolute;
      height: 100%;
      width: 54px;
      /* background: rgba(163, 158, 157, 0.09); */
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 0%,
        rgba(163, 158, 157, 0.09) 100%
      );
      top: 0;
      left: 0;
    }

    & > div:nth-of-type(2) {
      position: absolute;
      height: 50px;
      border-top: 1px solid #a39e9d;
      width: 100%;
      background: rgba(7, 16, 28, 1);
      /* background: black; */
      bottom: 0;
      left: 0;
      z-index: 0;
    }
  }

  & > div:nth-of-type(3) {
    width: 48px;
    height: calc(100% + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #02020f;
  }
`;

const LeftArrow = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  cursor: pointer;
  font-size: 18px;
  pointer-events: all;
  user-select: none;
`;

const RightArrow = styled(LeftArrow)``;

const BlockDiv = styled.div`
  display: block;
  padding: 1px 8px;

  @media (min-width: 1798px) {
    display: flex;
    align-items: center;
  }
`;

/* interface Props {
  playerProfile: PlayerProfileDetailInterface;
  ownerId: string;
} */

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string;
  }[];
}

interface Props {
  playerProfile: ChartData | null;
  ownerId: string;
  getTrainingsData: (value: string[]) => void;
}

interface bodyData {
  UserId: string;
  ProfileId: string;
  ProfileType: number;
  TimeInterval: number;
  RangeFrom: string;
  RangeTo: string;
  Partition: number;
  Statistics: number[];
}

const StatisticsTab: React.FC<Props> = ({
  playerProfile,
  ownerId,
  getTrainingsData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [dateFrom, setDateFrom] = useState(
    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
  );
  const [dateTo, setDateTo] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    window.addEventListener("resize", () => {
      setPageWidth(window.innerWidth);
    });
  }, []);

  const [customDataFrom, setCustomDataFrom] = useState();
  const [customDataTo, setCustomDataTo] = useState();

  const [currentIdUser, setCurrentIdUser] = useState("");

  useEffect(() => {
    if (id === undefined) {
      setCurrentIdUser(ownerId);
    } else {
      setCurrentIdUser(id);
    }
  }, [id, ownerId]);

  const handleDateChangeFrom = (e) => {
    const selectedDate = e.target.value;
    const [year, month, day] = selectedDate.split("-");

    if (year.startsWith("0") || month === "00" || day === "00") {
      console.log(
        "Invalid date: year starts with 0 or month/day is 00. Not updating state.",
      );
      return;
    }

    const referenceDate = new Date("0000-06-01");
    const selectedDateObject = new Date(selectedDate);

    if (selectedDateObject < referenceDate) {
      console.log("Selected date is less than 0000-06-01. Not updating state.");
      return;
    }

    setCustomDataFrom(selectedDate);

    // setDateFrom(selectedDate);
  };

  const handleDateChangeTo = (e) => {
    const selectedDate = e.target.value;
    const [year, month, day] = selectedDate.split("-");

    if (year.startsWith("0") || month === "00" || day === "00") {
      console.log(
        "Invalid date: year starts with 0 or month/day is 00. Not updating state.",
      );
      return;
    }

    const referenceDate = new Date("0000-06-01");
    const selectedDateObject = new Date(selectedDate);

    if (selectedDateObject < referenceDate) {
      console.log("Selected date is less than 0000-06-01. Not updating state.");
      return;
    }
    setCustomDataTo(selectedDate);

    // setDateTo(selectedDate);
  };

  const [zoomLevel, setZoomLevel] = useState(
    (window.devicePixelRatio * 100).toFixed(2),
  );

  const checkZoom = () => {
    const currentZoomLevel = (window.devicePixelRatio * 100).toFixed(1);

    if (currentZoomLevel !== zoomLevel) {
      setZoomLevel(currentZoomLevel);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkZoom);
    checkZoom();
    return () => {
      window.removeEventListener("resize", checkZoom);
    };
  }, [zoomLevel]);

  const [timeFrom, setTimeFrom] = useState("00:01");
  const [timeTo, setTimeTo] = useState("23:59");

  const [benchmarkDateFrom, setBenchmarkDateFrom] = useState(
    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
  );
  const [benchmarkDateTo, setBenchmarkDateTo] = useState(
    new Date().toISOString().slice(0, 10),
  );

  const [benchmarkTimeFrom, setBenchmarkTimeFrom] = useState("00:01");
  const [benchmarkTimeTo, setBenchmarkTimeTo] = useState("23:59");

  const [benchmarkAgeFrom, setBenchmarkAgeFrom] = useState(1);
  const [benchmarkAgeTo, setBenchmarkAgeTo] = useState(99);
  const [benchmarkPostCode, setBenchmarkPostCode] = useState("");
  const [benchmarkRadius, setBenchmarkRadius] =
    useState<{
      value: string;
      label: string;
    } | null>(null);
  const [benchmarkRadiusTypes, setBenchmarkRadiusTypes] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedBenchmarkPlayer, setSelectedBenchmarkPlayer] = useState();

  const [benchmarkPosition, setBenchmarkPosition] =
    useState<{
      value: string;
      label: string;
    } | null>(null);
  const [benchmarkPositionTypes, setBenchmarkPositionTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [benchmarkGroup, setBenchmarkGroup] =
    useState<{
      value: string;
      label: string;
    } | null>(null);
  const [benchmarkGroupTypes, setBenchmarkGroupTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const selectedBenchmarkPlayerOnChange = (e) => {
    setSelectedBenchmarkPlayer(e.target.value);
  };

  const benchmarkPositionOnChange = (e) => {
    setBenchmarkPosition(e.target.value);
  };

  const benchmarkGroupOnChange = (e) => {
    setBenchmarkGroup(+e.target.value);
  };

  const benchmarkDateFromOnChange = (e) => {
    setBenchmarkDateFrom(e.target.value);
  };

  const benchmarkDateToOnChange = (e) => {
    setBenchmarkDateTo(e.target.value);
  };

  const benchmarkAgeFromOnChange = (e) => {
    setBenchmarkAgeFrom(+e.target.value);
  };

  const benchmarkAgeToOnChange = (e) => {
    setBenchmarkAgeTo(+e.target.value);
  };

  const benchmarkRadiusOnChange = (e) => {
    setBenchmarkRadius(+e.target.value);
  };

  const benchmarkPostCodeOnChange = (e) => {
    setBenchmarkPostCode(e.target.value);
  };

  const [labels, setLabels] = useState<any>();

  const convertUTCdateToLocal = (date: string) => {
    const utcDate = new Date(date);

    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000,
    );

    return localDate.toISOString();
  };

  const convertUTCdateToLocalTime = (date: string) => {
    const utcDate = new Date(date);

    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
    );

    if (typeof localDate === "string") {
      const date = new Date(localDate);
      if (!isNaN(date.getTime())) {
        return date.toISOString();
      }
    } else {
      return undefined;
    }
  };

  const arrowLeftFn = () => {
    setUpdateDays(false);

    if (periodType?.value === "week") {
      setDateFrom(
        new Date(new Date(dateFrom).getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      );
      setDateTo(
        new Date(new Date(dateTo).getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      );
    }

    if (periodType?.value === "month") {
      setDateFrom(
        new Date(new Date(dateFrom).getTime() - 28 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      );
      setDateTo(
        new Date(new Date(dateTo).getTime() - 28 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      );
    }

    if (periodType?.value === "year") {
      setDateFrom(
        new Date(new Date(dateFrom).getTime() - 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      );
      setDateTo(
        new Date(new Date(dateTo).getTime() - 365 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      );
    }

    if (periodType?.value === "custom") {
      setDateFrom(
        new Date(
          new Date(dateFrom).getTime() - daysBetweenDates * 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .slice(0, 10),
      );
      setDateTo(
        new Date(
          new Date(dateTo).getTime() - daysBetweenDates * 24 * 60 * 60 * 1000,
        )
          .toISOString()
          .slice(0, 10),
      );
    }
  };
  // max={new Date().toISOString().slice(0, 10)}
  const arrowRightFn = () => {
    setUpdateDays(false);

    if (periodType?.value === "week") {
      if (
        new Date(dateTo).getTime() + 7 * 24 * 60 * 60 * 1000 >=
        new Date().getTime()
      ) {
        setDateFrom(
          new Date(new Date(dateFrom).getTime()).toISOString().slice(0, 10),
        );

        setDateTo(new Date().toISOString().slice(0, 10));
      } else {
        setDateFrom(
          new Date(new Date(dateFrom).getTime() + 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );

        setDateTo(
          new Date(new Date(dateTo).getTime() + 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
      }
    }

    if (periodType?.value === "month") {
      if (
        new Date(dateTo).getTime() + 28 * 24 * 60 * 60 * 1000 >=
        new Date().getTime()
      ) {
        setDateFrom(
          new Date(new Date(dateFrom).getTime()).toISOString().slice(0, 10),
        );

        setDateTo(new Date().toISOString().slice(0, 10));
      } else {
        setDateFrom(
          new Date(new Date(dateFrom).getTime() + 28 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );

        setDateTo(
          new Date(new Date(dateTo).getTime() + 28 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
      }
    }

    if (periodType?.value === "year") {
      if (
        new Date(dateTo).getTime() + 365 * 24 * 60 * 60 * 1000 >=
        new Date().getTime()
      ) {
        setDateFrom(
          new Date(new Date(dateFrom).getTime()).toISOString().slice(0, 10),
        );

        setDateTo(new Date().toISOString().slice(0, 10));
      } else {
        setDateFrom(
          new Date(new Date(dateFrom).getTime() + 365 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );

        setDateTo(
          new Date(new Date(dateTo).getTime() + 365 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
      }
    }

    if (periodType?.value === "custom") {
      if (
        new Date(dateTo).getTime() + daysBetweenDates * 24 * 60 * 60 * 1000 >=
        new Date().getTime()
      ) {
        setDateFrom(
          new Date(new Date(dateFrom).getTime()).toISOString().slice(0, 10),
        );

        setDateTo(new Date().toISOString().slice(0, 10));
      } else {
        setDateFrom(
          new Date(
            new Date(dateFrom).getTime() +
              daysBetweenDates * 24 * 60 * 60 * 1000,
          )
            .toISOString()
            .slice(0, 10),
        );

        setDateTo(
          new Date(
            new Date(dateTo).getTime() + daysBetweenDates * 24 * 60 * 60 * 1000,
          )
            .toISOString()
            .slice(0, 10),
        );
      }
    }
  };

  ChartJS.register(...registerables);
  // ChartJS.register(moveChart);
  const [statysticsTypes, setStatysticsTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [statysticsType, setStatysticsType] =
    useState<{
      value: string;
      label: string;
    } | null>(null);

  const [medicalCard, setMedicalCard] = useState<any>([]);

  const [deltaSpeedData, setDeltaSpeedData] = useState<any>([]);
  const [deltaMaxSpeedData, setDeltaMaxSpeedData] = useState<any>([]);

  const [deltaDistanceData, setDeltaDistanceData] = useState<any>([]);
  const [deltaHeartRateData, setDeltaHeartRateData] = useState<any>([]);
  const [deltaCaloriesData, setDeltaCaloriesData] = useState<any>([]);
  const [deltaHSRData, setDeltaHSRData] = useState<any>([]);
  const [deltaHmldData, setDeltaHmldData] = useState<any>([]);
  const [intensityData, setIntensityData] = useState<any>([]);
  const [lastMonthIntensityData, setLastMonthIntensityData] = useState<any>([]);
  const [timeInMotionsData, setTimeInMotionsData] = useState<any>([]);
  const [lastMonthTimeInMotionsData, setLastMonthTimeInMotionsData] =
    useState<any>([]);

  const chartLineRef = useRef<ChartJS>(null);
  const chartLine = chartLineRef.current;

  const chartBarRef = useRef<ChartJS>(null);
  // const chartBar = chartBarRef.current;

  useEffect(() => {
    const chart = chartBarRef;
    // console.log("CanvasRenderingContext2D", chart.ctx);
    console.log("HTMLCanvasElement", chart);
  }, [chartBarRef]);

  const getDates = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  };

  const getDateAndTimeInHours = (startDate, endDate) => {
    const array = [];
    const msInHour = 1000 * 60 * 60;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();

    for (let i = startTime + msInHour; i <= endTime + msInHour; i += msInHour) {
      array.push(new Date(i).toISOString());
    }
    return array;
  };

  const getDateWithMonth = (startDate, endDate) => {
    const array = [];
    const msInHour = 1000 * 60 * 60;
    const msInMonth = 28 * 24 * 1000 * 60 * 60;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();

    for (
      let i = startTime + msInHour;
      i <= endTime + msInHour;
      i += msInMonth
    ) {
      array.push(new Date(i).toISOString());
    }
    return array;
  };

  const getDateAndTimeInMins = (startDate, endDate) => {
    const array = [];
    const msInMin = 1000 * 60;
    const msInHour = 1000 * 60 * 60;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();

    for (let i = startTime + msInHour; i <= endTime + msInHour; i += msInMin) {
      array.push(new Date(i).toISOString());
    }
    return array;
  };

  const getLabels = (dateFrom, dateTo, timeFrom, timeTo) => {
    const dates = getDates(new Date(dateFrom), new Date(dateTo));

    const dateFromWithTime = new Date(`${dateFrom}T${timeFrom}:00`);
    const dateToWithTime = new Date(`${dateTo}T${timeTo}:00`);

    const adjustTime = (date) => {
      const adjustedDate = new Date(date);
      adjustedDate.setHours(adjustedDate.getHours() - 1);
      return adjustedDate;
    };

    const datesWithTimeInHours = getDateAndTimeInHours(
      dateFromWithTime,
      dateToWithTime,
    ).map(adjustTime);
    const datesWithTimeInMins = getDateAndTimeInMins(
      dateFromWithTime,
      dateToWithTime,
    ).map(adjustTime);
    const datesWithMonth = getDateWithMonth(
      dateFromWithTime,
      dateToWithTime,
    ).map(adjustTime);

    const array = [];
    if (statysticsType?.value === "medical") {
      dates.forEach(function (date) {
        array.push(
          new Date(date.getTime()).toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
        );
      });
    } else {
      const timeDifference =
        dateToWithTime.getTime() - dateFromWithTime.getTime();

      if (timeDifference <= 180 * 60 * 1000) {
        setInterval(10);
        datesWithTimeInMins.forEach(function (dateWithTime) {
          const date = new Date(dateWithTime).toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          const time = new Date(dateWithTime).toLocaleString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
          });
          array.push(`${date} ${time}`);
        });
      } else if (timeDifference <= 3 * 24 * 60 * 60 * 1000) {
        setInterval(20);
        datesWithTimeInHours.forEach(function (dateWithTime) {
          const date = new Date(dateWithTime).toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
          const time = new Date(dateWithTime).toLocaleString("en-GB", {
            hour: "2-digit",
          });
          array.push(`${date} ${time}:00`);
        });
      } else if (timeDifference >= 87 * 24 * 60 * 60 * 1000) {
        setInterval(201);
        datesWithMonth.forEach(function (dateWithTime) {
          const date = new Date(dateWithTime).toLocaleString("en-GB", {
            year: "numeric",
            month: "2-digit",
          });
          array.push(date);
        });
      } else {
        setInterval(99);
        dates.forEach(function (date) {
          array.push(
            new Date(date.getTime()).toLocaleString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
          );
        });
      }
    }

    return array;
  };

  const defaults = ChartJS.defaults;
  // defaults.plugins.legend.display = false;
  defaults.datasets.bar.hoverBackgroundColor = "rgba(0,145,255,0.5)";

  const [weight, setWeight] = useState([]);

  const [weightAvgData, setWeightAvgData] = useState([]);

  const [height, setHeight] = useState([]);
  const [speed, setSpeed] = useState([]);
  const [distance, setDistance] = useState([]);
  const [pulse, setPulse] = useState([]);
  const [calories, setCalories] = useState([]);
  const [HSR, setHSR] = useState([]);
  const [percentHSR, setPercentHSR] = useState([]);
  const [percentHmld, setPercentHmld] = useState([]);
  const [Hmld, setHmld] = useState([]);
  const [intensity, setIntensity] = useState([]);
  const [lastMonthIntensity, setLastMonthIntensity] = useState([]);
  const [timeInMotions, setTimeInMotions] = useState([]);
  const [lastMonthTimeInMotions, setLastMonthTimeInMotions] = useState([]);

  const [minWeight, setMinWeight] = useState(0);
  const [maxWeight, setMaxWeight] = useState(0);
  const [avgWeight, setAvgWeight] = useState(0);
  const [benchmarkWeight, setBenchmarkWeight] = useState(0);

  const [minHeight, setMinHeight] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [avgHeight, setAvgHeight] = useState(0);
  const [benchmarkHeight, setBenchmarkHeight] = useState(0);

  const [minHSR, setMinHSR] = useState(0);
  const [minPercentHSR, setMinPercentHSR] = useState(0);
  const [maxPercentHSR, setMaxPercentHSR] = useState(0);
  const [maxHSR, setMaxHSR] = useState(0);

  const [avgHSR, setAvgHSR] = useState(0);
  const [benchmarkHSR, setBenchmarkHSR] = useState(0);

  const [minPercentHmld, setMinPercentHmld] = useState(0);
  const [maxPercentHmld, setMaxPercentHmld] = useState(0);
  const [minHmld, setMinHmld] = useState(0);
  const [maxHmld, setMaxHmld] = useState(0);
  const [avgHmld, setAvgHmld] = useState(0);
  const [benchmarkHmld, setBenchmarkHmld] = useState(0);

  const [minIntensity, setMinIntensity] = useState(0);
  const [maxIntensity, setMaxIntensity] = useState(0);
  const [avgIntensity, setAvgIntensity] = useState(0);
  const [benchmarkIntensity, setBenchmarkIntensity] = useState(0);

  const [minSpeed, setMinSpeed] = useState(0);
  const [maxSpeed, setMaxSpeed] = useState(0);
  const [avgSpeed, setAvgSpeed] = useState(0);
  const [benchmarkSpeed, setBenchmarkSpeed] = useState(0);

  const [avgSpeedMax, setAvgSpeedMax] = useState(0);
  const [speedMax, setSpeedMax] = useState(0);
  const [speedCurrentMax, setSpeedCurrentMax] = useState(0);

  const [minDistance, setMinDistance] = useState(0);
  const [maxDistance, setMaxDistance] = useState(0);
  const [avgDistance, setAvgDistance] = useState(0);
  const [benchmarkDistance, setBenchmarkDistance] = useState(0);

  const [minPulse, setMinPulse] = useState(0);
  const [maxPulse, setMaxPulse] = useState(0);
  const [avgPulse, setAvgPulse] = useState(0);
  const [benchmarkPulse, setBenchmarkPulse] = useState(0);

  const [maxPulseData, setMaxPulseData] = useState(0);

  const [minCalories, setMinCalories] = useState(0);
  const [maxCalories, setMaxCalories] = useState(0);
  const [avgCalories, setAvgCalories] = useState(0);
  const [benchmarkCalories, setBenchmarkCalories] = useState(0);

  const [interval, setInterval] = useState(99);

  const [daysBetweenDates, setDaysBetweenDates] = useState(0);
  const [updateDays, setUpdateDays] = useState(true);
  const [allPlayerProfiles, setAllPlayerProfiles] = useState<
    { value: string; label: string }[]
  >([]);

  const [chartTypes, setChartTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [chartType, setChartType] = useState<{
    value: string;
    label: string;
  } | null>({ label: "Liniowy", value: "line" });

  const [periodTypes, setPeriodTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [periodType, setPeriodType] =
    useState<{
      value: string;
      label: string;
    } | null>(null);

  const [benchmarkToTypes, setBenchmarkToTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [benchmarkTo, setBenchmarkTo] = useState<{
    value: string;
    label: string;
  } | null>({ value: "thisPlayer", label: "Do tego profilu zawodnika" });

  const [benchmarkPeriodTypes, setBenchmarkPeriodTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const [benchmarkPeriod, setBenchmarkPeriod] = useState<{
    value: string;
    label: string;
  } | null>({ value: "week", label: "Ostatnie 7 dni" });

  const [trends, setTrends] = useState<{ value: string; label: string }[]>([]);

  const [trend, setTrend] =
    useState<{
      value: string;
      label: string;
    } | null>(null);

  const [comparisonChecked, setComparisonChecked] = useState(false);
  const [trainingsNums, setTrainingsNum] = useState(0);
  const [unitTrend, setUnitTrend] = useState("m");

  const handleComparisonChange = () => {
    setComparisonChecked((prev) => !prev);
  };

  useEffect(() => {
    if (statysticsType?.value === "training") {
      setChartType({ label: "Słupkowy", value: "bar" });
    }
  }, [statysticsType]);

  useEffect(() => {
    if (statysticsType?.value === "medical") {
      setChartType({ label: "Liniowy", value: "line" });
    }
  }, [statysticsType]);

  useEffect(() => {
    setHeight([]);
    setWeight([]);

    if (statysticsType?.value === "medical") {
      playerProfileServices
        .getMedicalCard(currentIdUser)
        .then((playerProfileData) => {
          setMedicalCard(playerProfileData.MedicalCards);
        })
        .catch((errors: any) => {
          showServerErrors(errors);
        });
    }
  }, [dateFrom, dateTo, timeFrom, timeTo, statysticsType, labels]);

  useEffect(() => {
    setLabels(getLabels(dateFrom, dateTo, timeFrom, timeTo));
    getTrainingsData(getLabels(dateFrom, dateTo, timeFrom, timeTo));
  }, [dateFrom, dateTo, timeFrom, timeTo, trend]);

  // useEffect(() => {
  //   playerProfileServices
  //     .getAllPlayerProfiles({
  //       pageNumber: 1,
  //       pageSize: 500,
  //       searchString: "string",
  //     })
  //     .then((playerProfiles) => {
  //       playerProfiles.Items.map((playerProfile) => {
  //         setAllPlayerProfiles((prev) => [
  //           ...prev,
  //           {
  //             value: playerProfile.Id,
  //             label: playerProfile.Name,
  //           },
  //         ]);
  //       });
  //     })
  //     .catch((errors: any) => {
  //       showServerErrors(errors);
  //     });
  // }, [dateFrom, dateTo, timeFrom, timeTo]);

  useEffect(() => {
    setSpeed([]);

    const body = {
      UserId: ownerId,
      ProfileId: id,
      ProfileType: 1,
      RangeFrom: convertUTCdateToLocalTime(`${dateFrom}T${timeFrom}:00.00Z`),
      RangeTo: convertUTCdateToLocalTime(`${dateTo}T${timeTo}:00.00Z`),
      Partition: 0,
    };
  }, [dateFrom, dateTo, interval, timeFrom, timeTo]);

  useEffect(() => {
    setBenchmarkPulse([]);

    const baseUrl = getOtherConnectionUrl("justwin");
    const body = {
      UserId: ownerId,
      ProfileId: id,
      CompareWithProfilId:
        benchmarkTo?.value === "thisPlayer"
          ? id
          : benchmarkTo?.value === "player"
          ? selectedBenchmarkPlayer
            ? selectedBenchmarkPlayer?.value
            : ""
          : "",
      CompareWithProfiles: [],
      ProfileType: 0,
      RangeFrom: convertUTCdateToLocalTime(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00.00Z`,
      ),
      RangeTo: convertUTCdateToLocalTime(
        `${benchmarkDateTo}T${benchmarkTimeTo}:00.00Z`,
      ),
      AgeFrom: benchmarkAgeFrom,
      AgeTo: benchmarkAgeTo,
      PostalCode: benchmarkPostCode,
      Radius: benchmarkRadius ? +benchmarkRadius.value : null,
      Gender: 0,
      HeihgtFrom: 0,
      HeihgtTo: 0,
      WeightFrom: 0,
      WeightTo: 0,
      Players: benchmarkGroup ? +benchmarkGroup.value : null,
      PreferredPosition: benchmarkPosition ? +benchmarkPosition.value : null,
      TaskType: 0,
      ActivityLevel: 0,
      Objective: 0,
      DominantLeg: 1,
      PlayerTypes: 0,
    };

    if (
      (trend?.value === "weight" || trend?.value === "height") &&
      statysticsType.value === "medical"
    ) {
      axios
        .post(
          `${baseUrl}/statisticsdistributor/FilterStatistics/HeartRateStatistic?timeInterval=99`,
          body,
        )
        .then((response) => {
          setBenchmarkPulse(
            response.data.length > 0
              ? response.data[0][0].heartRate.toFixed(2)
              : 0,
          );
        })
        .catch((errors: any) => {
          console.error(errors);
        });
    }
  }, [
    benchmarkDateFrom,
    benchmarkDateTo,
    interval,
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkAgeFrom,
    benchmarkAgeTo,
    benchmarkTo,
    benchmarkPostCode,
    benchmarkRadius,
    benchmarkGroup,
    benchmarkPosition,
    selectedBenchmarkPlayer,
    benchmarkGroup,
  ]);

  useEffect(() => {
    // setBenchmarkDistance([]);

    const body = {
      UserId: ownerId,
      ProfileId: id,
      CompareWithProfilId:
        benchmarkTo?.value === "thisPlayer"
          ? id
          : benchmarkTo?.value === "player"
          ? selectedBenchmarkPlayer
            ? selectedBenchmarkPlayer?.value
            : ""
          : "",
      CompareWithProfiles: [],
      ProfileType: 0,
      RangeFrom: convertUTCdateToLocalTime(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00.00Z`,
      ),
      RangeTo: convertUTCdateToLocalTime(
        `${benchmarkDateTo}T${benchmarkTimeTo}:00.00Z`,
      ),
      AgeFrom: benchmarkAgeFrom,
      AgeTo: benchmarkAgeTo,
      PostalCode: benchmarkPostCode,
      Radius: benchmarkRadius ? +benchmarkRadius.value : null,
      Gender: 0,
      HeihgtFrom: 0,
      HeihgtTo: 0,
      WeightFrom: 0,
      WeightTo: 0,
      Players: benchmarkGroup ? +benchmarkGroup.value : null,
      PreferredPosition: benchmarkPosition ? +benchmarkPosition.value : null,
      TaskType: 0,
      ActivityLevel: 0,
      Objective: 0,
      DominantLeg: 1,
      PlayerTypes: 0,
    };

    // axios
    //   .post(
    //     `https://justwin.pl/statisticsdistributor/FilterStatistics/DistanceStatistic?timeInterval=99`,
    //     body,
    //   )
    //   .then((response) => {
    //     setBenchmarkDistance(
    //       response.data.length > 0
    //         ? response.data[0][0].DistanceInMeters.toFixed(2)
    //         : 0,
    //     );
    //   })
    //   .catch((errors: any) => {
    //     console.error(errors);
    //   });
  }, [
    benchmarkDateFrom,
    benchmarkDateTo,
    interval,
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkAgeFrom,
    benchmarkAgeTo,
    benchmarkTo,
    benchmarkPostCode,
    benchmarkRadius,
    benchmarkGroup,
    benchmarkPosition,
    selectedBenchmarkPlayer,
    benchmarkGroup,
  ]);

  useEffect(() => {
    setBenchmarkSpeed([]);

    const body = {
      UserId: ownerId,
      ProfileId: id,
      CompareWithProfilId:
        benchmarkTo?.value === "thisPlayer"
          ? id
          : benchmarkTo?.value === "player"
          ? selectedBenchmarkPlayer
            ? selectedBenchmarkPlayer?.value
            : ""
          : "",
      CompareWithProfiles: [],
      ProfileType: 0,
      RangeFrom: convertUTCdateToLocalTime(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00.00Z`,
      ),
      RangeTo: convertUTCdateToLocalTime(
        `${benchmarkDateTo}T${benchmarkTimeTo}:00.00Z`,
      ),
      AgeFrom: benchmarkAgeFrom,
      AgeTo: benchmarkAgeTo,
      PostalCode: benchmarkPostCode,
      Radius: benchmarkRadius ? +benchmarkRadius.value : null,
      Gender: 0,
      HeihgtFrom: 0,
      HeihgtTo: 0,
      WeightFrom: 0,
      WeightTo: 0,
      Players: benchmarkGroup ? +benchmarkGroup.value : null,
      PreferredPosition: benchmarkPosition ? +benchmarkPosition.value : null,
      TaskType: 0,
      ActivityLevel: 0,
      Objective: 0,
      DominantLeg: 1,
      PlayerTypes: 0,
    };

    // axios
    //   .post(
    //     `https://justwin.pl/statisticsdistributor/FilterStatistics/SpeedStatistic?timeInterval=99`,
    //     body,
    //   )
    //   .then((response) => {
    //     setBenchmarkSpeed(
    //       response.data.length > 0 ? response.data[0][0].speed.toFixed(2) : 0,
    //     );
    //   })
    //   .catch((errors: any) => {
    //     console.error(errors);
    //   });
  }, [
    benchmarkDateFrom,
    benchmarkDateTo,
    interval,
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkAgeFrom,
    benchmarkAgeTo,
    benchmarkTo,
    benchmarkPostCode,
    benchmarkRadius,
    benchmarkGroup,
    benchmarkPosition,
    selectedBenchmarkPlayer,
    benchmarkGroup,
  ]);

  useEffect(() => {
    setBenchmarkHmld([]);

    const body = {
      UserId: ownerId,
      ProfileId: id,
      CompareWithProfilId:
        benchmarkTo?.value === "thisPlayer"
          ? id
          : benchmarkTo?.value === "player"
          ? selectedBenchmarkPlayer
            ? selectedBenchmarkPlayer?.value
            : ""
          : "",
      CompareWithProfiles: [],
      ProfileType: 0,
      RangeFrom: convertUTCdateToLocalTime(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00.00Z`,
      ),
      RangeTo: convertUTCdateToLocalTime(
        `${benchmarkDateTo}T${benchmarkTimeTo}:00.00Z`,
      ),
      AgeFrom: benchmarkAgeFrom,
      AgeTo: benchmarkAgeTo,
      PostalCode: benchmarkPostCode,
      Radius: benchmarkRadius ? +benchmarkRadius.value : null,
      Gender: 0,
      HeihgtFrom: 0,
      HeihgtTo: 0,
      WeightFrom: 0,
      WeightTo: 0,
      Players: benchmarkGroup ? +benchmarkGroup.value : null,
      PreferredPosition: benchmarkPosition ? +benchmarkPosition.value : null,
      TaskType: 0,
      ActivityLevel: 0,
      Objective: 0,
      DominantLeg: 1,
      PlayerTypes: 0,
    };

    // axios
    //   .post(
    //     `https://justwin.pl/statisticsdistributor/FilterStatistics/HmldDistanceStatistic?timeInterval=99`,
    //     body,
    //   )
    //   .then((response) => {
    //     setBenchmarkHmld(
    //       response.data.length > 0
    //         ? response.data[0][0].DistanceInMeters.toFixed(2)
    //         : 0,
    //     );
    //   })
    //   .catch((errors: any) => {
    //     console.error(errors);
    //   });
  }, [
    benchmarkDateFrom,
    benchmarkDateTo,
    interval,
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkAgeFrom,
    benchmarkAgeTo,
    benchmarkTo,
    benchmarkPostCode,
    benchmarkRadius,
    benchmarkGroup,
    benchmarkPosition,
    selectedBenchmarkPlayer,
    benchmarkGroup,
  ]);

  useEffect(() => {
    setBenchmarkHSR([]);

    const body = {
      UserId: ownerId,
      ProfileId: id,
      CompareWithProfilId:
        benchmarkTo?.value === "thisPlayer"
          ? id
          : benchmarkTo?.value === "player"
          ? selectedBenchmarkPlayer
            ? selectedBenchmarkPlayer?.value
            : ""
          : "",
      CompareWithProfiles: [],
      ProfileType: 0,
      RangeFrom: convertUTCdateToLocalTime(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00.00Z`,
      ),
      RangeTo: convertUTCdateToLocalTime(
        `${benchmarkDateTo}T${benchmarkTimeTo}:00.00Z`,
      ),
      AgeFrom: benchmarkAgeFrom,
      AgeTo: benchmarkAgeTo,
      PostalCode: benchmarkPostCode,
      Radius: benchmarkRadius ? +benchmarkRadius.value : null,
      Gender: 0,
      HeihgtFrom: 0,
      HeihgtTo: 0,
      WeightFrom: 0,
      WeightTo: 0,
      Players: benchmarkGroup ? +benchmarkGroup.value : null,
      PreferredPosition: benchmarkPosition ? +benchmarkPosition.value : null,
      TaskType: 0,
      ActivityLevel: 0,
      Objective: 0,
      DominantLeg: 1,
      PlayerTypes: 0,
    };

    // axios
    //   .post(
    //     `https://justwin.pl/statisticsdistributor/FilterStatistics/HsrDistanceStatistic?timeInterval=99`,
    //     body,
    //   )
    //   .then((response) => {
    //     setBenchmarkHSR(
    //       response.data.length > 0
    //         ? response.data[0][0].DistanceInMeters.toFixed(2)
    //         : 0,
    //     );
    //   })
    //   .catch((errors: any) => {
    //     console.error(errors);
    //   });
  }, [
    benchmarkDateFrom,
    benchmarkDateTo,
    interval,
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkAgeFrom,
    benchmarkAgeTo,
    benchmarkTo,
    benchmarkPostCode,
    benchmarkRadius,
    benchmarkGroup,
    benchmarkPosition,
    selectedBenchmarkPlayer,
    benchmarkGroup,
  ]);

  useEffect(() => {
    setBenchmarkCalories([]);

    const body = {
      UserId: ownerId,
      ProfileId: id,
      CompareWithProfilId:
        benchmarkTo?.value === "thisPlayer"
          ? id
          : benchmarkTo?.value === "player"
          ? selectedBenchmarkPlayer
            ? selectedBenchmarkPlayer?.value
            : ""
          : "",
      CompareWithProfiles: [],
      ProfileType: 0,
      RangeFrom: convertUTCdateToLocalTime(
        `${benchmarkDateFrom}T${benchmarkTimeFrom}:00.00Z`,
      ),
      RangeTo: convertUTCdateToLocalTime(
        `${benchmarkDateTo}T${benchmarkTimeTo}:00.00Z`,
      ),
      AgeFrom: benchmarkAgeFrom,
      AgeTo: benchmarkAgeTo,
      PostalCode: benchmarkPostCode,
      Radius: benchmarkRadius ? +benchmarkRadius.value : null,
      Gender: 0,
      HeihgtFrom: 0,
      HeihgtTo: 0,
      WeightFrom: 0,
      WeightTo: 0,
      Players: benchmarkGroup ? +benchmarkGroup.value : null,
      PreferredPosition: benchmarkPosition ? +benchmarkPosition.value : null,
      TaskType: 0,
      ActivityLevel: 0,
      Objective: 0,
      DominantLeg: 1,
      PlayerTypes: 0,
    };

    // axios
    //   .post(
    //     `https://justwin.pl/statisticsdistributor/FilterStatistics/CaloriesStatistic?timeInterval=99`,
    //     body,
    //   )
    //   .then((response) => {
    //     setBenchmarkCalories(
    //       response.data.length > 0
    //         ? response.data[0][0].calories.toFixed(2)
    //         : 0,
    //     );
    //   })
    //   .catch((errors: any) => {
    //     console.error(errors);
    //   });
  }, [
    benchmarkDateFrom,
    benchmarkDateTo,
    interval,
    benchmarkTimeFrom,
    benchmarkTimeTo,
    benchmarkAgeFrom,
    benchmarkAgeTo,
    benchmarkTo,
    benchmarkPostCode,
    benchmarkRadius,
    benchmarkGroup,
    benchmarkPosition,
    selectedBenchmarkPlayer,
    benchmarkGroup,
  ]);

  useEffect(() => {
    // setCalories([]);
    // const body = {
    //   UserId: ownerId,
    //   ProfileId: id,
    //   ProfileType: 1,
    //   RangeFrom: convertUTCdateToLocalTime(`${dateFrom}T${timeFrom}:00.00Z`),
    //   RangeTo: convertUTCdateToLocalTime(`${dateTo}T${timeTo}:00.00Z`),
    //   Partition: 0,
    // };
    // axios
    //   .post(
    //     `https://justwin.pl/statisticsdistributor/Statistics/CaloriesStatistic?timeInterval=${interval}`,
    //     body,
    //   )
    //   .then((response) => {
    //     setDeltaCaloriesData(response.data);
    //   })
    //   .catch((errors: any) => {
    //     showServerErrors(errors);
    //   });
  }, [dateFrom, dateTo, interval, timeFrom, timeTo]);

  // useEffect(() => {
  //   setHSR([]);

  //   const body = {
  //     UserId: ownerId,
  //     ProfileId: id,
  //     ProfileType: 1,
  //     RangeFrom: convertUTCdateToLocalTime(`${dateFrom}T${timeFrom}:00.00Z`),
  //     RangeTo: convertUTCdateToLocalTime(`${dateTo}T${timeTo}:00.00Z`),
  //     Partition: 0,
  //   };

  //   axios
  //     .post(
  //       `https://justwin.pl/statisticsdistributor/Statistics/DistanceTraveledHsr?timeInterval=${interval}`,
  //       body,
  //     )
  //     .then((response) => {
  //       setDeltaHSRData(response.data);
  //     })
  //     .catch((errors: any) => {
  //       showServerErrors(errors);
  //     });
  // }, [dateFrom, dateTo, interval, timeFrom, timeTo]);

  // useEffect(() => {
  //   setHmld([]);

  //   const body = {
  //     UserId: ownerId,
  //     ProfileId: id,
  //     ProfileType: 1,
  //     RangeFrom: convertUTCdateToLocalTime(`${dateFrom}T${timeFrom}:00.00Z`),
  //     RangeTo: convertUTCdateToLocalTime(`${dateTo}T${timeTo}:00.00Z`),
  //     Partition: 0,
  //   };

  //   axios
  //     .post(
  //       `https://justwin.pl/statisticsdistributor/Statistics/DistanceTraveledHmld?timeInterval=${interval}`,
  //       body,
  //     )
  //     .then((response) => {
  //       setDeltaHmldData(response.data);
  //     })
  //     .catch((errors: any) => {
  //       showServerErrors(errors);
  //     });
  // }, [dateFrom, dateTo, interval, timeFrom, timeTo]);

  function lastDayOfMonth(Year, Month) {
    return new Date(new Date(Year, Month, 1) - 1).getUTCDate();
  }

  // useEffect(() => {
  //   setIntensity([]);
  //   const currentDate = new Date().toISOString();
  //   const currentYear = new Date().getFullYear();
  //   const lastMonth = new Date();
  //   lastMonth.setMonth(lastMonth.getMonth() - 1);
  //   const lastMonthDate = lastMonth.toJSON();

  //   const body = {
  //     UserId: ownerId,
  //     ProfileId: id,
  //     ProfileType: 1,
  //     RangeFrom: `${currentDate.slice(0, 4)}-${currentDate.slice(
  //       5,
  //       7,
  //     )}-01T00:00:00.00Z`,
  //     RangeTo: `${currentYear}-${currentDate.slice(5, 7)}-${lastDayOfMonth(
  //       currentYear,
  //       currentDate.slice(5, 7),
  //     )}T23:59:59.00Z`,
  //     Partition: 0,
  //   };

  //   const lastMonthBody = {
  //     UserId: ownerId,
  //     ProfileId: id,
  //     ProfileType: 1,
  //     RangeFrom: `${lastMonthDate.slice(0, 7)}-01T00:00:00.00Z`,
  //     RangeTo: `${lastMonthDate.slice(0, 7)}-${lastDayOfMonth(
  //       lastMonthDate.slice(0, 4),
  //       lastMonthDate.slice(5, 7),
  //     )}T23:59:59.00Z`,
  //     Partition: 0,
  //   };

  //   axios
  //     .post(
  //       `https://justwin.pl/statisticsdistributor/Statistics/IntensityStatistic?timeInterval=101`,
  //       body,
  //     )
  //     .then((response) => {
  //      setIntensityData(response.data);
  //     })
  //     .catch((errors: any) => {
  //       showServerErrors(errors);
  //     });

  //   axios
  //     .post(
  //       `https://justwin.pl/statisticsdistributor/Statistics/IntensityStatistic?timeInterval=101`,
  //       lastMonthBody,
  //     )
  //     .then((response) => {
  //      setLastMonthIntensityData(response.data);
  //     })
  //     .catch((errors: any) => {
  //      showServerErrors(errors);
  //     });
  // }, [dateFrom, dateTo, interval, timeFrom, timeTo]);

  const filterValues = (arr: (number | null)[]) =>
    arr.map((distance) => distance.Value);
  const maxCumulatedValue = (arr: number[]) => Math.max(...filterValues(arr));

  const avgCumulatedValue = (arr: (number | null)[]) => {
    const nonNullValues = arr.filter((value) => value !== null);
    return nonNullValues.length > 0
      ? nonNullValues.reduce((a, b) => a + b, 0) / nonNullValues.length
      : 0;
  };

  const getTrainingsNums = (arr) =>
    arr.filter((elem) => {
      const trainingsValues = elem.Value !== null ? elem.Value : "";

      return trainingsValues;
    });

  const StatisticType = {
    AvarageCalories: 5,
    AvarageDistance: 10,
    AvarageHeartRate: 15,
    AvarageSpeed: 20,
    AvarageSprintSpeed: 25,
    Balance: 30,
    Calories: 35,
    CountAcceleration: 40,
    CountSprint: 45,
    distance: 50,
    pulse: 55,
    HMLD: 60,
    HSR: 65,
    MaxAcceleration: 70,
    MaxCalories: 75,
    MaxDeceleration: 80,
    MaxDistance: 85,
    MaxHeartRate: 90,
    MaxHmld: 95,
    MaxHsr: 100,
    speed: 105,
    maxSpeed: 105,
    sprinty: 110,
    calories: 115,
    SumCalories: 120,
    SumDistance: 125,
    SumHmldDistance: 130,
    SumHsrDistance: 135,
    HsrDistancePercentage: 140,
    HmldDistancePercentage: 150,
  };

  // function getDateRangeCode(rangeFrom = dateFrom, rangeTo = dateTo) {
  //   const startDate = new Date(rangeFrom);
  //   const endDate = new Date(rangeTo);

  //   const differenceInDays = Math.ceil((endDate - startDate) / (1000 * 3600 * 24)) + 1;

  //     if (differenceInDays!== isNaN ) {
  //          if (differenceInDays === 7) {
  //           return 99
  //          } else if (differenceInDays <=31 && differenceInDays !== 7) {
  //           return 99
  //          } else if(differenceInDays >= 365) {
  //           return 201
  //          }
  //     }
  // }

  const initialBodyData: bodyData = {
    UserId: currentIdUser,
    ProfileId: currentIdUser,
    ProfileType: 1,
    TimeInterval: interval,
    RangeFrom: convertUTCdateToLocalTime(`${dateFrom}T${timeFrom}:00.00Z`),
    RangeTo: convertUTCdateToLocalTime(`${dateTo}T${timeTo}:00.00Z`),
    Partition: 0,
    Statistics: [50],
  };

  const [body, setBody] = useState<bodyData>(initialBodyData);

  const displayCurrentTrend = (elem: string) => {
    if (StatisticType.hasOwnProperty(elem)) {
      return StatisticType[elem];
    } else {
      return null;
    }
  };

  const prevBodyRef = useRef(body);

  const updateBody = useCallback(() => {
    if (trend?.value && statysticsType.value === "training") {
      const baseUrl = getOtherConnectionUrl("justwin");

      let trendValue = displayCurrentTrend(trend.value);

      if (trend.value === "HSR" && unitTrend === "m") {
        trendValue = displayCurrentTrend(trend.value);
      } else if (trend.value === "HSR" && unitTrend === "%") {
        trendValue = 140;
      } else if (trend.value === "HMLD" && unitTrend === "m") {
        trendValue = displayCurrentTrend(trend.value);
      } else if (trend.value === "HMLD" && unitTrend === "%") {
        trendValue = 150;
      }

      if (trendValue !== null) {
        const updatedBody: bodyData = {
          ...body,
          Statistics: [trendValue],
          RangeFrom: `${dateFrom}T${timeFrom}:00.00Z`,
          RangeTo: `${dateTo}T${timeTo}:00.00Z`,
          Partition: 0,
          TimeInterval: interval,
          AggregateType:
            trend?.value === "distance"
              ? 1
              : trend?.value === "speed"
              ? 0
              : trend?.value === "maxSpeed"
              ? 2
              : 0,
          UserId: currentIdUser,
          ProfileId: currentIdUser,
          ProfileType: 1,
        };

        if (
          JSON.stringify(prevBodyRef.current) !== JSON.stringify(updatedBody) &&
          updatedBody.TimeInterval
        ) {
          setBody(updatedBody);
          prevBodyRef.current = updatedBody;

          axios
            .post(
              `${baseUrl}/statisticsprocessor/GetAggregatedStatistic`,
              updatedBody,
            )
            .then((response) => {
              if (trend?.value === "pulse") {
                setTrainingsNum(0);
                setDeltaHeartRateData(response.data.HeartRate);
              }
              if (trend?.value === "distance") {
                setTrainingsNum(0);
                setDeltaDistanceData(response.data.Distance);
              }
              if (trend?.value === "HSR" && unitTrend === "m") {
                setTrainingsNum(0);
                setDeltaHSRData(response.data.HsrDistance);
              }

              if (trend?.value === "HSR" && unitTrend === "%") {
                setTrainingsNum(0);
                setDeltaHSRData(response.data.HsrDistancePercentage);
              }

              if (trend?.value === "HMLD" && unitTrend === "m") {
                setTrainingsNum(0);
                setDeltaHmldData(response.data.HmldDistance);
              }

              if (trend?.value === "HMLD" && unitTrend === "%") {
                setTrainingsNum(0);
                setDeltaHmldData(response.data.HmldDistancePercentage);
              }

              if (trend?.value === "speed") {
                setTrainingsNum(0);
                setDeltaSpeedData(response.data.MaxSpeed);
              }
              if (trend?.value === "maxSpeed") {
                setTrainingsNum(0);
                setDeltaMaxSpeedData(response.data.MaxSpeed);
              }
              if (trend?.value === "timeInMotions") {
                console.log(response.data, "timeInMotions");
              }
              if (trend?.value === "sprinty") {
                console.log(response.data, "sprinty tego jeszcze nie ma");
              }
              if (trend?.value === "intensity") {
                console.log(response.data, "intensity tego jeszcze nie ma");
              }
            })
            .catch((errors) => {
              showServerErrors(errors);
            });
        }
      }
    }
  }, [
    body,
    trend,
    statysticsType,
    dateFrom,
    dateTo,
    labels,
    interval,
    unitTrend,
  ]);

  useEffect(() => {
    updateBody();
  }, [trend, labels, updateBody]);

  const handleCustomData = () => {
    setDateFrom(customDataFrom);
    setDateTo(customDataTo);
  };

  const getAllChartTypes = async () => {
    try {
      setChartTypes([
        {
          label: "Liniowy",
          value: "line",
        },
        {
          label: "Słupkowy",
          value: "bar",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllChartTypes();
  }, []);

  useEffect(() => {
    setWeight([]);
    setHeight([]);

    if (statysticsType?.value === "medical" && medicalCard.length > 0) {
      const labelsSet = new Set(labels);
      const startDate = new Date(dateFrom);
      const endDate = new Date(dateTo);

      const getDataSet = (param: string) => {
        return medicalCard.reduce((acc, curr) => {
          const updatedAt = new Date(curr.UpdatedAt);
          const dateStr = updatedAt
            .toISOString()
            .slice(0, 10)
            .split("-")
            .reverse()
            .join("/");

          if (
            labelsSet.has(dateStr) &&
            updatedAt >= startDate &&
            updatedAt <= endDate
          ) {
            acc.push({ x: dateStr, y: curr[param] });
          }
          return acc;
        }, []);
      };

      const weightDataSet = getDataSet("Weight");
      const heightDataSet = getDataSet("Height");

      const calcMinMax = (
        dataSet: [{ x: string; y: number }],
        minSetter: number,
        maxSetter: number,
      ) => {
        if (dataSet.length > 0) {
          const values = dataSet.map(({ y }) => y);
          const minValue = Math.min(...values);
          const maxValue = Math.max(...values);

          minSetter(minValue - 10 < 0 ? 0 : minValue - 10);
          maxSetter(maxValue + 10);
        } else {
          minSetter(0);
          maxSetter(0);
        }
      };

      calcMinMax(weightDataSet, setMinWeight, setMaxWeight);
      calcMinMax(heightDataSet, setMinHeight, setMaxHeight);

      const calculateAverage = (dataSet: [{ x: string; y: number }]) => {
        if (dataSet.length > 0) {
          const sum = dataSet.reduce((acc, curr) => acc + curr.y, 0);
          return sum / dataSet.length;
        }
        return 0;
      };

      setAvgWeight(calculateAverage(weightDataSet));
      setAvgHeight(calculateAverage(heightDataSet));

      setWeight(weightDataSet);
      setHeight(heightDataSet);
    }
  }, [
    statysticsType,
    medicalCard,
    labels,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    deltaSpeedData /* deltaHeartRateData, deltaDistanceData, deltaHSRData */,
  ]);

  useEffect(() => {
    setSpeed([]);
    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "speed" &&
      deltaSpeedData.length > 0
    ) {
      setTrainingsNum(0);
      const countTrainings = getTrainingsNums(deltaSpeedData).length;

      setTrainingsNum(countTrainings);
      setAvgSpeed(avgCumulatedValue(filterValues(deltaSpeedData)));
      setMaxSpeed(maxCumulatedValue(deltaSpeedData) + 10);
      setSpeed(filterValues(deltaSpeedData));
    }
  }, [
    statysticsType,
    deltaSpeedData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    // setSpeedCurrentMax([]);

    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "maxSpeed" &&
      deltaMaxSpeedData.length > 0
    ) {
      setTrainingsNum(0);
      const countTrainings = getTrainingsNums(deltaMaxSpeedData).length;

      setAvgSpeedMax(avgCumulatedValue(filterValues(deltaMaxSpeedData)));
      setSpeedMax(maxCumulatedValue(deltaMaxSpeedData) + 10);
      setSpeedCurrentMax(filterValues(deltaMaxSpeedData));
      setTrainingsNum(countTrainings);
    }
  }, [
    statysticsType,
    deltaMaxSpeedData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    setHSR([]);
    setPercentHSR([]);
    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "HSR" &&
      deltaHSRData.length > 0
    ) {
      setTrainingsNum(0);
      const countTrainings = getTrainingsNums(deltaHSRData).length;
      setTrainingsNum(countTrainings);
      setAvgHSR(avgCumulatedValue(filterValues(deltaHSRData)));
      setMaxHSR(maxCumulatedValue(deltaHSRData) + 10);
      setHSR(filterValues(deltaHSRData));
    }
  }, [statysticsType, deltaHSRData, trend, dateFrom, dateTo, timeFrom, timeTo]);

  useEffect(() => {
    setHmld([]);
    setPercentHmld([]);

    console.log(deltaHmldData, "deltaHmldData");

    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "HMLD" &&
      deltaHmldData.length > 0
    ) {
      setTrainingsNum(0);

      const countTrainings = getTrainingsNums(deltaHmldData).length;

      setTrainingsNum(countTrainings);

      setAvgHmld(avgCumulatedValue(filterValues(deltaHmldData)));
      setMaxHmld(maxCumulatedValue(deltaHmldData) + 10);
      setHmld(filterValues(deltaHmldData));
    }
  }, [
    statysticsType,
    deltaHmldData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    setDistance([]);

    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "distance" &&
      deltaDistanceData.length > 0
    ) {
      // const getDataSet = (param) => {
      //   return deltaDistanceData.reduce((acc, curr) => {
      //     labels.forEach((label) => {
      //       if (
      //         interval === 101 &&
      //         convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 7)
      //           .split("-")
      //           .reverse()
      //           .join("-") === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       } else if (
      //         interval === 99 &&
      //         convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 10)
      //           .split("-")
      //           .reverse()
      //           .join("-") === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       } else if (
      //         interval === 20 &&
      //         `${convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 10)
      //           .split("-")
      //           .reverse()
      //           .join("-")} ${convertUTCdateToLocal(curr.RecordDate).slice(
      //           11,
      //           13,
      //         )}:00` === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       } else if (
      //         interval === 10 &&
      //         `${convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 10)
      //           .split("-")
      //           .reverse()
      //           .join("-")} ${convertUTCdateToLocal(curr.RecordDate).slice(
      //           11,
      //           16,
      //         )}` === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       }
      //     });

      //     return acc;
      //   }, []);
      // };

      // const distanceDataSet = getDataSet("DistanceInMeters");

      // if (Math.min(...distanceDataSet.map(({ y }) => y)) - 10 < 0) {
      //   setMinDistance(0);
      // } else {
      //   setMinDistance(Math.min(...distanceDataSet.map(({ y }) => y)) - 10);
      // }

      // const getAvgDistance = () => {
      //   let i = 0;

      //   const reducedDataSet = distanceDataSet.reduce((acc, curr) => {
      //     acc += curr.y;
      //     i++;

      //     return acc;
      //   }, 0);
      //   return reducedDataSet / i;
      // };

      //////////////////////////////////////////////////////////////////////////

      /*  const filterValues = deltaDistanceData.filter(distance => distance.Value !==null).map(el => el.Value.toFixed()).map(Number); */

      // const maxDistance = Math.max(...filterValues(deltaDistanceData))
      // const avgDistances = filterValues(deltaDistanceData).length > 0 ? filterValues(deltaDistanceData).reduce((a, b) => a + b, 0) / filterValues(deltaDistanceData).length : 0;
      //  setAvgDistance(getAvgDistance);
      //  setMaxDistance(Math.max(...distanceDataSet.map(({ y }) => y)) + 10);
      //  setDistance(distanceDataSet);

      setTrainingsNum(0);

      const countTrainings = getTrainingsNums(deltaDistanceData).length;

      setTrainingsNum(countTrainings);
      setDistance(filterValues(deltaDistanceData));
      setAvgDistance(avgCumulatedValue(filterValues(deltaDistanceData)));
      setMaxDistance(maxCumulatedValue(deltaDistanceData) + 10);
    }
  }, [
    statysticsType,
    deltaDistanceData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    setIntensity([]);
    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "intensity"
    ) {
      if (intensityData.length > 0) {
        setIntensity(roundToTwo(intensityData[0][0].percent));
      } else {
        setIntensity(0);
      }
      if (lastMonthIntensityData.length > 0) {
        setLastMonthIntensity(roundToTwo(lastMonthIntensityData[0][0].percent));
      } else {
        setLastMonthIntensity(0);
      }
    }
  }, [
    statysticsType,
    intensityData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    setTimeInMotions([]);
    setLastMonthTimeInMotions([]);
    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "timeInMotions"
    ) {
      if (timeInMotionsData) {
        setTimeInMotions(roundToTwo(timeInMotionsData.percent));
      } else {
        setTimeInMotions(0);
      }
      if (
        !lastMonthTimeInMotionsData ||
        lastMonthTimeInMotionsData.length === 0
      ) {
        setLastMonthTimeInMotions(0);
      } else {
        setLastMonthTimeInMotions(
          roundToTwo(lastMonthTimeInMotionsData.percent),
        );
      }
    }
  }, [
    statysticsType,
    timeInMotionsData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    console.log("test", benchmarkTo);
  }, [benchmarkTo]);

  useEffect(() => {
    setCalories([]);
    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "calories" &&
      deltaCaloriesData.length > 0
    ) {
      const getDataSet = (param) => {
        return deltaCaloriesData.reduce((acc, curr) => {
          labels.forEach((label) => {
            if (
              interval === 101 &&
              convertUTCdateToLocal(curr.recordDate)
                .slice(0, 7)
                .split("-")
                .reverse()
                .join("-") === label
            ) {
              acc.push({
                x: label,
                y: curr[param],
              });
            } else if (
              interval === 99 &&
              convertUTCdateToLocal(curr.recordDate)
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-") === label
            ) {
              acc.push({
                x: label,
                y: curr[param],
              });
            } else if (
              interval === 20 &&
              `${convertUTCdateToLocal(curr.recordDate)
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-")} ${convertUTCdateToLocal(curr.recordDate).slice(
                11,
                13,
              )}:00` === label
            ) {
              acc.push({
                x: label,
                y: curr[param],
              });
            } else if (
              interval === 10 &&
              `${convertUTCdateToLocal(curr.recordDate)
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-")} ${convertUTCdateToLocal(curr.recordDate).slice(
                11,
                16,
              )}` === label
            ) {
              acc.push({
                x: label,
                y: curr[param],
              });
            }
          });

          return acc;
        }, []);
      };

      const caloriesDataSet = getDataSet("calories");

      if (Math.min(...caloriesDataSet.map(({ y }) => y)) - 10 < 0) {
        setMinCalories(0);
      } else {
        setMinCalories(Math.min(...caloriesDataSet.map(({ y }) => y)) - 10);
      }

      const getAvgCalories = () => {
        let i = 0;

        const reducedDataSet = caloriesDataSet.reduce((acc, curr) => {
          acc += curr.y;
          i++;

          return acc;
        }, 0);
        return reducedDataSet / i;
      };

      setAvgCalories(getAvgCalories);

      setMaxCalories(Math.max(...caloriesDataSet.map(({ y }) => y)) + 10);

      setCalories(caloriesDataSet);
    }
  }, [
    statysticsType,
    deltaCaloriesData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  useEffect(() => {
    setPulse([]);
    if (
      statysticsType?.value === "training" &&
      trend &&
      trend.value === "pulse" &&
      deltaHeartRateData.length > 0
    ) {
      // const getDataSet = (param) => {
      //   return [deltaHeartRateData[0]].reduce((acc, curr) => {
      //     labels.forEach((label) => {
      //       if (
      //         interval === 101 &&
      //         convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 7)
      //           .split("-")
      //           .reverse()
      //           .join("-") === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       } else if (
      //         interval === 99 &&
      //         convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 10)
      //           .split("-")
      //           .reverse()
      //           .join("-") === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       } else if (
      //         interval === 20 &&
      //         `${convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 10)
      //           .split("-")
      //           .reverse()
      //           .join("-")} ${convertUTCdateToLocal(curr.RecordDate).slice(
      //           11,
      //           13,
      //         )}:00` === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       } else if (
      //         interval === 10 &&
      //         `${convertUTCdateToLocal(curr.RecordDate)
      //           .slice(0, 10)
      //           .split("-")
      //           .reverse()
      //           .join("-")} ${convertUTCdateToLocal(curr.RecordDate).slice(
      //           11,
      //           16,
      //         )}` === label
      //       ) {
      //         acc.push({
      //           x: label,
      //           y: curr[param],
      //         });
      //       }
      //     });

      //     return acc;
      //   }, []);
      // };

      // const pulseDataSet = getDataSet("HeartRate");

      // const getAvgPulse = () => {
      //   let i = 0;

      //   const reducedDataSet = pulseDataSet.reduce((acc, curr) => {
      //     acc += curr.y;
      //     i++;
      //     return acc;
      //   }, 0);
      //   return reducedDataSet / i;
      // };

      // setAvgPulse(getAvgPulse);
      // setMinPulse(40);
      // setMaxPulse(200);
      // setMaxPulseData(Math.max(...pulseDataSet.map(({ y }) => y)) + 10);
      // setPulse(pulseDataSet);

      setTrainingsNum(0);

      const countTrainings = getTrainingsNums(deltaHeartRateData).length;

      setPulse(filterValues(deltaHeartRateData));
      setAvgPulse(avgCumulatedValue(filterValues(deltaHeartRateData)));
      setMaxPulse(200);
      setMaxPulseData(maxCumulatedValue(deltaHeartRateData) + 10);
      setMinPulse(0);
      setTrainingsNum(countTrainings);
    }
  }, [
    statysticsType,
    deltaHeartRateData,
    trend,
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
  ]);

  const getAllStatisticsTypes = async () => {
    try {
      setStatysticsTypes([
        {
          label: "Trening",
          value: "training",
        },
        {
          label: "Badania okresowe",
          value: "medical",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllStatisticsTypes();
  }, []);

  const getAllPeriodTypes = async () => {
    try {
      setPeriodTypes([
        {
          label: "Ostatnie 7 dni",
          value: "week",
        },
        {
          label: "Ostatnie 28 dni",
          value: "month",
        },
        {
          label: "Ostatnie 12 miesięcy",
          value: "year",
        },
        {
          label: "Niestandardowe",
          value: "custom",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPeriodTypes();
  }, []);

  const getAllBenchmarkToTypes = async () => {
    try {
      setBenchmarkToTypes([
        {
          label: "Do tego profilu zawodnika",
          value: "thisPlayer",
        },
        {
          label: "Do grupy zawodników",
          value: "group",
        },
        {
          label: "Do konkretnego zawodnika",
          value: "player",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBenchmarkToTypes();
  }, []);

  const getAllBenchmarkGroupTypes = async () => {
    try {
      setBenchmarkGroupTypes([
        {
          label: "Wszyscy",
          value: "0",
        },
        {
          label: "Ten sam trener",
          value: "2",
        },
        {
          label: "Ta sama Akademia",
          value: "1",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBenchmarkGroupTypes();
  }, []);

  const getAllRadiusTypes = async () => {
    try {
      setBenchmarkRadiusTypes([
        {
          label: "0 km",
          value: "0",
        },
        {
          label: "5 km",
          value: "5",
        },
        {
          label: "10 km",
          value: "10",
        },
        {
          label: "15 km",
          value: "15",
        },
        {
          label: "25 km",
          value: "25",
        },
        {
          label: "50 km",
          value: "50",
        },
        {
          label: "75 km",
          value: "75",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRadiusTypes();
  }, []);

  const getAllPositionTypes = async () => {
    try {
      setBenchmarkPositionTypes([
        {
          label: "Wszystkie",
          value: "0",
        },
        {
          label: "Bramkarz",
          value: "1",
        },
        {
          label: "Obrońca",
          value: "2",
        },
        {
          label: "Pomocnik",
          value: "3",
        },
        {
          label: "Napastnik",
          value: "4",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPositionTypes();
  }, []);

  const getAllBenchmarkPeriodTypes = async () => {
    try {
      setBenchmarkPeriodTypes([
        {
          label: "Ostatnie 7 dni",
          value: "week",
        },
        {
          label: "Bieżący miesiąc",
          value: "thisMonth",
        },
        {
          label: "Ostatnie 30 dni",
          value: "month",
        },
        {
          label: "Bieżący rok",
          value: "year",
        },
        {
          label: "Nieokreślony",
          value: "indefinite",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBenchmarkPeriodTypes();
  }, []);

  const getAllTrends = async () => {
    try {
      if (statysticsType) {
        switch (statysticsType.value) {
          case "medical":
            setTrends([
              {
                label: "Waga",
                value: "weight",
              },
              {
                label: "Wzrost",
                value: "height",
              },
            ]);
            break;
          case "training":
            setTrends([
              {
                label: "Dystans",
                value: "distance",
              },
              {
                label: "HSR",
                value: "HSR",
              },
              {
                label: "HMLD",
                value: "HMLD",
              },
              {
                label: "Prędkość średnia",
                value: "speed",
              },

              {
                label: "Prędkość maksymalna",
                value: "maxSpeed",
              },
              {
                label: "Czas w ruchu",
                value: "timeInMotions",
              },
              {
                label: "Sprinty",
                value: "sprinty",
              },
              {
                label: "Tętno",
                value: "pulse",
              },
              {
                label: "Intensywność",
                value: "intensity",
              },
              {
                label: "Aktywność",
                value: "activity",
              },
            ]);
            break;
          default:
          // code block
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTrends();
  }, [statysticsType]);

  const maxScaleSwitch = (trend) => {
    switch (trend) {
      case "weight":
        return roundToTwo(maxWeight);

      case "height":
        return roundToTwo(maxHeight);

      case "speed":
        return roundToTwo(maxSpeed);

      case "maxSpeed":
        return roundToTwo(speedMax);

      case "distance":
        return roundToTwo(maxDistance);

      case "pulse":
        return roundToTwo(maxPulse);

      case "calories":
        return roundToTwo(maxCalories);

      case "intensity":
        return roundToTwo(maxIntensity);

      case "HSR":
        return maxHSR >= maxPercentHSR
          ? roundToTwo(maxHSR)
          : roundToTwo(maxPercentHSR);

      case "HMLD":
        return maxHmld >= maxPercentHmld
          ? roundToTwo(maxHmld)
          : roundToTwo(maxPercentHmld);

      default:
        return null;
    }
  };

  const minScaleSwitch = (trend) => {
    switch (trend) {
      case "weight":
        return roundToTwo(minWeight);

      case "height":
        return roundToTwo(minHeight);

      case "speed":
        return roundToTwo(minSpeed);

      case "distance":
        return roundToTwo(minDistance);

      case "pulse":
        return roundToTwo(minPulse);

      case "calories":
        return roundToTwo(minCalories);

      case "intensity":
        return roundToTwo(minIntensity);

      case "HSR":
        return minHSR <= minPercentHSR
          ? roundToTwo(minHSR)
          : roundToTwo(minPercentHSR);

      case "HMLD":
        return minHmld <= minPercentHmld
          ? roundToTwo(minHmld)
          : roundToTwo(minPercentHmld);

      default:
        return null;
    }
  };

  const getChartAspectRatio = (width: number) => {
    if (width >= 1920) {
      return 2.85;
    } else if ((width < 1920) & (width >= 1750)) {
      return 2.9;
    } else if ((width < 1750) & (width >= 1700)) {
      return 2.5;
    } else if ((width < 1700) & (width >= 1650)) {
      return 2.43;
    } else if ((width < 1650) & (width >= 1600)) {
      return 2.38;
    } else if ((width < 1600) & (width >= 1550)) {
      return 2.28;
    } else if ((width < 1550) & (width >= 1500)) {
      return 2.2;
    } else if ((width < 1500) & (width >= 1450)) {
      return 2.1;
    } else if ((width < 1450) & (width >= 1400)) {
      return 1.97;
    } else if ((width < 1400) & (width >= 1350)) {
      return 1.9;
    } else if ((width < 1350) & (width >= 1300)) {
      return 1.8;
    } else if ((width < 1300) & (width >= 1250)) {
      return 1.7;
    } else if ((width < 1250) & (width >= 1200)) {
      return 1.6;
    } else if ((width < 1200) & (width >= 1150)) {
      return 1.5;
    } else if ((width < 1150) & (width >= 1100)) {
      return 1.4;
    } else if ((width < 1100) & (width >= 1050)) {
      return 1.3;
    } else {
      return 1.2;
    }
  };

  const options = {
    // maintainAspectRatio: false,
    aspectRatio: getChartAspectRatio(pageWidth),
    plugins: {
      tooltip: {
        interaction: {
          mode: "nearest",
        },
      },

      legend: {
        display: false,
        position: "bottom",
      },
    },

    responsive: true,

    interaction: {
      mode: "index" as const,
      intersect: true,
    },
    stacked: false,
    layout: {
      padding: {
        bottom: -10,
      },
    },

    scales: {
      x: {
        offset: false,
        title: {
          align: "start",
        },
        ticks: {
          autoSkip: true,
          padding: 20,
          beginAtZero: true,
          display: true,
          maxTicksLimit: 366,

          color: (context) => {
            if (labels.length <= 7) {
              return "#A39E9D";
            } else {
              if (
                context.index === 0 ||
                context.index === Math.floor(labels.length * 0.25) ||
                context.index === Math.floor(labels.length * 0.5) ||
                context.index === Math.floor(labels.length * 0.75) ||
                context.index === labels.length - 1
              ) {
                return "#A39E9D";
              } else {
                return "rgba(255,255,255,0)";
              }
            }
          },

          maxRotation:
            labels?.length >= 15 && labels?.length <= 29
              ? 0
              : labels?.length >= 30
              ? 90
              : 0,
          minRotation:
            labels?.length >= 15 && labels?.length <= 29
              ? 0
              : labels?.length >= 30
              ? 90
              : 0,
        },

        grid: {
          tickColor: "#A39E9D",

          color: (context) => {
            // if (context?.tick?.value === 0) {
            //   return "rgba(0,0,0,0)";
            //   // console.log("context", context);
            // }

            if (labels.length <= 10) {
              return "rgba(163,158,157,0.3)";
            } else {
              if (
                context.index === 0 ||
                context.index === Math.floor(labels.length * 0.25) ||
                context.index === Math.floor(labels.length * 0.5) ||
                context.index === Math.floor(labels.length * 0.75) ||
                context.index === labels.length - 1
              ) {
                return "rgba(163,158,157,0.3)";
              } else {
                return "rgba(255,255,255,0)";
              }
            }
          },

          drawTicks: true,
        },

        // display: false,
        // min: 0,
        // max: 6,
      },
      y: {
        // beginAtZero: true,
        grid: {
          tickColor: "rgba(163,158,157,0.3)",
          // color: "rgba(163,158,157,0.3)",
          color: function (context) {
            if (context?.tick?.value === 0) {
              return "#A39E9D";
            }
            if (context.tick.value > 0) {
              return "rgba(163,158,157,0.3)";
            } else if (context.tick.value < 0) {
              return "rgba(163,158,157,0.3)";
            }

            return "rgba(163,158,157,0.3)";
          },
        },

        type: "linear" as const,
        display: true,
        position: "left" as const,
        min: minScaleSwitch(trend?.value),
        max: maxScaleSwitch(trend?.value),
        ticks: {
          color: "#FF9226",
          beginAtZero: true,
          callback: function (value) {
            return `${value}${unitSwitch(trend?.value)}`;
          },
        },
      },
    },
  };

  useEffect(() => {
    switch (periodType?.value) {
      case "week":
        setDateFrom(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setDateTo(new Date().toISOString().slice(0, 10));
        break;

      case "month":
        setDateFrom(
          new Date(new Date().getTime() - 27 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setDateTo(new Date().toISOString().slice(0, 10));
        break;

      case "year":
        setDateFrom(
          new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setDateTo(new Date().toISOString().slice(0, 10));
        break;

      case "custom":
        break;

      default:
        setDateFrom(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setDateTo(new Date().toISOString().slice(0, 10));
    }
  }, [periodType]);

  useEffect(() => {
    switch (benchmarkPeriod.value) {
      case "week":
        setBenchmarkDateFrom(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setBenchmarkDateTo(new Date().toISOString().slice(0, 10));
        break;

      case "month":
        setBenchmarkDateFrom(
          new Date(new Date().getTime() - 27 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setBenchmarkDateTo(new Date().toISOString().slice(0, 10));
        break;

      case "year":
        setBenchmarkDateFrom(
          new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setBenchmarkDateTo(new Date().toISOString().slice(0, 10));
        break;

      case "indefinite":
        break;

      default:
        setBenchmarkDateFrom(
          new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        );
        setBenchmarkDateTo(new Date().toISOString().slice(0, 10));
    }
  }, [benchmarkPeriod]);

  useEffect(() => {
    if (
      periodType?.value === "custom" &&
      new Date(dateFrom).getTime() > new Date(dateTo).getTime()
    ) {
      setDateFrom(dateTo);
    }
  }, [dateFrom, dateTo]);

  const dataSwitch = (trend: string) => {
    switch (trend) {
      case "weight":
        return {
          data: weight,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Waga",
          // tension: 0.5,
          minBarLength: 5,
        };
      case "HSR":
        return {
          data: HSR,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "HSR",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };
      case "HMLD":
        return {
          data: Hmld,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "HMLD",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };
      case "height":
        return {
          data: height,
          label: "Wzrost",
          // pointRadius: 4,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          tension: 0.5,
          minBarLength: 5,
        };
      case "speed":
        return {
          data: speed,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Prędkość",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };

      case "maxSpeed":
        return {
          data: speedCurrentMax,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Prędkość",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };

      case "distance":
        return {
          data: distance,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Dystans",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 50,
        };
      case "intensity":
        return {
          data: intensity,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Intensywność",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 50,
        };
      case "pulse":
        return {
          data: pulse,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Tętno",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 50,
        };
      case "calories":
        return {
          data: calories,
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Kalorie",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 50,
        };
      default:
        return {
          data: [0],
          borderColor: "rgb(33, 190, 252)",
          backgroundColor: "rgba(163,158,157,0.58)",
          label: "Waga",
          tension: 0.5,
          minBarLength: 5,
        };
    }
  };

  const percentDataSwitch = (trend: string) => {
    switch (trend) {
      case "HSR":
        return {
          data: percentHSR,
          borderColor: "rgb(33, 66, 252)",
          backgroundColor: "rgba(33, 66, 252, 0.5)",
          label: "Procent HSR",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };

      case "HMLD":
        return {
          data: percentHmld,
          borderColor: "rgb(33, 66, 252)",
          backgroundColor: "rgba(33, 66, 252, 0.5)",
          label: "Procent HMLD",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };

      default:
        return {
          data: [],
          borderColor: "rgb(33, 66, 252)",
          backgroundColor: "rgba(33, 66, 252, 0.5)",
          label: "",
          tension: 0.5,
          minBarLength: 5,
          maxBarThickness: 30,
        };
    }
  };

  const avgDataSwitch = (trend: string) => {
    let array = [];

    switch (trend) {
      case "weight":
        array = [];
        if (weight.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgWeight,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "height":
        array = [];
        if (height.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgHeight,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "HSR":
        array = [];
        if (HSR.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgHSR,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "HMLD":
        array = [];
        if (Hmld.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgHmld,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "speed":
        array = [];
        if (speed.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgSpeed,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "maxSpeed":
        array = [];
        if (speedCurrentMax.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgSpeedMax,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "distance":
        array = [];
        if (distance.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgDistance,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "intensity":
        array = [];
        if (intensity.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgIntensity,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "pulse":
        array = [];
        if (pulse.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgPulse,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "calories":
        array = [];
        if (calories.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: avgCalories,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      default:
        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };
    }
  };

  const maxDataSwitch = (trend: string) => {
    let array = [];

    switch (trend) {
      case "weight":
        array = [];
        if (weight.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxWeight - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 7],
        };

      case "height":
        array = [];
        if (height.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxHeight - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "HSR":
        array = [];
        if (HSR.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxHSR - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "HMLD":
        array = [];
        if (Hmld.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxHmld - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "speed":
        array = [];
        if (speed.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxSpeed - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "maxSpeed":
        array = [];
        if (speedCurrentMax.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: speedMax - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "distance":
        array = [];
        if (distance.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxDistance - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "intensity":
        array = [];
        if (intensity.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxIntensity - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "pulse":
        array = [];
        if (pulse.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxPulseData - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      case "calories":
        array = [];
        if (calories.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: maxCalories - 10,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };

      default:
        return {
          data: array,
          borderWidth: 1,
          borderColor: "rgb(252, 142, 33)",
          backgroundColor: "rgba(252, 142, 33, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          minBarLength: 5,
          pointHitRadius: 30,
          fill: false,
          label: "Maksymalna",
          type: "line",
          borderDash: [10, 5],
        };
    }
  };
  const benchmarkDataSwitch = (trend: string) => {
    let array = [];

    switch (trend) {
      case "weight":
        array = [];
        if (weight.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkWeight,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "height":
        array = [];
        if (height.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkHeight,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "HSR":
        array = [];
        if (HSR.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkHSR,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "HMLD":
        array = [];
        if (Hmld.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkHmld,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "speed":
        array = [];
        if (speed.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkSpeed,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "distance":
        array = [];
        if (distance.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkDistance,
              x: labels[i],
            });
          }
        }
        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "intensity":
        array = [];
        if (intensity.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkIntensity,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "pulse":
        array = [];
        if (pulse.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkPulse,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      case "calories":
        array = [];
        if (calories.length > 0) {
          for (let i = 0; i < labels.length; i++) {
            array.push({
              y: benchmarkCalories,
              x: labels[i],
            });
          }
        }

        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };

      default:
        return {
          data: array,
          pointHoverRadius: 0,
          borderColor: "rgb(0, 0, 0)",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          // label: "Waga",
          pointRadius: 0,
          // tension: 0.5,
          pointHitRadius: 30,
          borderWidth: 1,
          minBarLength: 5,
          borderDash: [5, 5],
          fill: false,
          label: "Średnia",
          type: "line",
        };
    }
  };

  const maxSwitch = (trend: string) => {
    switch (trend) {
      case "weight":
        return maxWeight.toFixed(2);
      case "height":
        return maxHeight.toFixed(2);
      case "speed":
        return maxSpeed.toFixed(2);
      case "maxSpeed":
        return speedMax.toFixed(2);
      case "distance":
        return maxDistance.toFixed(2);
      case "pulse":
        return maxPulseData.toFixed(2);
      case "calories":
        return maxCalories.toFixed(2);
      case "HSR":
        return maxHSR.toFixed(2);
      case "HMLD":
        return maxHmld.toFixed(2);
      case "intensity":
        return maxIntensity.toFixed(2);
      default:
        return 0;
    }
  };

  const avgSwitch = (trend: string) => {
    switch (trend) {
      case "weight":
        return avgWeight.toFixed(2);
      case "height":
        return avgHeight.toFixed(2);
      case "speed":
        return avgSpeed.toFixed(2);
      case "maxSpeed":
        return avgSpeedMax.toFixed(2);
      case "distance":
        return avgDistance.toFixed(2);
      case "pulse":
        return avgPulse.toFixed(2);
      case "calories":
        return avgCalories.toFixed(2);
      case "HSR":
        return avgHSR.toFixed(2);
      case "HMLD":
        return avgHmld.toFixed(2);
      case "intensity":
        return avgIntensity.toFixed(2);
      default:
        return 0;
    }
  };

  const unitSwitch = (trend: string) => {
    switch (trend) {
      case "weight":
        return " kg";
      case "height":
        return " cm";
      case "speed":
        return " m/s";
      case "distance":
        return " m";
      case "pulse":
        return " puls";
      case "calories":
        return " kcal";
      case "HSR":
        return ` ${unitTrend}`;
      case "HMLD":
        return ` ${unitTrend}`;
      case "intensity":
        return " %";
      default:
        return "";
    }
  };

  const benchmarkSwitch = (trend: string) => {
    switch (trend) {
      case "weight":
        return benchmarkWeight;
      case "height":
        return benchmarkHeight;
      case "speed":
        return benchmarkSpeed;
      case "distance":
        return benchmarkDistance;
      case "pulse":
        return benchmarkPulse;
      case "calories":
        return benchmarkCalories;
      case "HSR":
        return benchmarkHSR;
      case "HMLD":
        return benchmarkHmld;
      case "intensity":
        return benchmarkIntensity;
      default:
        return 0;
    }
  };

  const [avg, setAvg] = useState(0);
  const [max, setMax] = useState(0);
  const [unit, setUnit] = useState("");

  useEffect(() => {
    if (trend?.value) {
      const avgValue = avgSwitch(trend.value);
      const maxValue = maxSwitch(trend.value);
      setAvg(avgValue);
      setMax((maxValue - 10).toFixed(2));
      setUnit(unitSwitch(trend.value));
    }
  }, [
    trend,
    avgDistance,
    avgHSR,
    distance,
    HSR,
    Hmld,
    weight,
    height,
    statysticsType,
    avgSpeedMax,
    speedMax,
    speedCurrentMax,
    avgPulse,
    avgHmld,
    maxHmld,
  ]);

  const data = {
    labels: labels,
    datasets:
      // trend?.value === "HSR" || trend?.value === "HMLD"
      //   ? [
      //       dataSwitch(trend?.value),
      //       percentDataSwitch(trend?.value),
      //       trend?.value !== "height" && avgDataSwitch(trend?.value),
      //       maxDataSwitch(trend?.value),
      //      // benchmarkDataSwitch(trend?.value),
      //     ]
      //   :
      [
        dataSwitch(trend?.value),
        maxDataSwitch(trend?.value),
        // benchmarkDataSwitch(trend?.value),
      ],
    options: {
      plugins: {
        tooltip: {
          enabled: true,
          mode: "nearest",
          intersect: false,
        },
      },
    },
  };

  const barData = dataSwitch(trend?.value).data;
  const countNumsInChartData =
    typeof barData === "number"
      ? []
      : barData.map((elem, idx) => (elem === null ? 0 : elem));

  const lineData = dataSwitch(trend?.value).data.every(
    (elem) => elem === 0 || elem === null,
  )
    ? []
    : countNumsInChartData;

  const trainingData = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        data: barData,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 2,
        barThickness: 20,
        order: 1,
      },
      {
        type: "line",
        data: lineData,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: false,
        borderWidth: 2,
        pointRadius: 0,
        spanGaps: true,
        order: 2,
        tension: 0.3,
      },
    ],
  };

  // const getUniqueSortedLabels = (datasets: any[]) => {
  //   const allLabels = datasets?.flatMap((dataset) =>
  //     dataset?.data?.map((point: any) => point.x),
  //   );

  //   const uniqueLabels = Array.from(new Set(allLabels));
  //   uniqueLabels?.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));

  //   return uniqueLabels;
  // };

  // const labels = getUniqueSortedLabels(allDatasets);

  const options2 = {
    plugins: {
      tooltip: {
        filter: function (tooltipItem, data) {
          return tooltipItem.label !== "";
        },
      },
    },
  };

  const data2 = {
    labels:
      intensity > lastMonthIntensity
        ? ["Intensywność", "Uzyskana intensywność"]
        : intensity === lastMonthIntensity
        ? ["Intensywność"]
        : intensity < lastMonthIntensity
        ? ["Intensywność", "Utracona intensywność"]
        : ["x", "x"],
    datasets: [
      intensity > lastMonthIntensity
        ? {
            label: "Intensywność",
            data: [
              intensity - (intensity - lastMonthIntensity),
              intensity - lastMonthIntensity,
              100 - intensity,
            ],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(0, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],

            hoverBorderColor: [
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(0, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],

            hoverOffset: -1,
          }
        : intensity === lastMonthIntensity
        ? {
            label: "Intensywność",
            data: [intensity, 100 - intensity],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],
            hoverBorderColor: [
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],
            hoverOffset: -1,
          }
        : intensity < lastMonthIntensity
        ? {
            label: "Intensywność",
            data: [
              intensity,
              lastMonthIntensity - intensity,
              100 - lastMonthIntensity,
            ],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(199, 27, 27, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],
            hoverBorderColor: [
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(199, 27, 27, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],

            hoverOffset: -1,
          }
        : {
            label: "Intensywność",
            data: [1, 1],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(199, 27, 27, 0.7)",
            ],
            hoverOffset: -1,
          },
    ],
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }

  const data3 = {
    labels:
      timeInMotions > lastMonthTimeInMotions
        ? ["Czas w ruchu", "Uzyskany czas w ruchu"]
        : timeInMotions === lastMonthTimeInMotions
        ? ["Czas w ruchu"]
        : timeInMotions < lastMonthTimeInMotions
        ? ["Czas w ruchu", "Utracony czas w ruchu"]
        : ["x", "x"],
    datasets: [
      timeInMotions > lastMonthTimeInMotions
        ? {
            label: "Czas w ruchu",
            data: [
              timeInMotions - (timeInMotions - lastMonthTimeInMotions),
              timeInMotions - lastMonthTimeInMotions,
              100 - timeInMotions,
            ],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(0, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],

            hoverBorderColor: [
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(0, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],

            hoverOffset: -1,
          }
        : timeInMotions === lastMonthTimeInMotions
        ? {
            label: "Czas w ruchu",
            data: [timeInMotions, 100 - timeInMotions],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],
            hoverBorderColor: [
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],
            hoverOffset: -1,
          }
        : timeInMotions < lastMonthTimeInMotions
        ? {
            label: "Czas w ruchu",
            data: [
              timeInMotions,
              lastMonthTimeInMotions - timeInMotions,
              100 - lastMonthTimeInMotions,
            ],

            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(199, 27, 27, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],
            hoverBorderColor: [
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
              "rgba(255, 255, 255, 1)",
            ],
            hoverBackgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(199, 27, 27, 0.7)",
              "rgba(228, 233,235, 0.7)",
            ],

            hoverOffset: -1,
          }
        : {
            label: "Czas w ruchu",
            data: [1, 1],
            backgroundColor: [
              "rgba(255, 255, 0, 0.7)",
              "rgba(199, 27, 27, 0.7)",
            ],
            hoverOffset: -1,
          },
    ],
  };

  useEffect(() => {
    if (trend?.value === "intensity" || trend?.value === "timeInMotions") {
      setPeriodType({
        label: "Ostatnie 28 dni",
        value: "month",
      });
    }
  }, [trend]);

  useEffect(() => {
    if (updateDays) {
      const timeBetweenDates =
        new Date(dateTo).getTime() - new Date(dateFrom).getTime();
      setDaysBetweenDates(timeBetweenDates / (1000 * 60 * 60 * 24));
    }
  }, [dateFrom, dateTo, periodType, timeFrom, timeTo]);

  const benchmarkInputsSwitch = (benchmarkToValue: string) => {
    switch (benchmarkToValue) {
      case "thisPlayer":
        return (
          <>
            <NewSelectStatystics
              defaultValue="week"
              items={benchmarkPeriodTypes}
              className={"min-w-160"}
              label=""
              selectedItem={benchmarkPeriod}
              setSelectedItem={setBenchmarkPeriod}
            />

            {benchmarkPeriod.value === "indefinite" && (
              <>
                <TimeAndDateWrapper>
                  <DateInput
                    name="AvailableFrom"
                    label={"Od"}
                    type="date"
                    value={benchmarkDateFrom}
                    className={"min-w-120"}
                    onChange={(e) => setBenchmarkDateFrom(e.target.value)}
                    max={benchmarkDateTo}
                  />

                  <TimeInput
                    type="time"
                    value={benchmarkTimeFrom}
                    onChange={(e) => {
                      setBenchmarkTimeFrom(e.target.value);
                    }}
                  />
                </TimeAndDateWrapper>
                <TimeAndDateWrapper>
                  <DateInput
                    name="AvailableTo"
                    label={"Do"}
                    type="date"
                    className={"min-w-120"}
                    value={benchmarkDateTo}
                    onChange={(e) => setBenchmarkDateTo(e.target.value)}
                    max={new Date().toISOString().slice(0, 10)}
                  />
                  <TimeInput
                    type="time"
                    value={benchmarkTimeTo}
                    onChange={(e) => setBenchmarkTimeTo(e.target.value)}
                  />
                </TimeAndDateWrapper>
              </>
            )}
          </>
        );

      case "group":
        return (
          <>
            <NewSelectStatystics
              defaultValue="week"
              items={benchmarkPeriodTypes}
              className={"min-w-160"}
              label=""
              selectedItem={benchmarkPeriod}
              setSelectedItem={setBenchmarkPeriod}
            />

            {benchmarkPeriod.value === "indefinite" && (
              <>
                <TimeAndDateWrapper>
                  <DateInput
                    name="AvailableFrom"
                    label={"Od"}
                    type="date"
                    value={benchmarkDateFrom}
                    className={"min-w-120"}
                    onChange={(e) => setBenchmarkDateFrom(e.target.value)}
                    max={benchmarkDateTo}
                  />

                  <TimeInput
                    type="time"
                    value={benchmarkTimeFrom}
                    onChange={(e) => {
                      setBenchmarkTimeFrom(e.target.value);
                    }}
                  />
                </TimeAndDateWrapper>
                <TimeAndDateWrapper>
                  <DateInput
                    name="AvailableTo"
                    label={"Do"}
                    type="date"
                    className={"min-w-120"}
                    value={benchmarkDateTo}
                    onChange={(e) => setBenchmarkDateTo(e.target.value)}
                    max={new Date().toISOString().slice(0, 10)}
                  />
                  <TimeInput
                    type="time"
                    value={benchmarkTimeTo}
                    onChange={(e) => setBenchmarkTimeTo(e.target.value)}
                  />
                </TimeAndDateWrapper>
              </>
            )}
            <NewSelectStatystics
              defaultValue="0"
              items={benchmarkGroupTypes}
              className={"min-w-160"}
              label="Grupa"
              selectedItem={benchmarkGroup}
              setSelectedItem={setBenchmarkGroup}
            />

            <NewSelectStatystics
              defaultValue="0"
              items={benchmarkPositionTypes}
              className={"min-w-160"}
              label="Pozycja"
              selectedItem={benchmarkPosition}
              setSelectedItem={setBenchmarkPosition}
            />

            <TextInput
              // defaultValue={postcode}
              value={benchmarkAgeFrom}
              onChange={benchmarkAgeFromOnChange}
              label="Wiek od"
              placeholder="Od"
              min="1"
              max="99"
              type="number"
            />

            <TextInput
              // defaultValue={postcode}
              value={benchmarkAgeTo}
              onChange={benchmarkAgeToOnChange}
              label="Wiek do"
              min="1"
              max="99"
              type="number"
            />

            <TextInput
              // defaultValue={postcode}
              value={benchmarkPostCode}
              onChange={benchmarkPostCodeOnChange}
              label="Kod pocztowy"
            />

            <SelectWithoutFilter
              defaultValue="0"
              items={benchmarkRadiusTypes}
              className={"min-w-160"}
              placeholder="Promień"
              label="Promień"
              selectedItem={benchmarkRadius}
              setSelectedItem={setBenchmarkRadius}
            />
          </>
        );

      case "player":
        return (
          <>
            <NewSelectStatystics
              items={allPlayerProfiles}
              className={"min-w-160"}
              label=""
              placeholder="Wybierz zawodnika"
              selectedItem={selectedBenchmarkPlayer}
              setSelectedItem={setSelectedBenchmarkPlayer}
            />

            <NewSelectStatystics
              defaultValue="week"
              items={benchmarkPeriodTypes}
              className={"min-w-160"}
              label=""
              selectedItem={benchmarkPeriod}
              setSelectedItem={setBenchmarkPeriod}
            />

            {benchmarkPeriod.value === "indefinite" && (
              <>
                <TimeAndDateWrapper>
                  <DateInput
                    name="AvailableFrom"
                    label={"Od"}
                    type="date"
                    value={benchmarkDateFrom}
                    className={"min-w-120"}
                    onChange={(e) => setBenchmarkDateFrom(e.target.value)}
                    max={benchmarkDateTo}
                  />

                  <TimeInput
                    type="time"
                    value={benchmarkTimeFrom}
                    onChange={(e) => {
                      setBenchmarkTimeFrom(e.target.value);
                    }}
                  />
                </TimeAndDateWrapper>
                <TimeAndDateWrapper>
                  <DateInput
                    name="AvailableTo"
                    label={"Do"}
                    type="date"
                    className={"min-w-120"}
                    value={benchmarkDateTo}
                    onChange={(e) => setBenchmarkDateTo(e.target.value)}
                    max={new Date().toISOString().slice(0, 10)}
                  />
                  <TimeInput
                    type="time"
                    value={benchmarkTimeTo}
                    onChange={(e) => setBenchmarkTimeTo(e.target.value)}
                  />
                </TimeAndDateWrapper>
              </>
            )}
          </>
        );
    }
  };

  const isBtnActive =
    customDataFrom !== undefined && customDataTo !== undefined;

  return (
    <TabContent id="statistics">
      {/*  <Chart type='bar' data={data} options={optionss} /> */}
      <div
        /*  style={{
           display: "flex",
           justifyContent: "space-between",
           alignItems: "center",
         }} */
        className="2xl:flex block  2xl:justify-between
       2xl:gap-2"
      >
        <div
          /* style={{
            display: "flex",
            gap: "5px",
            background:'red'
          }} */
          className="md:flex gap-2 block pb-4 "
        >
          <NewSelectStatystics
            items={statysticsTypes}
            label="Statystyki"
            defaultValue={"training"}
            className={"min-w-160"}
            selectedItem={statysticsType}
            setSelectedItem={setStatysticsType}
          />
          <SelectWithoutFilter
            defaultValue={
              statysticsType?.value === "medical" ? "weight" : "distance"
            }
            items={trends}
            label="Trend"
            selectedItem={trend}
            setSelectedItem={setTrend}
          />

          {(trend?.value === "HSR" || trend?.value === "HMLD") && (
            <div
              style={{
                border: "2px solid rgba(163, 158, 157, 0.14)",
                borderRadius: "2px",
                width: "100px",
                height: "48px",
              }}
            >
              <div
                style={{
                  // backgroundColor: '#222c38',
                  transition: "background-color 0.2s ease",
                  padding: "3px",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                }}
              >
                {["m", "%"].map((label, idx) => (
                  <button
                    key={label + idx}
                    onClick={() => setUnitTrend(label)}
                    className="hover:opacity-75"
                    style={{
                      color: "white",
                      height: "38px",
                      padding: "7px 2px",
                      width: "100%",
                      backgroundColor:
                        label === unitTrend ? "rgba(163, 158, 157, 0.14)" : "",
                      pointerEvents: label === unitTrend ? "none" : "auto",
                      transition: "background-color 0.4s ease",
                      borderRadius: label === unitTrend ? "2px" : "0px",
                    }}
                    disabled={label === unitTrend}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>

        <div
          /*  style={{
             display: "flex",
             width: "100%",
             gap: "10px",
             alignItems: "center",
             justifyContent: "flex-end",
              background:'red'
           }} */
          className="md:flex block "
        >
          <NewSelectStatystics
            defaultValue="week"
            items={periodTypes}
            className={"min-w-160"}
            label="Okres"
            selectedItem={periodType}
            setSelectedItem={setPeriodType}
          />
          {periodType?.value === "custom" && (
            <BlockDiv className="2xl:pl-1 pl-8 gap-1">
              <TimeAndDateWrapper>
                <DateInput
                  name="AvailableFrom"
                  label={"Od"}
                  type="date"
                  value={customDataFrom}
                  className={"min-w-120"}
                  onChange={handleDateChangeFrom}
                  max={dateTo}
                />

                <TimeInput
                  type="time"
                  value={timeFrom}
                  onChange={(e) => {
                    setTimeFrom(e.target.value);
                  }}
                />
              </TimeAndDateWrapper>

              <TimeAndDateWrapper className="pt-4 2xl:pt-0">
                <DateInput
                  name="AvailableTo"
                  label={"Do"}
                  type="date"
                  className={"min-w-120"}
                  value={customDataTo}
                  onChange={handleDateChangeTo}
                  max={new Date().toISOString().slice(0, 10)}
                />
                <TimeInput
                  type="time"
                  value={timeTo}
                  onChange={(e) => setTimeTo(e.target.value)}
                />
              </TimeAndDateWrapper>

              <div className="pt-4 2xl:pt-0 2xl:flex justify-end">
                <Button
                  variant={ButtonVariant.Submit}
                  style={{ height: "40px", maxWidth: "50px", width: "100%" }}
                  onClick={handleCustomData}
                  disabled={!isBtnActive}
                >
                  Zastosuj
                </Button>
              </div>
            </BlockDiv>
          )}
        </div>
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap-reverse",
          padding: "5px",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        ></div>
      </div> */}

      {/* statistics */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 9px",
          background: "rgba(0,0,0,0.2)",
          fontSize: "12px",
          height: "60.5px",
        }}
      >
        <div
          style={{
            width: "75%",
            display: "flex",
            gap: "20px",
            alignItems: "center",
            zIndex: "2",
          }}
        >
          {statysticsType?.value === "training" && (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              {/* <label
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >

                <p
                  style={{
                    color: "white",
                    fontSize: "15px",
                    fontWeight: "300",
                  }}
                >
                  PORÓWNANIE{" "}
                </p>
              </label> */}

              <StatisticsDropDown label="PORÓWNANIE">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <NewSelectStatystics
                    defaultValue="thisPlayer"
                    items={benchmarkToTypes}
                    className={"min-w-160"}
                    label=""
                    selectedItem={benchmarkTo}
                    setSelectedItem={setBenchmarkTo}
                  />

                  {benchmarkInputsSwitch(benchmarkTo?.value)}
                </div>
              </StatisticsDropDown>
            </div>
          )}
        </div>

        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          {trend?.value === "intensity" && (
            <>
              <p>Intensywność w poprzednim miesiącu: {lastMonthIntensity}%</p>
              <p>Intensywność w tym miesiącu: {intensity}%</p>
            </>
          )}

          {trend?.value === "timeInMotions" && (
            <>
              <p>
                Czas w ruchu w poprzednim miesiącu: {lastMonthTimeInMotions}%
              </p>
              <p>Czas w ruchu w tym miesiącu: {timeInMotions}%</p>
            </>
          )}

          {statysticsType?.value === "training" &&
            trend?.value !== "intensity" &&
            trend?.value !== "timeInMotions" && (
              <p style={{ color: "white", paddingLeft: "4px" }}>
                {/*   {data?.datasets[0].data ? data?.datasets[0].data.length : "0"} */}
                {trainingsNums}
                <span
                  style={{ color: "rgba(112,112,112,1)", paddingLeft: "5px" }}
                >
                  {trainingsNums !== 0 &&
                  trainingsNums !== 1 &&
                  trainingsNums <= 4
                    ? "Treningi"
                    : trainingsNums === 1
                    ? "Trening"
                    : "Treningów"}
                </span>
              </p>
            )}

          {trend?.value !== "intensity" && trend?.value !== "timeInMotions" && (
            <p style={{ color: "rgba(112,112,112,1)" }}>
              Max:{" "}
              <span style={{ color: "white" }}>
                {data?.datasets[0].data.length === 0 ? 0 : max}
                {unit}
              </span>
            </p>
          )}

          {trend?.value !== "intensity" &&
            trend?.value !== "timeInMotions" &&
            statysticsType?.value !== "medical" && (
              <p style={{ color: "rgba(112,112,112,1)" }}>
                Średnia:{" "}
                <span style={{ color: "white" }}>
                  {data?.datasets[0].data.length === 0 ? 0 : avg}
                  {unit}
                </span>
              </p>
            )}

          {/* {trend?.value !== "height" &&
            trend?.value !== "intensity" &&
            trend?.value !== "timeInMotions" && (
              <p>
                średnia{" "}
                {data?.datasets[0].data.length === 0
                  ? 0
                  : avgSwitch(trend?.value)}
                {unitSwitch(trend?.value)}
              </p>
            )} */}
        </div>
      </div>

      <ChartBox height={zoomLevel}>
        <div>
          {trend?.value !== "intensity" && trend?.value !== "timeInMotions" && (
            <LeftArrow
              onClick={() => {
                arrowLeftFn();
                setTimeout(() => {
                  setUpdateDays(true);
                }, 0);
              }}
              alt="arrow"
              src={leftArrowIco}
            />
          )}
        </div>
        <div>
          <div />
          <div />
          {statysticsType?.value === "training" ? (
            <Bar
              options={options}
              data={trainingData}
              ref={chartBarRef}
              style={{
                display:
                  chartType?.value !== "line" &&
                  trend?.value !== "intensity" &&
                  trend?.value !== "timeInMotions"
                    ? "unset"
                    : "none",
              }}
            />
          ) : (
            <Line
              options={options}
              data={data.datasets.data?.length ?? data}
              ref={chartLineRef}
              style={{
                display: chartType?.value === "line" ? "unset" : "none",
              }}
            />
          )}

          {/*  <Line
            options={options}
            data={data.datasets.data?.length ?? data }
            ref={chartLineRef}
            style={{
              display: chartType?.value === "line" ? "unset" : "none",
            }}
          />

          <Bar
            options={options}
            data={data}
            ref={chartBarRef}
            style={{
              display:
                chartType?.value !== "line" &&
                trend?.value !== "intensity" &&
                trend?.value !== "timeInMotions"
                  ? "unset"
                  : "none",
            }}
          /> */}
          {trend?.value === "intensity" && (
            <div style={{ width: "40%", height: "50%" }}>
              {" "}
              <Doughnut data={data2} options={options2} />
            </div>
          )}

          {trend?.value === "timeInMotions" && (
            <div style={{ width: "40%", height: "50%" }}>
              {" "}
              <Doughnut data={data3} options={options2} />
            </div>
          )}
        </div>
        <div>
          {trend?.value !== "intensity" && trend?.value !== "timeInMotions" && (
            <RightArrow
              onClick={() => {
                arrowRightFn();
                setTimeout(() => {
                  setUpdateDays(true);
                }, 0);
              }}
              alt="arrow"
              src={rightArrowIco}
            />
          )}
        </div>
      </ChartBox>
    </TabContent>
  );
};

export default StatisticsTab;
