import ProductForm from "./ProductForm";
import { productServices } from "services/marketpleace";
import {
  IAddProduct,
  IProductForm,
  PRODUCT_TYPES,
} from "types/marketpleace/product.interface";
import { toast } from "react-toastify";
import systemServices from "services/systemServices";
import { productInitialValues } from "../utils/helpers";

interface IAddProductProps {
  isAddMenuOpen: boolean;
  toggleMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refresh: () => Promise<void>;
}

const AddProduct = ({
  isAddMenuOpen,
  refresh,
  toggleMenuOpen,
}: IAddProductProps) => {
  const handleSubmit = async (
    values: IProductForm,
    isVisibilityChecked: boolean,
    categoryId: string,
    base64: string,
    productType?: PRODUCT_TYPES,
  ) => {
    try {
      let product = values as IAddProduct;

      const fileUrl = await systemServices.uploadFile(base64);
      product.Visibility = isVisibilityChecked ? 2 : 1;
      product.CategoryId = categoryId;
      product.Type = productType;
      product.BannerPhotoFilePath = fileUrl;
      product.PhotoFilesPath = [fileUrl];

      console.log("Add product object: ", product);

      await productServices.add(product);
      toast.success("Dodano nowy produkt");

      toggleMenuOpen(false);
      await refresh();
    } catch (error: any) {
      console.error(error);
      toast.error("Wystąpił nieoczekiwany problem. Spróbuj ponownie później.");
    }
  };

  return (
    <ProductForm
      initialValues={productInitialValues}
      toggleMenuOpen={toggleMenuOpen}
      handleSubmit={handleSubmit}
      isMenuOpen={isAddMenuOpen}
    />
  );
};

export default AddProduct;
