import { useState, useEffect, useRef } from "react";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import playerProfileServices from "services/playerProfileServices";
import styled, { keyframes, css } from "styled-components";
import { PlayerProfileInterface } from "types/userTypes";
import HomeTable from "./HomeTable";
import Button from "components/common/buttons/basicButton/Button";
import { ButtonVariant } from "components/common/buttons/buttonTypes";
import SelectStatystics from "components/common/inputs/select/SelectStatystics";
import HomeDropDown from "./HomeDropDown";
import axios from "axios";
import BackButton from "components/common/buttons/basicButton/BackButton";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import NewSelectStatystics from "components/common/inputs/select/NewSelectStatystics";
import jwtDecode from "jwt-decode";
import SelectWithoutFilter from "components/common/inputs/select/SelectWithoutFilter";
import { toast } from "react-toastify";
import TextFieldWithUnit from "components/common/inputs/textInput/TextFieldWithUnit";
import ArrowUp from "../../assets/icons/home-arrow-up.png";
import ArrowDown from "../../assets/icons/home-arrow-down.png";
import GridIco from "../../assets/icons/home-grid-ico.png";
import ListIco from "../../assets/icons/home-list-ico.png";
import TextFieldPostCode from "components/common/inputs/textInput/TextFieldPostCode";
import HomeList from "./HomeList";
import HomeSearchIco from "../../assets/icons/home-search-ico.png";
import MaskedField from "components/common/inputs/maskedInput/MaskedField";
import { Mask } from "utils/constants/constants";

import { useDebounce } from "use-debounce";
import FilterButton from "components/common/buttons/basicButton/FilterButton";
import api from "services/axiosConfig";
import { getOtherConnectionUrl } from "api/BaseConnection";

const SortSquare = styled.div<{ viewType: string; view: string }>`
  height: 48px;
  width: 54px;
  background: rgba(163, 158, 157, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: ${(props) =>
      props.viewType === props.view ? "brightness(60%)" : "brightness(90%)"};
  }

  filter: ${(props) =>
    props.viewType === props.view ? "brightness(50%)" : "brightness(100%)"};

  & > * {
    /* filter: brightness(50%); */
    pointer-events: none;
  }
`;

const SearchBox = styled.div`
  height: 48px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  width: 280px;
  display: flex;
  align-items: center;

  & > input {
    width: calc(100% - 45px);
  }

  & > div {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 21px;
      height: 21px;
    }
  }
`;

const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  padding: 14px 23px;
`;

const AgeInputBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  background: rgba(163, 158, 157, 0.11);
  /* border-radius: 5px; */
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  & > div:nth-of-type(1) {
    width: 50%;
    height: 100%;
    padding: 5px 6px;
    display: flex;
    & > p {
      font-size: 11px;
      font-weight: 400;
      color: rgba(163, 158, 157, 1);
    }
  }

  & > div:nth-of-type(2) {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
`;

const AgeInput = styled.input`
  height: 100%;
  width: 100%;
  /* background: rgba(163, 158, 157, 0.15); */
  border-left: 5px solid rgba(163, 158, 157, 0.15);
  background: transparent;
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  padding: 0 5px;
`;

const PostCodeBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  background: rgba(163, 158, 157, 0.11);
  /* border-radius: 5px; */
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  & > div:nth-of-type(1) {
    width: 50%;
    height: 100%;
    padding: 5px 6px;
    display: flex;
    & > p {
      font-size: 11px;
      font-weight: 400;
      color: rgba(163, 158, 157, 1);
    }
  }

  & > div:nth-of-type(2) {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
`;

const PostCodeInput = styled.input`
  height: 100%;
  width: 100%;
  /* background: rgba(163, 158, 157, 0.15); */
  background: transparent;
  color: white;
  font-size: 15px;
  text-align: right;
  font-weight: 400;
  padding: 5px;

  &::-webkit-input-placeholder {
    color: white;
    opacity: 1;
  }
`;

const BlurBox = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  display: ${(props) => (props.isMenuOpen ? "unset" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

const SingleColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & > p {
    text-align: center;
  }

  & > * {
    width: 280px;
  }
  /* align-items: center; */
`;

const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  /* padding: 23px 14px; */
  padding: ${(props) => (props.isMenuOpen ? "23px 24px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "627px" : "0px")};
  // background: rgba(2, 2, 15, 1);
  background: #202d38;
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  /* opacity: ${(props) => (props.isMenuOpen ? "1" : "0")}; */
  flex-direction: column;
  /* align-items: center; */
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    top: 95px;
    left: 0;
    height: 48px;
    width: 5px;
    background-color: rgba(163, 158, 157, 0.7);
    left: ${(props) => (props.isMenuOpen ? "0" : "-50px")};
    transition: all 0.1s ease-in-out;
    background-color: ${(props) =>
      props.isMenuOpen ? "rgba(163, 158, 157, 0.7)" : "transparent"};
    position: ${(props) => (props.isMenuOpen ? "absolute" : "absolute")};
  }

  & > div:nth-child(1) {
    width: 100%;
    display: flex;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.225px;
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-top: 10px;

    & > div {
      order: 2;
    }
    & > button {
      order: 1;
    }
  }
`;

const ScrollTableBox = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  /* margin-top: 5px; */
  border-top: 10px solid rgba(0, 0, 0, 0.5);

  &::-webkit-scrollbar {
    background: rgba(0, 145, 255, 0.09);
    width: 18px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 145, 255, 0.15);
    border: 4px solid transparent;
    background-clip: content-box;
  }
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 12px 18px; */
  /* gap: 10px; */
  /* background: rgba(0, 145, 255, 0.01); */
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 145, 255, 0.01) 100%
  ); */
  /* border-top: 20px solid rgba(4, 8, 12, 0.6); */
`;

const TableBox = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  border-radius: 5px;
  height: 38px;
  opacity: 1;
  color: white;
  padding: 5px 8px;
  font-size: 14px;
  width: 250px;
`;

const AlertContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.2); */
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
`;

const AlertBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: rgba(2, 2, 15, 0.3);
  padding: 50px 80px;
  gap: 25px;
`;

const TemplateBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  // height: 48px;
  background: rgba(0, 0, 0, 0.5);

  padding-right: 14px;

  & > div {
    height: 100%;
    display: flex;
    align-items: center;

    gap: 3px;

    & > p {
      color: #f8f8f8;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.7px;
    }
  }
`;

const FilterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  height: 50px;
  padding: 5px 0;
  background: rgba(0, 0, 0, 0.2);
`;

const Home: React.FC = () => {
  const [queryString, setQueryString] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [avgStats, setAvgStats] =
    useState<{
      EventCountFromLast7Days: number;
      Dynamic: number;
      Energy: number;
      TotalDistanceFromLast7Days: number;
      AverageDistance: number;
      MaxSpeedFromLast7Days: number;
      HSR: number;
      HMLD: number;
      Fitness: number;
      MaxHeartRate: number;
      AverageHeartRateFromLast7Days: number;
    }>();
  const [userId, setUserId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [tokenUser, setTokenUser] = useState("");

  const [debounceSearchInput] = useDebounce(searchQuery.trim(), 500);

  const handleSearchQueryChange = (e: any) => {
    setSearchQuery(e.target.value);
  };
  const handleTemplateNameChange = (e: any) => {
    setTemplateName(e.target.value);
  };

  const handlePostcodeChange = (e: any) => {
    let input = e.target.value;
    const sanitizedInput = input.replace(/[^0-9-]/g, "");

    if (sanitizedInput.length === 2) {
      // Dodawanie pauzy na trzecim miejscu
      input = `${sanitizedInput.slice(0, 2)}-${sanitizedInput.slice(2)}`;
    }

    setPostcode(input);
  };

  const handleAgeFromChange = (e: any) => {
    setAgeFrom(e.target.value);
  };

  const handleAgeToChange = (e: any) => {
    setAgeTo(e.target.value);
  };

  const handleWeightToChange = (e: any) => {
    setWeightTo(e.target.value);
  };

  const handleWeightFromChange = (e: any) => {
    setWeightFrom(e.target.value);
  };

  const handleEventCountMinChange = (e: any) => {
    setEventCountMin(+e.target.value);
  };

  const handleEventCountMaxChange = (e: any) => {
    setEventCountMax(+e.target.value);
  };

  const handleConditionMinChange = (e: any) => {
    setConditionMin(+e.target.value);
  };

  const handleConditionMaxChange = (e: any) => {
    setConditionMax(+e.target.value);
  };

  const handleDynamicsMinChange = (e: any) => {
    setDynamicsMin(+e.target.value);
  };

  const handleDynamicsMaxChange = (e: any) => {
    setDynamicsMax(+e.target.value);
  };

  const handleEnergyMinChange = (e: any) => {
    setEnergyMin(+e.target.value);
  };

  const handleEnergyMaxChange = (e: any) => {
    setEnergyMax(+e.target.value);
  };

  const handleDistanceMinChange = (e: any) => {
    setDistanceMin(+e.target.value);
  };

  const handleDistanceMaxChange = (e: any) => {
    setDistanceMax(+e.target.value);
  };

  const handleSpeedMinChange = (e: any) => {
    setSpeedMin(+e.target.value);
  };

  const handleSpeedMaxChange = (e: any) => {
    setSpeedMax(+e.target.value);
  };

  const handleHsrMinChange = (e: any) => {
    setHsrMin(+e.target.value);
  };

  const handleHsrMaxChange = (e: any) => {
    setHsrMax(+e.target.value);
  };

  const handleHmldMinChange = (e: any) => {
    setHmldMin(+e.target.value);
  };

  const handleHmldMaxChange = (e: any) => {
    setHmldMax(+e.target.value);
  };

  const handleHeartRateMinChange = (e: any) => {
    setHeartRateMin(+e.target.value);
  };

  const handleHeartRateMaxChange = (e: any) => {
    setHeartRateMax(+e.target.value);
  };

  const handleHeightFromChange = (e: any) => {
    setHeightFrom(e.target.value);
  };

  const handleHeightToChange = (e: any) => {
    setHeightTo(e.target.value);
  };

  const handleRadiusChange = (e: any) => {
    setRadius(e.target.value);
  };

  useEffect(() => {
    const token: any = localStorage.getItem("token");
    if (token) {
      const decodedToken: any = token && jwtDecode(token);

      setTokenUser(token);
      setUserId(decodedToken.jti);
    }
  }, []);

  const [sortBy, setSortBy] = useState(0);
  const [sorts, setSorts] = useState<{ value: number; label: string }[]>([]);

  const [template, setTemplate] =
    useState<{ value: number; label: string } | null>(null);
  const [templates, setTemplates] = useState<
    { value: number; label: string }[]
  >([]);

  const [gender, setGender] = useState<{ value: number; label: string } | null>(
    {
      label: "Neutralna",
      value: 2,
    },
  );
  const [targets, setTargets] = useState<{ value: number; label: string }[]>(
    [],
  );

  const [genders, setGenders] = useState<{ value: number; label: string }[]>(
    [],
  );

  const [eventTypes, setEventTypes] = useState<
    { value: number; label: string }[]
  >([]);

  const [prefferedPositions, setPrefferedPositions] = useState<
    { value: number; label: string }[]
  >([]);

  const [dominantLegs, setDominantLegs] = useState<
    { value: number; label: string }[]
  >([]);

  const [levels, setLevels] = useState<{ value: number; label: string }[]>([]);

  const [target, setTarget] = useState<{ value: number; label: string } | null>(
    {
      label: "Dowolny",
      value: 999,
    },
  );

  const [dominantLeg, setDominantLeg] = useState<{
    value: number;
    label: string;
  } | null>({
    label: "Obie",
    value: 3,
  });

  const [prefferedPosition, setPrefferedPosition] = useState<{
    value: number;
    label: string;
  } | null>({
    label: "Dowolna",
    value: 999,
  });

  const [eventType, setEventType] = useState<{
    value: number;
    label: string;
  } | null>({
    label: "Dowolny",
    value: 1,
  });

  const [level, setLevel] = useState<{ value: number; label: string } | null>({
    label: "Dowolny",
    value: 999,
  });

  const [viewType, setViewType] = useState("list");
  const [isAlertOpen, toggleAlertOpen] = useState(false);
  const [isLoadDisable, toggleLoadDisable] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [ageFrom, setAgeFrom] = useState(0);
  const [ageTo, setAgeTo] = useState(0);
  const [heightFrom, setHeightFrom] = useState(0);
  const [heightTo, setHeightTo] = useState(0);
  const [weightFrom, setWeightFrom] = useState(0);

  const [eventCountMin, setEventCountMin] = useState(0);
  const [eventCountMax, setEventCountMax] = useState(0);
  const [conditionMin, setConditionMin] = useState(0);
  const [conditionMax, setConditionMax] = useState(0);
  const [dynamicsMin, setDynamicsMin] = useState(0);
  const [dynamicsMax, setDynamicsMax] = useState(0);
  const [energyMin, setEnergyMin] = useState(0);
  const [energyMax, setEnergyMax] = useState(0);
  const [distanceMin, setDistanceMin] = useState(0);
  const [distanceMax, setDistanceMax] = useState(0);
  const [speedMin, setSpeedMin] = useState(0);
  const [speedMax, setSpeedMax] = useState(0);
  const [hsrMin, setHsrMin] = useState(0);
  const [hsrMax, setHsrMax] = useState(0);
  const [hmldMin, setHmldMin] = useState(0);
  const [hmldMax, setHmldMax] = useState(0);
  const [heartRateMin, setHeartRateMin] = useState(0);
  const [heartRateMax, setHeartRateMax] = useState(0);

  const [weightTo, setWeightTo] = useState(0);
  const [radius, setRadius] = useState(0);
  const [postcode, setPostcode] = useState("");

  const [avarageDistance, setAvarageDistance] = useState();
  const [avarageEvent, setAvarageEvent] = useState();
  const [avarageForm, setAvarageForm] = useState();
  const [avarageHeartRate, setAvarageHeartRate] = useState();
  const [avarageHmld, setAvarageHmld] = useState();
  const [avarageHsr, setAvarageHsr] = useState();
  const [avarageSpeed, setAvarageSpeed] = useState();
  const [avarageIntensity, setAvarageIntensity] = useState();
  const [isMenuOpen, toggleMenuOpen] = useState(false);

  const [averageSpeedMin, setAverageSpeedMin] = useState(0);
  const [averageSpeedMax, setAverageSpeedMax] = useState(0);
  const [averageDistanceMin, setAverageDistanceMin] = useState(0);
  const [averageDistanceMax, setAverageDistanceMax] = useState(0);
  const [isLoading, setIsloading] = useState(true);

  // const {
  //   items: playerProfiles,
  //   loading,
  //   containerRef,
  //   lastItemRef,
  // } = useInfiniteScroll<PlayerProfileInterface>(
  //    playerProfileServices.getAllPlayerProfiles,
  //   queryString,
  // );

  useEffect(() => {
    if (isLoadDisable) {
      toggleLoadDisable(false);
    }
  }, [
    eventCountMin,
    eventCountMax,
    conditionMin,
    conditionMax,
    dynamicsMin,
    dynamicsMax,
    energyMin,
    energyMax,
    distanceMin,
    distanceMax,
    speedMin,
    speedMax,
    hsrMin,
    hsrMax,
    hmldMin,
    hmldMax,
    heartRateMin,
    heartRateMax,
    ageFrom,
    ageTo,
    heightFrom,
    heightTo,
    weightFrom,
    weightTo,
    radius,
    postcode,
    template,
    gender,
    target,
    dominantLeg,
    prefferedPosition,
    eventType,
    level,
  ]);

  const getAllTargets = async () => {
    try {
      setTargets([
        {
          label: "Amator",
          value: 0,
        },
        {
          label: "Profesjonalny",
          value: 1,
        },
        {
          label: "Dowolny",
          value: 999,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTargets();
  }, []);

  const getAllEventTypes = async () => {
    try {
      setEventTypes([
        {
          label: "Dowolny",
          value: 999,
        },
        {
          label: "Trening",
          value: 2,
        },
        {
          label: "Trening aerobowy",
          value: 3,
        },
        {
          label: "Trening indywidualny",
          value: 4,
        },
        {
          label: "Testy okresowe",
          value: 1,
        },
        {
          label: "Mecz piłkarski",
          value: 0,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEventTypes();
  }, []);

  const getAllLevels = async () => {
    try {
      setLevels([
        {
          label: "Dowolny",
          value: 999,
        },
        {
          label: "Klub",
          value: 0,
        },
        {
          label: "Reprezentacja",
          value: 1,
        },
        {
          label: "Własne",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLevels();
  }, []);

  const getAllGenders = async () => {
    try {
      setGenders([
        {
          label: "Mężczyzna",
          value: 0,
        },
        {
          label: "Kobieta",
          value: 1,
        },
        {
          label: "Neutralna",
          value: 2,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllGenders();
  }, []);

  const getAllPrefferedPositions = async () => {
    try {
      setPrefferedPositions([
        {
          label: "Bramkarz",
          value: 1,
        },
        {
          label: "Obrońca",
          value: 2,
        },
        {
          label: "Pomocnik",
          value: 3,
        },
        {
          label: "Napastnik",
          value: 4,
        },
        {
          label: "Dowolna",
          value: 999,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrefferedPositions();
  }, []);

  const getAllDominantLegs = async () => {
    try {
      setDominantLegs([
        {
          label: "Prawa",
          value: 1,
        },
        {
          label: "Lewa",
          value: 2,
        },
        {
          label: "Obie",
          value: 3,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDominantLegs();
  }, []);

  const handleAverageSpeedMin = (e: any) => {
    setAverageSpeedMin(+e.target.value);
  };

  const handleAverageSpeedMax = (e: any) => {
    setAverageSpeedMax(+e.target.value);
  };

  const handleAverageDistanceMin = (e: any) => {
    setAverageDistanceMin(+e.target.value);
  };

  const handleAverageDistanceMax = (e: any) => {
    setAverageDistanceMax(+e.target.value);
  };

  const getHomePlayers = () => {
    api
      .post(
        "/api/StatisticsProcessor/GetPlayersStatisticsByAcademyOrGroupId",
        {},
      )
      .then((response) => {
        setAllUsers(response.data.Data.PlayersStatistics);
        setAvgStats(response.data.Data.AverageStatistics);
        setIsloading(false);
      })
      .catch((errors) => {
        console.error(errors);
        setIsloading(true);
      });
  };

  useEffect(() => {
    if (tokenUser) {
      getHomePlayers();
    }
  }, [tokenUser]);

  const loadTemplate = (templateId: any) => {
    api
      .post("/api/StatisticsProcessor/GetPlayersStatisticsByAcademyOrGroupId", {
        TemplateId: templateId,
      })
      .then((response) => {
        setAllUsers(response.data.Data.PlayersStatistics);
        setIsloading(false);
      })
      .catch((errors) => {
        console.error(errors);
        setIsloading(true);
      });
  };

  const deleteTemplate = (TemplateId: any) => {
    const baseUrl = getOtherConnectionUrl("justwin");
    const body = {
      UserId: userId,
      TemplateId: TemplateId,
    };

    axios
      .delete(`${baseUrl}/statisticsprocessor`, { data: body })
      .then((response) => {
        getHomePlayers();
        getAllTemplates();
      })
      .catch((errors: any) => {
        console.error(errors);
      });
  };

  const saveTemplate = (name: any) => {
    const baseUrl = getOtherConnectionUrl("justwin");
    const body = {
      UserId: userId,
      TemplateName: name,
      Gender: gender?.value,
      AgeMin: ageFrom,
      AgeMax: ageTo,
      HeightMin: heightFrom,
      HeightMax: heightTo,
      WeightMin: weightFrom,
      WeightMax: weightTo,
      TaskType: eventType?.value,
      Activity: level?.value,
      AverageSpeedMin: averageSpeedMin,
      AverageSpeedMax: averageSpeedMax,
      AverageDistanceMin: averageDistanceMin,
      AverageDistanceMax: averageDistanceMax,
      Objective: target?.value,
      PreferredPosition: prefferedPosition?.value,
      DominantLeg: dominantLeg?.value,
      PostalCode: postcode,
      Radius: `${radius}`,
      EventCountMin: eventCountMin,
      EventCountMax: eventCountMax,
      ConditionMin: conditionMin,
      ConditionMax: conditionMax,
      DynamicMin: dynamicsMin,
      DynamicMax: dynamicsMax,
      EnergyMin: energyMin,
      EnergyMax: energyMax,
      DistanceMin: distanceMin,
      DistanceMax: distanceMax,
      SpeedMin: speedMin,
      SpeedMax: speedMax,
      HsrMin: hsrMin,
      HsrMax: hsrMax,
      HmldMin: hmldMin,
      HmldMax: hmldMax,
      HeartRateMin: heartRateMin,
      HeartRateMax: heartRateMax,
    };

    const filteredBody = {
      UserId: body.UserId,
      TemplateName: body.TemplateName,
      ...Object.fromEntries(
        Object.entries(body).filter(
          ([key, value]) =>
            value > 0 || key === "UserId" || key === "TemplateName",
        ),
      ),
    };

    axios
      .post(`${baseUrl}/statisticsprocessor`, filteredBody)
      .then((response) => {
        getAllTemplates();
      })
      .catch((errors: any) => {
        console.error(errors);
      });
  };

  const getAllTemplates = () => {
    if (userId) {
      const baseUrl = getOtherConnectionUrl("justwin");
      axios
        .get(`${baseUrl}/statisticsprocessor/GetAll?userId=${userId}`)
        .then((response) => {
          let array: any = [];
          console.log("templates", response.data.Data);
          response.data.Data.map((template: any) => {
            array.push({
              label: template.TemplateName ? template.TemplateName : "BezNazwy",
              value: template.Id,
            });
          });

          setTemplates(array);
        })
        .catch((errors: any) => {
          console.error(errors);
        });
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, [userId]);

  const getAllSorts = async () => {
    try {
      setSorts([
        {
          label: "Najnowsze",
          value: 0,
        },
        {
          label: "Najstarsze",
          value: 1,
        },
        {
          label: "Nazwisko (A-Z)",
          value: 2,
        },
        {
          label: "Nazwisko (Z-A)",
          value: 3,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSorts();
  }, []);

  const handleClearFilter = () => {
    getHomePlayers();
    toggleLoadDisable(false);
  };

  return (
    <>
      <BlurBox isMenuOpen={isMenuOpen} onClick={() => toggleMenuOpen(false)} />
      <SideMenu isMenuOpen={isMenuOpen}>
        <div>
          <SingleColumn style={{ padding: "0 15px 0 0", marginTop: "65px" }}>
            <p>DANE PODSTAWOWE</p>
            <NewSelectStatystics
              name="Gender"
              items={genders}
              label="Płeć"
              selectedItem={gender}
              setSelectedItem={setGender}
              defaultValue={2}
            />

            <AgeInputBox>
              <div>
                <p>Waga (KG)</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={weightFrom}
                  onChange={handleWeightFromChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={weightTo}
                  onChange={handleWeightToChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>
            <AgeInputBox>
              <div>
                <p>Wiek (LAT)</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={ageFrom}
                  onChange={handleAgeFromChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={ageTo}
                  onChange={handleAgeToChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>
            <AgeInputBox>
              <div>
                <p>Wzrost (CM)</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={heightFrom}
                  onChange={handleHeightFromChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={heightTo}
                  onChange={handleHeightToChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>
            <p>KARTA ZAWODNIKA</p>

            <NewSelectStatystics
              name="EventType"
              items={eventTypes}
              label="Typ zadania"
              selectedItem={eventType}
              setSelectedItem={setEventType}
              defaultValue={999}
            />
            <NewSelectStatystics
              name="Level"
              items={levels}
              label="Poziom"
              selectedItem={level}
              setSelectedItem={setLevel}
              defaultValue={999}
            />

            <NewSelectStatystics
              name="Target"
              items={targets}
              label="Cel"
              selectedItem={target}
              setSelectedItem={setTarget}
              defaultValue={999}
            />
            <NewSelectStatystics
              name="PreferredPosition"
              items={prefferedPositions}
              label="Preferowana pozycja"
              selectedItem={prefferedPosition}
              setSelectedItem={setPrefferedPosition}
              defaultValue={999}
            />
            <NewSelectStatystics
              name="DominantLeg"
              items={dominantLegs}
              label="Noga dominująca"
              selectedItem={dominantLeg}
              setSelectedItem={setDominantLeg}
              defaultValue={3}
            />
            <p>ADRES</p>
            <PostCodeBox>
              <div>
                <p>Kod pocztowy</p>
              </div>
              <div>
                <PostCodeInput
                  maxLength={6}
                  placeholder="__-___"
                  type="text"
                  style={{ letterSpacing: "4px" }}
                  value={postcode}
                  onChange={handlePostcodeChange}
                />
              </div>
            </PostCodeBox>

            <PostCodeBox>
              <div>
                <p>Promień</p>
              </div>
              <div>
                <PostCodeInput
                  maxLength={4}
                  placeholder="_"
                  value={radius}
                  onChange={handleRadiusChange}
                  type="text"
                />
              </div>
            </PostCodeBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "8px",
                marginTop: "5px",
              }}
            ></div>
          </SingleColumn>

          <SingleColumn>
            <NewSelectStatystics
              style={{ marginBottom: "10px" }}
              name="Template"
              items={templates}
              label="Szablon"
              placeholder="Załaduj z szablonu"
              selectedItem={template}
              setSelectedItem={setTemplate}
            />

            <AgeInputBox>
              <div>
                <p>Liczba eventowa</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={eventCountMin}
                  onChange={handleEventCountMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={eventCountMax}
                  onChange={handleEventCountMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Kondycja</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={conditionMin}
                  onChange={handleConditionMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={conditionMax}
                  onChange={handleConditionMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Dynamika</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={dynamicsMin}
                  onChange={handleDynamicsMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={dynamicsMax}
                  onChange={handleDynamicsMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Energia</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={energyMin}
                  onChange={handleEnergyMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={energyMax}
                  onChange={handleEnergyMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Dystans</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={distanceMin}
                  onChange={handleDistanceMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={distanceMax}
                  onChange={handleDistanceMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Prędkość</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={speedMin}
                  onChange={handleSpeedMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={speedMax}
                  onChange={handleSpeedMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>HSR</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={hsrMin}
                  onChange={handleHsrMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={hsrMax}
                  onChange={handleHsrMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>HMLD</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={hmldMin}
                  onChange={handleHmldMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={hmldMax}
                  onChange={handleHmldMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Tętno</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={heartRateMin}
                  onChange={handleHeartRateMinChange}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={heartRateMax}
                  onChange={handleHeartRateMaxChange}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>
            <AgeInputBox>
              <div>
                <p>Średnia predkość</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={averageSpeedMin}
                  onChange={handleAverageSpeedMin}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={averageSpeedMax}
                  onChange={handleAverageSpeedMax}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>

            <AgeInputBox>
              <div>
                <p>Średni dystans</p>
              </div>
              <div>
                <AgeInput
                  placeholder="od"
                  value={averageDistanceMin}
                  onChange={handleAverageDistanceMin}
                  type="text"
                  maxLength={3}
                />
                <AgeInput
                  placeholder="do"
                  value={averageDistanceMax}
                  onChange={handleAverageDistanceMax}
                  type="text"
                  maxLength={3}
                />
              </div>
            </AgeInputBox>
            <Button
              className="px-24 xl:order-2"
              variant={ButtonVariant.Submit}
              style={{
                width: "50%",
                marginTop: "5px",
                background: "rgba(0,145,255,1)",
                fontSize: "15px",
                fontWeight: "bold",
                letterSpacing: "0.375px",
                alignSelf: "self-end",
              }}
              onClick={() => {
                loadTemplate(template?.value);
                // toggleLoadDisable(true);
                setTimeout(() => {
                  toggleLoadDisable(true);
                }, 100);
              }}
              disabled={template?.value ? false : true && !isLoadDisable}
            >
              Zastostuj
            </Button>
          </SingleColumn>
        </div>
        <div>
          <PostCodeBox style={{ width: "280px" }}>
            <div>
              <p>Nazwa szablonu</p>
            </div>
            <div>
              <PostCodeInput
                type="text"
                name="templateName"
                onChange={handleTemplateNameChange}
                value={templateName}
                placeholder="Nazwa szablonu"
              />
            </div>
          </PostCodeBox>
          <Button
            className="px-24 xl:order-2"
            variant={ButtonVariant.Submit}
            style={{
              background: "rgba(255,146,38,1)",
              // width: "50%",
              // marginTop: "5px",
              fontSize: "15px",
              fontWeight: "bold",
              letterSpacing: "0.375px",
            }}
            onClick={() => {
              if (!templateName) {
                toast.error("Nazwa szablonu nie może być pusta!");
              } else {
                saveTemplate(templateName);
                toggleAlertOpen(false);
                toast.success("Zapisano nowy szablon!");
                setTemplateName("");
              }
            }}
          >
            Zapisz jako nowy szablon
          </Button>
        </div>
      </SideMenu>

      <Container>
        <TemplateBox>
          <div className=" w-full">
            <p style={{ height: "46px" }}></p>
            <div className="w-full">
              <div style={{ height: "48px", width: "100%" }}>
                <p
                  style={{
                    paddingLeft: "17px",
                    fontSize: "18px",
                    fontWeight: 400,
                    letterSpacing: "0.1px",
                    borderLeft: "5px solid rgba(163,158,157,0.7)",
                    height: "48px",
                  }}
                  className="flex items-center text-white"
                >
                  Home
                </p>
              </div>
              <div className="flex flex-wrap items-center gap-1 justify-end w-full">
                <SearchBox style={{ width: "100%", maxWidth: "280px" }}>
                  <SearchInput
                    className="w-full md:w-36 xl:w-72"
                    placeholder="Szukaj..."
                    autoComplete="off"
                    name="search"
                    onChange={handleSearchQueryChange}
                  />
                  <div>
                    <img src={HomeSearchIco} alt="ico" />
                  </div>
                </SearchBox>

                <FilterButton
                  className="px-24 xl:order-2"
                  variant={ButtonVariant.Submit}
                  onClick={() => toggleMenuOpen((prev) => !prev)}
                />
              </div>
            </div>
          </div>

          <div>
            {template?.value && (
              <>
                <Button
                  className="px-24 xl:order-2"
                  variant={ButtonVariant.Remove}
                  style={{
                    padding: "15px 41.4064px",
                    background: "rgba(220, 33, 33, 0.7)",
                    width: "120px",
                  }}
                  onClick={() => deleteTemplate(template?.value)}
                >
                  Usuń
                </Button>

                <Button
                  className="px-24 xl:order-2"
                  variant={ButtonVariant.Remove}
                  style={{
                    padding: "15px 41.4064px",
                    background: "rgba(0, 145, 255, 0.7)",
                    width: "120px",
                  }}
                  onClick={handleClearFilter}
                  // onClick={() => {
                  //   loadTemplate(template?.value);
                  //   setTimeout(() => {
                  //     toggleLoadDisable(true);
                  //   }, 100);
                  // }}
                  disabled={!isLoadDisable}
                >
                  Wyczyść
                </Button>
              </>
            )}
          </div>
        </TemplateBox>

        {viewType === "grid" ? (
          <></>
        ) : (
          <>
            <ScrollTableBox>
              <TableBox>
                <HomeTable
                  // avarageSpeed={0}
                  // avarageIntensity={0}
                  // avarageHsr={0}
                  // avarageHmld={0}
                  // avarageHeartRate={0}
                  // avarageEvent={0}
                  // avarageForm={0}
                  // avarageDistance={0}
                  playerProfiles={allUsers}
                  avgStats={avgStats || ({} as any)}
                  //containerRef={containerRef}
                  // lastItemRef={lastItemRef}
                  loading={isLoading}
                  sortBy={sortBy}
                  searchQuery={debounceSearchInput}
                />
              </TableBox>
            </ScrollTableBox>
          </>
        )}
      </Container>
    </>
  );
};

export default Home;
