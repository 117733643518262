import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import AuthPage from "components/auth/LoginPage";
import ChangePassword from "components/profile/ChangePassword";
import ContentContainer from "components/layout/ContentContainer";
import Home from "components/home/Home";
import Layout from "components/layout/Layout";
import Profile from "components/profile/Profile";
import RegisterForm from "components/auth/Register/RegisterForm";
import RemindPassword from "components/auth/remindPassword/RemindPassword";
import PrivateRoute from "./PrivateRoute";
import { authorize } from "store/actions/auth";
import { RootState } from "store/store";
import Events from "components/events/Events";
import EventsDetail from "components/events/detail/EventsDetail";
import ChangePasswordForm from "components/auth/ChangePasswordForm";
import jwtDecode from "jwt-decode";
import Users from "components/users/Users";
import UserDetail from "components/users/detail/UserDetail";
import PlayersDetail from "components/players/detail/PlayersDetail";
import ResetPassword from "components/auth/resetPassword/ResetPassword";
import Contact from "components/contact/Contact";
import Files from "components/Files";
import Structure from "components/structure/Structure";
import Trainers from "components/trainers/Trainers";
import TrainersDetail from "components/trainers/detail/TrainersDetail";
import { ProductDetail, Products } from "components/marketpleace/Products";
import Categories from "components/marketpleace/Categories/Categories";
import Attributes from "components/marketpleace/Attributes/Attributes";
import Deliveries from "components/marketpleace/Deliveries/Deliveries";
import { OrderDetail, Orders } from "components/marketpleace/Orders";
import { Passes } from "components/marketpleace/Passes";
import AcademyDetail from "components/academy/detail/Academy";
import Players from "components/players/academy/Players";
import Chat from "components/chat/Chat";
import NotificationCenter from "components/notificationCenter/NotificationCenter";
import TrainerOutsideDatail from "components/trainers/detail/TrainerOutsideDetail";
import { startSignalR, stopSignalR } from "../store/actions/startSignalR";
import { userPermissions } from "../store/actions/userPermissions";
import { getUserDetail } from "../services/usersServices";
import { setAcademyMerchantStatus } from "store/actions/academyMerchantStatus";
import RequestedOutsidePlayers from "components/players/outside/RequestedOutsidePlayers";
import AvailableOutsidePlayers from "components/players/outside/AvailablePlayersOutsidePlayers";
import RequestedTrainerOutside from "components/trainers/detail/RequestedTrainerOutside";
import AvailableTrainerOutside from "components/trainers/detail/AvailableTrainerOutside";
import DraftEvents from "components/events/DraftEvents";
import Academies from "components/academy/Academies";

interface InicialPermissionValue {
  id: number;
  pass: boolean;
}

const initiaPermissionValue: InicialPermissionValue[] = [
  { id: 46, pass: false },
  { id: 130, pass: false },
  { id: 131, pass: false },
  { id: 132, pass: false },
  { id: 133, pass: false },
  { id: 134, pass: false },
  { id: 135, pass: false },
  { id: 137, pass: false },
  { id: 141, pass: false },
  { id: 140, pass: false },
  { id: 139, pass: false },
  { id: 138, pass: false },
  { id: 136, pass: false },
  { id: 160, pass: false },
  { id: 161, pass: false },
  { id: 162, pass: false },
  { id: 163, pass: false },
  { id: 164, pass: false },
  { id: 165, pass: false },
  { id: 182, pass: false },
  { id: 139, pass: false },
  { id: 205, pass: false },
  { id: 211, pass: false },
  { id: 213, pass: false },
  { id: 215, pass: false },
];

const AppRouter: React.FC = () => {
  const { isAuth } = useSelector((state: RootState) => state.auth);
  const location = useLocation<{ from: string }>();
  const dispatch = useDispatch();
  const [decodedToken, setDecodedToken] = useState<any | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const [isAcademyMerchantRegistration, setIsAcademyMerchantRegistration] =
    useState(false);

  const [isTabPermission, setTabPermission] = useState<
    typeof initiaPermissionValue
  >(initiaPermissionValue);

  const TAB_PERMISSIONS = useMemo(
    () => [
      46, 130, 131, 132, 133, 134, 137, 140, 141, 139, 135, 138, 136, 160, 161,
      162, 163, 164, 165, 211, 213, 215, 205,
    ],
    []
  );

  useEffect(() => {
    dispatch(authorize());
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setToken(token);
      setDecodedToken(decodedToken);
      dispatch(startSignalR());
      return () => {
        dispatch(stopSignalR());
      };
    }
  }, [dispatch]);

  useEffect(() => {
    if (decodedToken) {
      getUserDetail(decodedToken.UserId)
        .then((data) => {
          const academyMerchantRegistrationStatus =
            data.Data.AcademyMerchantRegistrationStatus === 2;

          setIsAcademyMerchantRegistration(!academyMerchantRegistrationStatus);

          dispatch(
            setAcademyMerchantStatus(!academyMerchantRegistrationStatus)
          );

          if (decodedToken.Role !== "0") {
            const playerPermissions = data.Data.Permissions.map(
              (perm: { Id: number }) => perm.Id
            );

            const updatedPermissions = TAB_PERMISSIONS.map((id) => ({
              id,
              pass: playerPermissions.includes(id),
            }));

            setTabPermission((prevPermissions) => {
              const hasChanged = prevPermissions.some((perm, index) => {
                return perm?.pass !== updatedPermissions[index]?.pass;
              });

              return hasChanged ? updatedPermissions : prevPermissions;
            });

            dispatch(userPermissions(data.Data.Permissions));
          } else {
            dispatch(userPermissions([{ Id: -1, Value: "Admin" }]));

            setTabPermission((prevPermissions) => {
              const allPermissionsEnabled = prevPermissions.map((perm) => ({
                ...perm,
                pass: true,
              }));

              return allPermissionsEnabled;
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [decodedToken, dispatch, TAB_PERMISSIONS]);

  const isTabActive = useMemo(
    () => (e: number) => {
      return isTabPermission.find((tab) => tab.id === e)?.pass;
    },
    [isTabPermission]
  );

  return (
    <Layout>
      <Switch>
        <Route exact path="/login">
          {isAuth ? (
            <Redirect to={location.state?.from || "/"} />
          ) : (
            <AuthPage />
          )}

          {/* {
            // @ts-ignore
            !localStorage.getItem("token")?.IsTemporaryPasswordChanged ? (
              <Redirect to={"/changepassword"} />
            ) : (
              <AuthPage />
            )
          } */}
        </Route>

        <Route exact path="/changepassword">
          {/* {isAuth ? <ChangePasswordForm /> : <AuthPage />} */}
          <ChangePasswordForm />
        </Route>

        <Route exact path="/remindPassword">
          <RemindPassword />
        </Route>

        <Route exact path="/resetPassword">
          <ResetPassword />
        </Route>

        <PrivateRoute path="/">
          <Route exact path="/academy">
            {isTabActive(130) && <Academies />}
          </Route>
          <Route exact path="/academy/detail/:id">
            {isTabActive(130) && <AcademyDetail />}
          </Route>

          <Route exact path="/files">
            {isTabActive(213) && <Files />}
          </Route>

          <Route exact path="/contacts">
            {isTabActive(211) && <Contact />}
          </Route>

          <Route exact path="/structure">
            {isTabActive(139) && <Structure />}
          </Route>

          <Route exact path="/users/detail/:id">
            <UserDetail />
          </Route>

          <Route exact path="/users">
            <Users />
          </Route>

          <Route exact path="/players">
            {isTabActive(133) && <Players />}
          </Route>

          <Route exact path="/players/detail/:id">
            {(isTabActive(134) || isTabActive(205)) && <PlayersDetail />}
          </Route>

          <Route exact path="/outside-players/requested">
            {isTabActive(135) && <RequestedOutsidePlayers />}
          </Route>

          <Route exact path="/outside-players/available">
            {isTabActive(135) && <AvailableOutsidePlayers />}
          </Route>

          <Route exact path="/outside-players/detail/:id">
            {isTabActive(134) && <PlayersDetail />}
          </Route>

          <Route exact path="/outside-trainers/requested">
            {isTabActive(138) && <RequestedTrainerOutside />}
          </Route>

          <Route exact path="/outside-trainers/available">
            {isTabActive(138) && <AvailableTrainerOutside />}
          </Route>

          <Route exact path="/outside-trainers/detail/:id">
            {isTabActive(182) && <TrainerOutsideDatail />}
          </Route>

          <Route exact path="/trainers">
            {isTabActive(136) && <Trainers />}
          </Route>

          <Route exact path="/trainers/detail/:id">
            {isTabActive(137) && <TrainersDetail />}
          </Route>

          <Route exact path="/events">
            {isTabActive(131) && <Events />}
          </Route>

          <Route exact path="/drafts">
            {isTabActive(131) && <DraftEvents />}
          </Route>

          <Route exact path="/events/detail/:id">
            {isTabActive(132) && <EventsDetail />}
          </Route>

          {isAcademyMerchantRegistration && (
            <>
              <Route exact path="/shop/passes">
                {isTabActive(161) && <Passes />}
              </Route>

              <Route exact path="/shop/orders">
                {isTabActive(160) && <Orders />}
              </Route>

              <Route exact path="/shop/orders/detail/:id">
                {isTabActive(141) && <OrderDetail />}
              </Route>

              <Route exact path="/shop/products">
                {isTabActive(162) && <Products />}
              </Route>

              <Route exact path="/shop/products/detail/:id">
                {isTabActive(46) && <ProductDetail />}
              </Route>

              <Route exact path="/shop/categories">
                {isTabActive(163) && <Categories />}
              </Route>

              <Route exact path="/shop/attributes">
                {isTabActive(164) && <Attributes />}
              </Route>

              <Route exact path="/shop/delivery">
                {isTabActive(165) && <Deliveries />}
              </Route>
            </>
          )}

          <Route exact path="/users/add">
            <RegisterForm />
          </Route>
          <Route exact path="/notifications">
            <NotificationCenter />
          </Route>
          <Route exact path="/chat">
            {isTabActive(215) && <Chat />}
          </Route>
          <Route path="/profile">
            <Profile />
            <Switch>
              <Route exact path="/profile/changePassword">
                <ContentContainer title="Zmień hasło">
                  <ChangePassword />
                </ContentContainer>
              </Route>
            </Switch>
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </PrivateRoute>
      </Switch>
    </Layout>
  );
};

export default AppRouter;
