import { Middleware } from "redux";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { toast } from "react-toastify";
import * as signalR from "@microsoft/signalr";
import { setNotificationCounter } from "../../store/actions/notificationCounter";
import { conn } from "api/BaseConnection";

const signalRMiddleware: Middleware = (store) => {
  let connection: signalR.HubConnection | null = null;

  return (next) => async (action) => {
    const dispatch = store.dispatch;

    if (action.type === "START_SIGNALR") {
      const token = localStorage.getItem("token");

      if (token) {
        connection = new HubConnectionBuilder()
          .withUrl(`${conn.base_url}/notifications`, {
            transport: signalR.HttpTransportType.LongPolling,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .configureLogging(LogLevel.Information)
          .withAutomaticReconnect([0, 2000, 10000, 30000])
          .build();

        try {
          await connection.start();
          console.log("SignalR connected");

          connection.on("ConsumeNotification", async (data) => {
            try {
              toast.info("Dostałeś nowe powiadomienie!");
              dispatch(setNotificationCounter(true));
              dispatch(setNotificationCounter(false));
            } catch (error) {
              console.error("Failed to parse notification data:", error);
            }
          });

          connection.onreconnecting((err) => {
            console.warn("Reconnecting...", err);
          });

          connection.onreconnected((connectionId) => {
            console.log("Reconnected. Connection ID:", connectionId);
          });

          connection.onclose((err) => {
            if (err) {
              console.error("Connection closed with error:", err);
            } else {
              console.log("Connection closed");
            }
          });
        } catch (err) {
          console.error("Connection failed:", err);
        }
      }
    } else if (action.type === "STOP_SIGNALR" && connection) {
      try {
        await connection.stop();
        console.log("Connection stopped");
        connection = null;
      } catch (err) {
        console.error("Error stopping connection:", err);
      }
    }
    return next(action);
  };
};

export default signalRMiddleware;
