import styled from "styled-components";
import SelectArrow from "../assets/icons/selectArrow.png";
import SelectInfoArrow from "../assets/icons/selectInfoArrow.png";
import CurrencyInput from "react-currency-input-field";

export const BlurBox = styled.div<{
  isMenuOpen: boolean;
}>`
  position: absolute;
  display: ${(props) => (props.isMenuOpen ? "unset" : "none")};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(3px);
  z-index: 99;
`;

export const SideMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 48px;
  right: 0;
  z-index: 99;

  height: calc(100vh - 48px);
  width: ${(props) => (props.isMenuOpen ? "630px" : "0px")};

  padding: ${(props) => (props.isMenuOpen ? "23px 0" : "23px 0")};
  gap: 9px;

  // background: rgba(2, 2, 15, 1);
  background: #202d38;
  color: rgba(163, 158, 157, 1);

  transition: width 0.4s ease-in-out;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.2s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    & > form {
      & > div {
        & > p {
          /* text-align: center; */
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export const SearchBox = styled.div`
  height: 48px;
  background: rgba(163, 158, 157, 0.11);
  color: white;
  width: 280px;
  display: flex;
  align-items: center;
  border-radius: 10px;

  & > input {
    width: calc(100% - 45px);
  }

  & > div {
    width: 45px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 21px;
      height: 21px;
    }
  }
`;

export const SearchInput = styled.input`
  background: transparent;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  padding: 14px 23px;
`;

export const SortSquare = styled.div<{ viewType: string; view: string }>`
  height: 48px;
  width: 54px;
  background: ${(props) =>
    props.viewType !== props.view
      ? "rgba(163, 158, 157, 0.11)"
      : "rgba(163, 158, 157, 0.38)"};

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: brightness(120%);
  }

  /* filter: ${(props) =>
    props.viewType === props.view ? "brightness(50%)" : "brightness(100%)"}; */

  & > * {
    /* filter: brightness(50%); */
    pointer-events: none;
  }
`;

export const SingleColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  gap: 10px;

  & > p {
    /* text-align: center; */
    margin: 10px 0 0 0;
    margin-bottom: 0;
  }

  & > * {
    width: 280px;
  }
  /* align-items: center; */
`;

export const SingleLabel = styled.div`
  margin-top: 8px;

  & > span {
    &:nth-of-type(1) {
      width: 270px;
      letter-spacing: -0.375px;
    }

    &:nth-of-type(2) {
      width: calc(100% - 270px);
      color: white;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.45px;
    }
  }
`;

export const SideFilterMenu = styled.div<{ isMenuOpen: boolean }>`
  position: absolute;
  height: calc(100vh - 48px);
  top: 48px;
  padding: ${(props) => (props.isMenuOpen ? "23px 44px" : "23px 0px")};
  right: 0;
  width: ${(props) => (props.isMenuOpen ? "369px" : "0px")};
  background: #202d38;
  transition: all 0.25s ease-in-out;
  color: rgba(163, 158, 157, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;

  & > * {
    opacity: ${(props) => (props.isMenuOpen ? "1" : "0")};
    transition: all 0.125s ease-in-out;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
    }
  }
`;

export const StyledInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 280px;
  border-radius: 5px;
  height: 48px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  color: white;

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
`;

export const StyledTableSelect = styled.select`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  background: url(${SelectArrow}) calc(100% - 10px) 60% no-repeat;
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  outline: none;

  :disabled {
    opacity: 40%;
    cursor: default;
  }

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    height: 48px;
  }
`;

export const StyledInfoSelect = styled.select`
  background: transparent;
  width: 100%;
  border-radius: 5px;
  height: 20px;
  opacity: 1;
  text-align-last: right;
  text-align: right;
  padding-right: 35px;
  background: url(${SelectInfoArrow}) calc(100%) 60% no-repeat;
  -webkit-appearance: none;
  cursor: pointer;
  appearance: none;
  outline: none;

  :disabled {
    opacity: 40%;
    cursor: default;
  }

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    height: 20px;
  }
`;

export const StyledTableInput = styled.input`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;

  :disabled {
    opacity: 40%;
    cursor: default;
  }

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
  color-scheme: dark;
`;

export const StyledTableCurrencyInput = styled(CurrencyInput)`
  background: transparent;
  border: 1px solid rgba(163, 158, 157, 0.3);
  width: 100%;
  border-radius: 5px;
  height: 40px;
  opacity: 1;
  text-align-last: center;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  outline: none;

  :disabled {
    opacity: 40%;
    cursor: default;
  }

  & > * {
    background: rgb(57, 57, 65);
    opacity: 0.5;
    /* height: 48px; */
  }
  color-scheme: dark;
`;

export const GridColumn = styled.div<{ cols: number; largerColumn?: number }>`
  display: grid;
  position: relative;
  gap: 4px;
  grid-template-columns: ${(props) =>
    props.largerColumn
      ? `${Array(props.cols)
          .fill(null)
          .map((value, index) =>
            index + 1 === props.largerColumn ? "2fr " : "1fr ",
          )}`.replaceAll(",", "")
      : `repeat(${props.cols}, minmax(75px, 1000px))`};
`;

export const GridTextItem = styled.div<{
  isHeader?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
}>`
  background: ${({ isHeader }) =>
    isHeader ? "rgba(2, 2, 15, 0.3)" : "rgba(163, 158, 157, 0.09)"};
  color: white;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  opacity: ${({ disabled }) => (disabled ? ".6" : "1")};
  font-size: 13px;
  font-weight: 400;
  padding: 0 4px;
  width: 100%;

  text-align: center;
  line-height: 48px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const GridItem = styled.div<{
  isHeader?: boolean;
  uppercase?: boolean;
  disabled?: boolean;
}>`
  background: ${({ isHeader }) =>
    isHeader ? "rgba(2, 2, 15, 0.3)" : "rgba(163, 158, 157, 0.09)"};
  color: white;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  font-size: 13px;
  font-weight: 400;
  opacity: ${({ disabled }) => (disabled ? ".6" : "1")};
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  opacity: 1;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.7px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    gap: 5px;

    &:nth-child(even) {
      background: rgba(163, 158, 157, 0.09);
    }
    &:nth-child(odd) {
      background: rgba(163, 158, 157, 0.05);
    }

    & > p {
      width: 50%;
      text-align: left;
      line-height: 45px;
      padding: 0 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;

      :nth-child(1) {
        color: #a1a1a1;
        text-transform: capitalize;
        width: 42%;
      }
      :nth-child(2) {
        color: white;
        width: 55%;
      }
    }
    & > div {
      width: 50%;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
      :nth-child(1) {
        color: #a1a1a1;
        width: 45%;
      }
      :nth-child(2) {
        color: white;
        width: 55%;
      }
    }
  }
`;

export const OneLinerText = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

export const TextArea = styled.textarea`
  background: none;
  width: 100%;
  outline: dashed 2px rgba(255, 255, 255, 0.2);
  padding: 10px;
  min-height: 45px;
  max-height: 400px;
`;

export const RangeInputBox = styled.div`
  width: 100%;
  height: 48px;
  min-height: 48px;
  background: rgba(163, 158, 157, 0.11);
  /* border-radius: 5px; */
  padding: 0 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  & > div:nth-of-type(1) {
    width: 50%;
    height: 100%;
    padding: 5px 6px;
    display: flex;
    & > p {
      font-size: 11px;
      font-weight: 400;
      color: rgba(163, 158, 157, 1);
    }
  }

  & > div:nth-of-type(2) {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
`;

export const RangeInputValue = styled.input`
  height: 100%;
  width: 100%;
  /* background: rgba(163, 158, 157, 0.15); */
  border-left: 5px solid rgba(163, 158, 157, 0.15);
  background: transparent;
  color: white;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  padding: 0 5px;
`;
