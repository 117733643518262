import { conn } from "api/BaseConnection";
import api from "./axiosConfig";

// export const getAllEvents = async () => {
//   try {
//     const response = await api.get("/api/Event/Listining");
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

export const getAllEvents = (pageInfo: any) => {
  return conn.getJSON(`/api/Event/Listining`, "json", { ...pageInfo });
};

export const getAllCyclicalEvents = (pageInfo: any) => {
  return conn.getJSON(`/api/CyclicalEvent/Filter?IsDraft=true`, "json", {
    ...pageInfo,
  });
};

export const getAllEventsFilter = (pageInfo: any) => {
  return conn.getJSON(`/api/Event/Filter`, "json", { ...pageInfo });
};

export const getEventDetail = async (EventId: string) => {
  try {
    const response = await api.get(`/api/Event/Detail?EventId=${EventId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCyclicalEventDetail = async (EventId: string) => {
  try {
    if (EventId) {
      const eventid = EventId?.split("?")[0];
      const response = await api.get(
        `/api/CyclicalEvent/Detail?EventId=${eventid}`
      );
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const addFile = async (
  EventId: string,
  Name: string,
  FilePath: string,
  FilePathMin: string
) => {
  try {
    const response = await api.post(`/api/Event/File`, {
      EventId,
      Name,
      FilePath,
      FilePathMin,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const changeLocalization = async (
  EventId: string,
  City: string,
  PostCode: string
) => {
  try {
    const response = await api.patch(`/api/Event/Localization`, {
      EventId,
      City,
      PostCode,
      Country: 177,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFile = async (EventId: string, FileId: string) => {
  try {
    const response = await api.delete(`/api/Event/File`, {
      data: { EventId, FileId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteEvent = async (EventId: string) => {
  try {
    const response = await api.delete(`/api/Event`, {
      data: { EventId },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
